import { Alert, Box, Button, CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API } from "../../controllers/API";
import axios from "axios";
import {Form, Spinner } from "react-bootstrap";




export const ConfirmFinalNonconfirmation = ({caseNumber, status})=>{
  const [closureReasons, setClosureReasons] = useState([])
    const [selectedValue, setSelectedValue] = useState('');
    const [caseClosureReasonCode, setCaseClosureReasonCode] = useState('');
    const [currentlyEmployedReasonDescription, setCurrentlyEmployedReasonDescription] = useState('');
    const [otherReasonDescription, setOtherReasonDescription] = useState('');
    const [spinner, setSpinner] = useState(false)
    const [loading, setLoading] = useState(true)
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value)
        if(event.target.value !== 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC'){
            setCurrentlyEmployedReasonDescription('')

        }
        if(event.target.value !== 'OTHER_FREE_TEXT'){
          setOtherReasonDescription('')

      }
       
      };

      const getClosureReasons = async ()=>{
        const response = await axios.post(API + `/everify/authentication/closure_reasons`, {case_number: caseNumber },{
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log(response.data)
        setClosureReasons(response.data.case_closure_reasons)
        setLoading(false)
      }

      const handleCloseCase = async (caseNumber) => {
        try {
            console.log(caseNumber);
            setSpinner(true);
            const response = await axios.post(API + `/everify/authentication/close`, {
                case_number: caseNumber,
                case_closure_reason_code: selectedValue,
                other_reason_description: otherReasonDescription,
                currently_employed_reason_description: currentlyEmployedReasonDescription
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            console.log(response);
            if (response.status === 200) {
                window.location.reload();
            } else {
                window.alert('Something went wrong. Please try again.');
                setSpinner(false);
            }
        } catch (error) {
            console.error('Error closing case:', error.message);
            window.alert('Something went wrong. Please try again.');
            setSpinner(false);
        }
    };
    

      useEffect(()=>{
        console.log(status)
        if(status === 'FINAL_NONCONFIRMATION' || status === 'CLOSE_CASE_AND_RESUBMIT' ){
          
        getClosureReasons();

      }
      },[])
      if(loading){
          return (
            <Alert severity="warning">
              <CircularProgress/>
            </Alert>
          )
      } else {
        
    return(
    <Alert severity="warning">
   <Box>
    <Typography variant="button"><b>Final Nonconfirmation</b></Typography>

    <Typography>Unfortunately, we were unable to confirm the employee's authorization for employment. Please close David's case below to indicate whether or not you intend to continue David's employment.</Typography>

    <Typography variant="button"><b>Close Case</b></Typography>

    <Typography>Below, indicate whether or not you will continue to employ David Cook after receiving a Final Nonconfirmation.</Typography>


    <RadioGroup
        name="closeDecision"
        value={selectedValue}
        onChange={handleRadioChange}
      >

        {closureReasons.map((item, index)=>{
          return(
            <FormControlLabel
            sx={{marginTop:'5px', marginBottom:'5px'}}
              value={item.code}
              control={<Radio />}
              label={<Typography>{item.name}</Typography>}
            />
          )
        })}

       

    </RadioGroup>

    {selectedValue === 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC' && (
        <Box>
            <Typography className="mb-1 mt-3"><b>Please indicate why you are choosing to employ David Cook after a Final Nonconfirmation was received:</b></Typography>
          <Form.Control
          value={currentlyEmployedReasonDescription}
          onChange={(e)=>{
            setCurrentlyEmployedReasonDescription(e.target.value)
          
          }}
          as="textarea"/>

        </Box>
    )}





{selectedValue === 'OTHER_FREE_TEXT' && (
        <Box>
            <Typography className="mb-1 mt-3"><b>Please indicate why you are closing this case below:</b></Typography>
          <Form.Control
          value={otherReasonDescription}
          onChange={(e)=>{
            setOtherReasonDescription(e.target.value)
           
          }}
          as="textarea"/>

        </Box>
    )}

            <Box>
            <Button
            className="mt-3"
            onClick={()=>{
            let formData = {
                case_closure_reason_code: caseClosureReasonCode,
                other_reason_description: otherReasonDescription,
                currently_employed_reason_description: currentlyEmployedReasonDescription
            }

            handleCloseCase(caseNumber, formData)
            }}
            variant="contained" endIcon={<Spinner size="sm" style={{display:spinner ? 'flex':'none'}}/>}><b>Close Case</b></Button>
            </Box>
   </Box>

    </Alert>
    )

  }
}