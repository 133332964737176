import React from "react";
import { ListItem, ListItemText, MenuItem } from "@mui/material";
import { PDFDocument, PDFDropdown, PDFTextField, PDFCheckbox, PDFButton } from 'pdf-lib';
import { formatDate, statesArray } from "../utils/Global-Functions";


export const PDFFill = ({ formData, doc, sectionTwo, supplementB, supplementA }) => {
  const fillForm = async () => {
    try {
      console.log(formData)
      const formUrl = doc;

      const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());

 
      const pdfDoc = await PDFDocument.load(formPdfBytes);



      const signatureImageBytes = await fetch(formData.sectionOne.signature).then(res => res.arrayBuffer());
      const signatureImage = await pdfDoc.embedPng(signatureImageBytes);


      const EmployerSignatureImageBytes = await fetch(sectionTwo.employerSignature).then(res => res.arrayBuffer());
      const EmployerSignatureImage = await pdfDoc.embedPng(EmployerSignatureImageBytes);
      console.log('EmployerSignatureImageBytes:', EmployerSignatureImageBytes);

     
      const form = pdfDoc.getForm();
   
      const lastNameField = form.getTextField('Last Name Given Name');
      const firstNameField = form.getTextField('First Name Given Name');
      const middleInitial = form.getTextField('Employee Middle Initial (if any)');

      const ssnField = form.getTextField('US Social Security Number');
      const state = form.getDropdown('State');
      const address = form.getTextField('Address Street Number and Name');
      const city = form.getTextField('City or Town');
      const zipCode = form.getTextField('ZIP Code');
      const dateOfBirth = form.getTextField('Date of Birth mmddyyyy');
      const emailAddress = form.getTextField('Employees E-mail Address');
      const phoneNumber = form.getTextField('Telephone Number');
      const signature = form.getButton('Employee Signature')
      const options = state.getOptions();
      const signatureDate = form.getTextField("Today's Date mmddyyy");
      

      const citizenCheck =form.getCheckBox("CB_1");

      const  noncitizenNationalCheck =form.getCheckBox("CB_2");

      const lawfulPermanentResidentCheck =form.getCheckBox("CB_3");

      const noncitizenCheck =form.getCheckBox("CB_4");
      
      const uscisANumber = form.getTextField('3 A lawful permanent resident Enter USCIS or ANumber');
      const expirationDate = form.getTextField('Exp Date mmddyyyy');
      
      const additionalInfo_1 = form.getTextField('USCIS ANumber');
      const additionalInfo_2 = form.getTextField('Form I94 Admission Number');
      const additionalInfo_3 = form.getTextField('Foreign Passport Number and Country of IssuanceRow1');
     

//Section Two

const listADocumentTitle = form.getTextField('Document Title 1');

const listADocumentIssAuth = form.getTextField('Issuing Authority 1');

const listADocumentNumber = form.getTextField('Document Number 0 (if any)');

const listAExpDate = form.getTextField('Expiration Date if any');

    

const listBDocumentTitle = form.getTextField('List B Document 1 Title');

const listBDocumentIssAuth = form.getTextField('List B Issuing Authority 1');

const listBDocumentNumber = form.getTextField('List B Document Number 1');

const listBExpDate = form.getTextField('List B Expiration Date 1');

    

const listCDocumentTitle = form.getTextField('List C Document Title 1');

const listCDocumentIssAuth = form.getTextField('List C Issuing Authority 1');

const listCDocumentNumber = form.getTextField('List C Document Number 1');

const listCExpDate = form.getTextField('List C Expiration Date 1');

const additionalInfo = form.getTextField('Additional Information');

const altProcedure =form.getCheckBox("CB_Alt");


const firstDayOfEmployement = form.getTextField("FirstDayEmployed mmddyyyy");

const employerDetails = form.getTextField("Last Name First Name and Title of Employer or Authorized Representative");
const employerBusinessName = form.getTextField("Employers Business or Org Name");
const employerBusinessAddress = form.getTextField("Employers Business or Org Address");
const employerSignature = form.getButton('Employer Signature')
const employerSignatureDate = form.getTextField("S2 Todays Date mmddyyyy");


// Supplement A

const lastNameFieldSupA = form.getTextField('Last Name Family Name from Section 1');
const firstNameFieldSupA = form.getTextField('First Name Given Name from Section 1');
const middleInitialSupA = form.getTextField('Middle initial if any from Section 1');

const prepLastNameFieldSupA = form.getTextField('Preparer or Translator Last Name (Family Name) 0');
const prepFirstNameFieldSupA = form.getTextField('Preparer or Translator First Name (Given Name) 0');
const prepMiddleInitialSupA = form.getTextField('PT Middle Initial 0');

const prepAddressSupA = form.getTextField('Preparer or Translator Address (Street Number and Name) 0');
const prepCitySupA = form.getTextField('Preparer or Translator City or Town 0');
const prepStateSupA = form.getDropdown('Preparer State 0');
const prepAZipCodeSupA = form.getTextField('Zip Code 0');
const prepSignature = form.getButton('Preparer Signature 0')
const prepSignatureDate = form.getTextField("Sig Date mmddyyyy 0");


    

      lastNameField.setText(formData.sectionOne.lastName);
      firstNameField.setText(formData.sectionOne.firstName);
      middleInitial.setText(formData.sectionOne.middleInitial);
      address.setText(formData.sectionOne.address);
      city.setText(formData.sectionOne.city);
      zipCode.setText(formData.sectionOne.zipCode);
      dateOfBirth.setText(formatDate(formData.sectionOne.dateOfBirth));
      emailAddress.setText(formData.sectionOne.emailAddress)
      phoneNumber.setText(formData.sectionOne.telephoneNumber)
      state.select(options[formData.sectionOne.state]);
      ssnField.setText(formData.sectionOne.socialSecurityNumber)
      signature.setImage(signatureImage)
      signatureDate.setText(formatDate(formData.sectionOne.signatureDate));
      expirationDate.setText(formatDate(formData.sectionOne.expirationDate));
      uscisANumber.setText(formData.sectionOne.uscisANumber);
    

      if(formData.sectionOne.citizenshipStatus === "US_CITIZEN"){
        citizenCheck.check();

      }

      if(formData.sectionOne.citizenshipStatus === "NONCITIZEN"){
        noncitizenCheck.check();
      }


      if(formData.sectionOne.citizenshipStatus === "LAWFUL_PERMANENT_RESIDENT"){
        lawfulPermanentResidentCheck.check();
      }


      if(formData.sectionOne.citizenshipStatus === "NONCITIZEN_AUTHORIZED_TO_WORK" || formData.sectionOne.citizenshipStatus === "ALIEN_AUTHORIZED_TO_WORK"){
        noncitizenNationalCheck.check();
      }

      
    
      if(formData.sectionOne.additionalInfoType === 'uscisANumber'){
        additionalInfo_1.setText(formData.sectionOne.additionalInfo)
      }
      
      if(formData.sectionOne.additionalInfoType === 'uscisANumber'){
        additionalInfo_1.setText(formData.sectionOne.additionalInfo)
      }

      if(formData.sectionOne.additionalInfoType === 'i94AdmissionNumber'){
        additionalInfo_2.setText(formData.sectionOne.additionalInfo)
      }

      if(formData.sectionOne.additionalInfoType === 'foreignPassport'){
        additionalInfo_3.setText(formData.sectionOne.additionalInfo)
      }
     // Section Two

     listADocumentTitle.setText(sectionTwo.listA.documentTitle)
     listADocumentIssAuth.setText(sectionTwo.listA.issuingAuthority)
     listADocumentNumber.setText(sectionTwo.listA.documentNumber)
     listAExpDate.setText(formatDate(sectionTwo.listA.expirationDate))
      
     listBDocumentTitle.setText(sectionTwo.listB.documentTitle)
     listBDocumentIssAuth.setText(sectionTwo.listB.issuingAuthority)
     listBDocumentNumber.setText(sectionTwo.listB.documentNumber)
     listBExpDate.setText(formatDate(sectionTwo.listB.expirationDate))
      

     listCDocumentTitle.setText(sectionTwo.listC.documentTitle)
     listCDocumentIssAuth.setText(sectionTwo.listC.issuingAuthority)
     listCDocumentNumber.setText(sectionTwo.listC.documentNumber)
     listCExpDate.setText(formatDate(sectionTwo.listC.expirationDate))
      
     additionalInfo.setText(sectionTwo.additionalInformation)

      // Embed the signature image on the form
      if(sectionTwo.alternativeProcedure){
        altProcedure.check();
      }

      firstDayOfEmployement.setText(formatDate(sectionTwo.firstDayOfEmployment))
      

      employerDetails.setText(`${sectionTwo.employerLastName} ${sectionTwo.employerFirstName} ${sectionTwo.employerTitle}`)
      employerBusinessName.setText(sectionTwo.employerBusinessName)
      employerBusinessAddress.setText(`${sectionTwo.employerAddress} ${sectionTwo.employerCity} ${statesArray[sectionTwo.employerState]} ${sectionTwo.employerZipCode}`)
      employerSignature.setImage(EmployerSignatureImage)
      employerSignatureDate.setText(formatDate(sectionTwo.todayDate))
if(formData.sectionOne.supplementA[0].lastName !== ''){
  console.log('this')
  const prepSignatureImageBytes = await fetch(formData.sectionOne.supplementA[0].signature).then(res => res.arrayBuffer());
  const prepSignatureImage = await pdfDoc.embedPng(prepSignatureImageBytes);

      // // Supplement A
      lastNameFieldSupA.setText(formData.sectionOne.lastName);
      firstNameFieldSupA.setText(formData.sectionOne.firstName);
      middleInitialSupA.setText(formData.sectionOne.middleInitial);

      prepLastNameFieldSupA.setText(formData.sectionOne.supplementA[0].lastName);
      prepFirstNameFieldSupA.setText(formData.sectionOne.supplementA[0].firstName);
      prepMiddleInitialSupA.setText(formData.sectionOne.supplementA[0].middleInitial);

      prepSignatureDate.setText(formatDate(formData.sectionOne.supplementA[0].date));
      prepSignature.setImage(prepSignatureImage)
      
      prepAddressSupA.setText(formData.sectionOne.supplementA[0].address)
      prepCitySupA.setText(formData.sectionOne.supplementA[0].city)
      prepStateSupA.select(options[formData.sectionOne.supplementA[0].state]);
      prepAZipCodeSupA.setText(formData.sectionOne.supplementA[0].zipCode)
    }
    
      for (let i = 0; i < sectionTwo.supplementB.length; i += 3) {

        const supplementBFormUrl = supplementB;
        const supplementBFormBytes = await fetch(supplementBFormUrl).then(res => res.arrayBuffer());
    
  const supplementBPdfDoc = await PDFDocument.load(supplementBFormBytes);
     
  // Access the AcroForm (Interactive Form) of the new PDF
  const form2 = supplementBPdfDoc.getForm();
// ... (Fill the new PDF form fields based on sectionTwo.supplementB[i], sectionTwo.supplementB[i+1], sectionTwo.supplementB[i+2])


       
     

  
       // Supplement B
       const lastNameFieldSupB = form2.getTextField('Sup B Last Name Family Name from Section 1-2');
       const firstNameFieldSupB = form2.getTextField('Sup B First Name Given Name from Section 1-2');
       const middleInitialSupB = form2.getTextField('Sup B Middle initial if any from Section 1-2');
       
       const oneLastNameFieldSupB = form2.getTextField('Sup B Last Name 0');
       const oneFirstNameFieldSupB = form2.getTextField('Sup B First Name 0');
       const oneMiddleInitialSupB = form2.getTextField('Sup B Middle Initial 0');
       
       const oneDocumentTitleSupB = form2.getTextField('Sup B Document Title 0');
       const oneDocumentNumberSupB = form2.getTextField('Sup B Document Number 0');
       const oneDocumentExpSupB = form2.getTextField('Sup B Expiration Date 0');
       
       
       const oneEmployerNameSupB = form2.getTextField('Sup B Name of Emp or Auth Rep 0');
       
       const oneDateOfRehireSupB = form2.getTextField('Sup B Date of Rehire 0');
       
       const oneTodaysDateSupB = form2.getTextField('Sup B Todays Date 0');
       const oneAdditionalInfoSupB = form2.getTextField('Sup B Addtl Info 0');
       const oneAltMethodSupB = form2.getCheckBox('Sup B CB_Alt_0');


//Row One
if (sectionTwo.supplementB[i]) {



  const oneEmployerSignatureSupB = form2.getButton('Button3')

  const oneEmployerSignatureImageBytes = await fetch(sectionTwo.supplementB[i].signature).then(res => res.arrayBuffer());
  const oneEmployerSignatureImage = await supplementBPdfDoc.embedPng(oneEmployerSignatureImageBytes)


  console.log(sectionTwo.supplementB[i])


// Set

oneLastNameFieldSupB.setText(sectionTwo.supplementB[i].lastName)
oneFirstNameFieldSupB.setText(sectionTwo.supplementB[i].firstName)
oneMiddleInitialSupB.setText(sectionTwo.supplementB[i].middleInitial)

oneDocumentTitleSupB.setText(sectionTwo.supplementB[i].documentTitle)
oneDocumentNumberSupB.setText(sectionTwo.supplementB[i].documentNumber)
oneDocumentExpSupB.setText(formatDate(sectionTwo.supplementB[i].expirationDate))

oneEmployerSignatureSupB.setImage(oneEmployerSignatureImage)

oneEmployerNameSupB.setText(`${sectionTwo.supplementB[i].employerFirstName} ${sectionTwo.supplementB[i].employerLastName}`)
oneDateOfRehireSupB.setText(formatDate(sectionTwo.supplementB[i].dateOfRehire))
oneAdditionalInfoSupB.setText(sectionTwo.supplementB[i].additionalInformation)
oneTodaysDateSupB.setText(formatDate(sectionTwo.supplementB[i].date))
if(sectionTwo.supplementB[i].alternativeProcedure){
  oneAltMethodSupB.check()
}
}

//Row Two
if (sectionTwo.supplementB[i + 1]) {
  const twoEmployerSignatureSupB = form2.getButton('Button1')

        const twoEmployerSignatureImageBytes = await fetch(sectionTwo.supplementB[i+1].signature).then(res => res.arrayBuffer());
        const twoEmployerSignatureImage = await supplementBPdfDoc.embedPng(twoEmployerSignatureImageBytes)
      
  console.log(sectionTwo.supplementB[i+1])
  const twoLastNameFieldSupB = form2.getTextField('Sup B Last Name 1');
  const twoFirstNameFieldSupB = form2.getTextField('Sup B First Name 1');
  const twoMiddleInitialSupB = form2.getTextField('Sup B Middle Initial 1');
  
  const documentTitleSupBRow2 = form2.getTextField('Sup B Document Title 1')
  const twoDocumentNumberSupB = form2.getTextField('Sup B Document Number 1');
  const twoDocumentExpSupB = form2.getTextField('Sup B Expiration Date 1');
  
  
  const twoEmployerNameSupB = form2.getTextField('Sup B Name of Emp or Auth Rep 1');
  
  const twoDateOfRehireSupB = form2.getTextField('Sup B Date of Rehire 1');
  
  const twoTodaysDateSupB = form2.getTextField('Sup B Todays Date 1');
  const twoAdditionalInfoSupB = form2.getTextField('Sup B Addtl Info 1');
  
  const twoAltMethodSupB = form2.getCheckBox('Sup B CB_Alt_1');
  

// Set


 twoLastNameFieldSupB.setText(sectionTwo.supplementB[i+1].lastName)
 twoFirstNameFieldSupB.setText(sectionTwo.supplementB[i+1].firstName)
 twoMiddleInitialSupB.setText(sectionTwo.supplementB[i+1].middleInitial)

documentTitleSupBRow2.setText(sectionTwo.supplementB[i+1].documentTitle)
 twoDocumentNumberSupB.setText(sectionTwo.supplementB[i+1].documentNumber)
 twoDocumentExpSupB.setText(formatDate(sectionTwo.supplementB[i+1].expirationDate))

 twoEmployerSignatureSupB.setImage(twoEmployerSignatureImage)

 twoEmployerNameSupB.setText(`${sectionTwo.supplementB[i+1].employerFirstName} ${sectionTwo.supplementB[i+1].employerLastName}`)
 twoDateOfRehireSupB.setText(formatDate(sectionTwo.supplementB[i+1].dateOfRehire))
 twoAdditionalInfoSupB.setText(sectionTwo.supplementB[i+1].additionalInformation)

 twoTodaysDateSupB.setText(formatDate(sectionTwo.supplementB[i+1].date))
 if(sectionTwo.supplementB[i+1].alternativeProcedure){
  twoAltMethodSupB.check()
}
}

//Row Three
if (sectionTwo.supplementB[i + 2]) {
 
  const  threeEmployerSignatureSupB = form2.getButton('Button2')
  const threeEmployerSignatureImageBytes = await fetch(sectionTwo.supplementB[i+2].signature).then(res => res.arrayBuffer());
  const threeEmployerSignatureImage = await supplementBPdfDoc.embedPng(threeEmployerSignatureImageBytes)
  

  console.log(sectionTwo.supplementB[i+2])


  const  threeLastNameFieldSupB = form2.getTextField('Sup B Last Name 2');
  const  threeFirstNameFieldSupB = form2.getTextField('Sup B First Name 2');
  const  threeMiddleInitialSupB = form2.getTextField('Sup B Middle Initial 2');
  
  const  threeDocumentTitleSupB = form2.getTextField('Sup B Document Title 2');
  const  threeDocumentNumberSupB = form2.getTextField('Sup B Document Number 2');
  const  threeDocumentExpSupB = form2.getTextField('Sup B Expiration Date 2');
  
  
  const  threeEmployerNameSupB = form2.getTextField('Sup B Name of Emp or Auth Rep 2');
  
  const  threeDateOfRehireSupB = form2.getTextField('Sup B Date of Rehire 2');
  
  const  threeTodaysDateSupB = form2.getTextField('Sup B Todays Date 2');
  const threeAdditionalInfoSupB = form2.getTextField('Sup B Addtl Info 2');
  const threeAltMethodSupB = form2.getCheckBox('Sup B CB_Alt_2');
// Set

threeLastNameFieldSupB.setText(sectionTwo.supplementB[i+2].lastName)
threeFirstNameFieldSupB.setText(sectionTwo.supplementB[i+2].firstName)
threeMiddleInitialSupB.setText(sectionTwo.supplementB[i+2].middleInitial)

threeDocumentTitleSupB.setText(sectionTwo.supplementB[i+2].documentTitle)
threeDocumentNumberSupB.setText(sectionTwo.supplementB[i+2].documentNumber)
threeDocumentExpSupB.setText(formatDate(sectionTwo.supplementB[i+2].expirationDate))

threeEmployerSignatureSupB.setImage(threeEmployerSignatureImage)

threeEmployerNameSupB.setText(`${sectionTwo.supplementB[i+2].employerFirstName} ${sectionTwo.supplementB[i+2].employerLastName}`)
threeDateOfRehireSupB.setText(formatDate(sectionTwo.supplementB[i+2].dateOfRehire))
threeAdditionalInfoSupB.setText(sectionTwo.supplementB[i+2].additionalInformation)

threeTodaysDateSupB.setText(formatDate(sectionTwo.supplementB[i+2].date))
if(sectionTwo.supplementB[i+2].alternativeProcedure){
  threeAltMethodSupB.check()
}
}   

// Supplement B
     lastNameFieldSupB.setText(formData.sectionOne.lastName);
     firstNameFieldSupB.setText(formData.sectionOne.firstName);
     middleInitialSupB.setText(formData.sectionOne.middleInitial);

// Row One




console.log('saved')
const [copiedPage] = await pdfDoc.copyPages(supplementBPdfDoc, [0]);
  pdfDoc.addPage(copiedPage);

        // Copy pages from the new document to the first document

     
;
      }

    


      // Save the modified PDF as a Uint8Array


      //Supplement B

       // Load the documents

    // Copy pages from the second document to the first document


    // Save the modified PDF as bytes
    const mergedPdfBytes = await pdfDoc.save();

    // Create a Blob from the merged PDF bytes
    const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(mergedPdfBlob);
    link.download = `${formData.sectionOne.firstName} ${formData.sectionOne.lastName} I-9`;

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error filling and downloading PDF:', error);
    }
  };

  return (
    <MenuItem onClick={fillForm}>
      <ListItemText sx={{color:'#0D6EFD'}}> 
        Download Documents
      </ListItemText>
    </MenuItem>
  );
};
