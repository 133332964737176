import { Typography } from "@mui/material";
import React from "react";



export const ResultBlock = ()=>{
    return(
    <span style={{backgroundColor:'rgb(0, 136, 23)', borderRadius:'4px', padding:'4px', boxSizing:'border-box', color:'white'}}>
     <b>EMPLOYMENT AUTHORIZED</b>
    </span>
    )
} 