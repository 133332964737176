import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TitleBar } from "../components/TitleBar";
import PDFViewer from "../components/PDFViewer";
import { NavBar } from "../components/NavBar";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../controllers/API";
import uuid from "react-uuid";
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoaderModal from "../components/LoaderModal";

export const I9Upload = ()=>{
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(true)
    const [sending, setSending] = useState(false)
    const date = new Date()
    const [formData, setFormData] = useState({
        id: uuid(),
        firstName: '',
        lastName: '',
        date: date,
        sectionTwo:{
          firstDayOfEmployment:'',
          supplementB:[]
        },
        i9Link:'',
        type:'upload'
      });
      let navigate = useNavigate();
      const [user, setUser] = useState(null);
      useEffect(()=>{
        const libLength = user?.data.i9Library.length;
        const pendingLength = user?.data.i9.length;
        const inactiveLength = user?.data.inactive.length;
       const membership = user?.membership;
  
    
        const total = libLength + pendingLength + inactiveLength;
        console.log(total)
        if(user !== null){
          
       if(membership === 'basic' && total >= 50) {
        window.alert('You have filled all I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
        navigate('/') 
       }
       else{
        if( membership === 'Pro' && total >= 200) {
          window.alert('You have filled all I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
          navigate('/') 
         }
         else {
         setLoading(false)
        }
       } 

      }
      
      },[user])
      useEffect(() => {
        // Fetch user data from the server when the component mounts or when the user comes back to the page
        const fetchUserData = async () => {
          try {
            // Check if the token cookie exists before making the request
            const token = Cookies.get('token');
            if (!token) {
              console.error('Token not found. Redirecting to login.');
              navigate('/login');
              return;
            }
    
            const response = await axios.post(API+'/auth/refresh-user', { token });
            const refreshedUser = response.data.user;
    
            // Update the user data in the state
            setUser(refreshedUser);
            console.log(refreshedUser)
          
          } catch (error) {
            console.error('Error fetching user data:', error.message);
            navigate('/login'); // Redirect to the login page in case of an error
          }
        };
    
        fetchUserData();
      }, [navigate]);



    const handleUpload = (e)=>{
       let file = e.target.files[0]
       setFile(file)
       console.log(file)
    }
    

    async function uploadFile(a, folderText) {
   
      setSending(true)
        const file = a;
        const folder = folderText;
      
        if (!file || !folder) {
          alert('Please select a file and enter a folder name.');
          return;
        }
      
        const fileFormData = new FormData();
        fileFormData.append('file', file);
        fileFormData.append('folder', folder);
      
        try {
          const response = await axios.post(API+'/file/upload', fileFormData, {
            headers: {
              'Content-Type': 'multipart/form-data', // Important for file uploads
            },
          });
      
          // Ensure the response is successful (status code 2xx)
          if (response.data.ok) {
            // Extract the shared link directly as a string
            const sharedLink = await response.data.sharedLink;
      console.log(sharedLink)
            let modifiedLink = sharedLink.replace("www.dropbox.com", "dl.dropboxusercontent.com");
           
            let updatedFormData = {
                ...formData,
                i9Link: modifiedLink
              };

          let resp = await axios.post(API + '/i9/upload-i9', {id:user._id , data:updatedFormData})

          if(resp.data.success){
            window.alert('The I9 has been uploaded to your I9 library. You can view it there.');
            navigate('/i9/library')
            setSending(false)
          }

          } else {
            setSending(false)
            // Handle non-successful response (status code other than 2xx)
            console.error('Error uploading file:', response.statusText);
            alert('Error uploading file. Please try again.');
            return false
          }
        } catch (error) {
            setSending(false)
          console.error('Error uploading file:', error);
          alert('Error uploading file. Please try again.');
          return false
        }
      }
      if(loading){
        return <LoaderModal open={loading}/>
      } else {
        
    return (
        <Box>
      <NavBar/>
            <TitleBar
            NavigationTitle="Back"
            To="/"
            Title="UPLOAD I9"
            />
            <Box sx={{padding:5}}>
            <Box component={Paper} sx={{padding:5}}>

                <Typography>Use this form to securely upload and store a previously created I9 in the system.</Typography>
                <Grid sx={{marginTop:2}}  item xs={12} sm={12}>
                <Typography sx={{fontSize:18}} variant="button"><b>STEP ONE:</b> Enter Employees Name</Typography>
                </Grid>

                <Grid spacing={2} container>
                <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
            <Typography ><b>First Name</b></Typography>
            <TextField
            fullWidth
            value={formData.firstName}
            onChange={(e)=>{
                setFormData((prevData)=>(
                    {
                    ...prevData,
                    firstName: e.target.value
                    }
                  ))
            }}
         
             />     
            </Grid>

            <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
            <Typography ><b>Last Name</b></Typography>
            <TextField
            fullWidth
            value={formData.lastName}
            onChange={(e)=>{
                setFormData((prevData)=>(
                    {
                    ...prevData,
                    lastName: e.target.value
                    }
                  ))
            }}
         
             />     
            </Grid>

            <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
            <Typography ><b>Date of Hire</b></Typography>
            <TextField
            fullWidth
            type="date"
            value={formData.sectionTwo.firstDayOfEmployment}
            onChange={(e)=>{
                setFormData((prevData)=>(
                    {
                    ...prevData,
                    sectionTwo:{
                      firstDayOfEmployment: e.target.value
                    } 
                    }
                  ))
            }}
         
             />     
            </Grid>

            <Grid sx={{marginTop:2, marginBottom:2}}  item xs={12} sm={12}>
                <Typography sx={{fontSize:18}} variant="button"><b>STEP TWO:</b> Upload Employees FORM I-9</Typography>
                </Grid>
            </Grid>
                
                <Form.Control 
                // value={file}
                type='file'
                accept=".pdf"
                onChange={handleUpload}
                />
                <Box sx={{padding:2, textAlign:'end'}}>
                    <Button onClick={()=>uploadFile(file, `${user._id}/uploaded-i9s/${formData.id}`)} variant="contained">Upload</Button>
                </Box>
            <Box sx={{display: file !== null ? 'block':'none'}}>
                <Typography><b>PREVIEW:</b></Typography>
                <Typography>Review the document to make sure it looks correct. Then you can click upload.</Typography>
            <PDFViewer file={file}/>
            </Box>

            </Box>
            </Box>
            <LoaderModal open={sending}/>
        </Box>
    )

}
}