import { Alert, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../controllers/API";
import { Link } from "react-router-dom";

export const EverifyStatus = ({caseNumber, state}) => {
  const [everifyStatus, setEverifyStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
        console.log(caseNumber);
        const resp = await axios.post(API + '/everify/authentication/check-case', { caseNumber: caseNumber });
        console.log(resp.data);
        setEverifyStatus(resp.data.sendBack);
      } catch (error) {
        console.error('Error fetching everify status:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    getData();
  }, [caseNumber]);

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <Alert severity={everifyStatus?.case_eligibility_statement !== 'EMPLOYMENT_AUTHORIZED' ? 'warning': 'success'}>
        <b>E-Verify Status</b>: {everifyStatus?.case_eligibility_statement === 'EMPLOYMENT_AUTHORIZED'?<b>EMPLOYMENT AUTHORIZED</b>: everifyStatus?.case_status.replace(/_/g,' ')} - {everifyStatus?.case_status !== 'COMPLETED'?<Link state={state} to='/i9s/everify'>Click here {everifyStatus?.case_status === "UNCONFIRMED_DATA" ?'to resolve the issue.':'to view the details'}</Link> : ''}
         
      </Alert>
    );
  }
};
