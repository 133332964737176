import { Alert, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../controllers/API";
import { Link } from "react-router-dom";

export const EverifyStart = ({onClick}) => {


 

    return (
      <Alert severity={'warning'}>
        Everify Status: NOT STARTED - <div style={{ display:'inline', cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={onClick}>Click here to start E-Everify</div>
         
      </Alert>
    );
  
};
