// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children, requireSubscription }) => {
  const token = Cookies.get('token');
  const user = JSON.parse(Cookies.get('user') || '{}');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (requireSubscription && !user.subscriptionActive) {
    return <Navigate to="/account" replace />;
  }

  return children;
};

export default ProtectedRoute;
