import React, { useEffect, useState, useRef } from "react";
import { AppBar, Box, Grid, Paper, Typography, Button, Modal, TextField, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { NavBar } from "../../components/NavBar";
import { TitleBar } from "../../components/TitleBar";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { formatDate } from "../../utils/Global-Functions";
import ToolTipButton from "../../components/TooltipButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SignatureCanvas from 'react-signature-canvas';
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../controllers/API";
import LoaderModal from "../../components/LoaderModal";
import { DeleteOutlined } from "@mui/icons-material";
import uuid from 'react-uuid';
import { SupplementBModal } from "../../components/SupplementBModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height:'95%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Set overflowY to 'auto' for vertical scrolling
  };


export const SupplementB = ()=>{
    const [loading, setLoading] = useState(true)
    const [formModal, setFormModal] = useState(false)
    const [sectionOne, setSectionOne] = useState(false)
    const [sectionTwo, setSectionTwo] = useState(false)
    const [imgModal, setImgModal] = useState(false)
    const [img, setImg] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [update, setUpdate] = useState(true);
    const [selectedData, setSelectedData] = useState({})

    let date = new Date();
    const preparerSigCanvas = useRef({});

    const [newSupplementB, setNewSupplementB] = useState(
      {
      id:uuid(),
      dateOfRehire:'',
      lastName: '',
      firstName: '',
      middleInitial: '',
      listType:'listA',
      documentTitle: '',
      documentNumber: '',
      expirationDate: '',
      signature: '',
      date: date,
      employerLastName: '',
      employerFirstName: '',
      additionalInformation:'',
      alternativeProcedure: false,
      documentFront:null,
      documentBack:null

      })
    let navigate = useNavigate();
    const {state} = useLocation();
    const {sOne,sTwo, id, i9Id, location} = state;

    const handleOpenModal = (a)=>{

        setFormModal(true);
    }

    const clearDocuments = ()=>{
      setNewSupplementB((prevData) => ({
        ...prevData,
        listType:'',
        documentTitle: '',
        documentNumber: '',
        expirationDate: '',
        documentBack:null,
        documentFront:null
      }));
    }

    const handleCloseModal = (a)=>{

        setFormModal(false);
    }
    const handleChange = (e)=>{
        setNewSupplementB((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
          }));
          console.log(newSupplementB)
    }

    const handleCheckboxChange = () => {
        setNewSupplementB((prevData) => ({
          ...prevData,
            alternativeProcedure: !newSupplementB.alternativeProcedure,
          }));
          
      };

      const handleSubmit = async()=>{

  setLoading(true)

if(newSupplementB.documentFront !== null && newSupplementB.documentBack !== null){
  const fileFront = await uploadFile(newSupplementB.documentFront, `${id}/${i9Id}/supplement-b/${newSupplementB.id}`)
  const fileBack = await uploadFile(newSupplementB.documentBack, `${id}/${i9Id}/supplement-b/${newSupplementB.id}`)
  const modifiedLinkFront = fileFront.replace("www.dropbox.com", "dl.dropboxusercontent.com");
  const modifiedLinkBack= fileBack.replace("www.dropbox.com", "dl.dropboxusercontent.com");

  
  const updatedSupData = {
    ...newSupplementB,
    documentFront: modifiedLinkFront,
    documentBack: modifiedLinkBack,

  };

 // Make a copy of selectedData to avoid direct mutations

  
  
  // Now, preparedData is ready with the updated supplementB and can be used to reactivate an employee
  saveFormData(updatedSupData)

} else {

    
  const updatedSupData = {
    ...newSupplementB,

  };
 

  saveFormData(updatedSupData)

}
  

 
}

      const handleFileUploadFront = (file, document) => {
        setNewSupplementB((prevData) => ({
            ...prevData,
              [document]: file,
            }));
      }
  
      const handleFileUploadBack = (file, document) => {
        setNewSupplementB((prevData) => ({
            ...prevData,
              [document]: file,
            }));
      }


    const clearSignature = () => {
        preparerSigCanvas.current.clear();
        setNewSupplementB((prevData) => ({
          ...prevData,
          signature: '',
        }));
      };
    
      const saveSignature = () => {
        const signatureImage = preparerSigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        // Save or send the signatureImage as needed
        setNewSupplementB((prevData) => ({
          ...prevData,
          signature: signatureImage,
        }));
     
        console.log(newSupplementB);
      };

      const handleOpenImgModal = (a)=>{
        console.log(a)
        setImg(a)
        setImgModal(true);
    }

    const handleCloseImgModal = (a)=>{
        setImg('')
        setImgModal(false);
    }

    const deleteItem = async (id, i9id, supBId)=>{
      const conf = window.prompt('Are you sure you would like to delete this? It cannot be recovered once deleted. Please give a brief reason for deleting this Supplement B:')
    
    if(conf){
      let resp = await axios.post(API +'/i9/deleteSupplementB', {userId: id, i9Id: i9id, supBId: supBId, location:location, reason: conf});
   
      console.log(resp)
     if(resp.data.message === 'Supplement-B item deleted successfully'){
      setUpdate(!update)
      
    
     } else {
    window.alert('Something went wrong. Please try again.')
     }
   
    

   }
   }

   async function uploadFile(a, folderText) {
   
    const file = a;
    const folder = folderText;
  
    if (!file || !folder) {
      alert('Please select a file and enter a folder name.');
      return;
    }
  
    const fileFormData = new FormData();
    fileFormData.append('file', file);
    fileFormData.append('folder', folder);
  
    try {
      const response = await axios.post(API+'/file/upload', fileFormData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });
  
      // Ensure the response is successful (status code 2xx)
      if (response.data.ok) {
        // Extract the shared link directly as a string
        const sharedLink = await response.data.sharedLink;
  console.log(sharedLink)
        let modifiedLink = sharedLink.replace("www.dropbox.com", "dl.dropboxusercontent.com");
  
  
            const updatedData = {
              ...newSupplementB,
              document: modifiedLink,
            };
          
            // Use updatedData for any immediate operations
          
            // For example, you can log it here
            console.log(updatedData);
            
  return sharedLink
        
  
              
      } else {
        // Handle non-successful response (status code other than 2xx)
        console.error('Error uploading file:', response.statusText);
        alert('Error uploading file. Please try again.');
        setLoading(false)
        return false
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      
      alert('Error uploading file. Please try again.');
      setLoading(false)
  
      return false
    }
  }

      const saveFormData = async (formData ) => {
        try {
          
          // Make a POST request to the server route '/save-form'
          const response = await axios.post(API +'/i9/save-sup-b', {
            id: id, 
            i9Id: i9Id,
            formData: formData,
            location: location
          });
      
          // Check the response and handle accordingly
          if (response.data.success) {
            // Form data saved successfully
            console.log('Form data saved successfully!');
            setUpdate(!update)
            setUploading(false)
            setNewSupplementB(
              {
              id:uuid(),
              dateOfRehire:'',
              lastName: '',
              firstName: '',
              middleInitial: '',
              listType:'listA',
              documentTitle: '',
              documentNumber: '',
              expirationDate: '',
              signature: '',
              date: date,
              employerLastName: '',
              employerFirstName: '',
              additionalInformation:'',
              alternativeProcedure: false,
              document:null
      
              })
            console.log(response.data)
            // You can navigate to another page or perform additional actions here
          } else if (response.data.notFound) {
            // Form not found
            setUpdate(!update)
            setUploading(false)
            handleCloseModal()
            console.log('Form not found');
          } else if (response.data.sectionOneNotFound) {
            // sectionOne not found
           
          } else {
            // Handle other errors
            setUpdate(!update)
            setUploading(false)
            handleCloseModal()
            console.error('An error occurred:', response.data.error);
          }
        } catch (error) {
          // Handle network or other errors
          setUploading(false)

          console.error('An error occurred:', error.message);
        }
      };



    

    useEffect(()=>{

      const checkData = async ()=>{
       
        const resp = await axios.post(API+'/i9/check-form',{id: id, i9Id: i9Id, location: location})
         console.log(resp.data)
       
        if(resp.data.notFound){
          console.log('error')
    
          // navigate('/errorPage'); 

      }
      setSectionOne(resp.data.sectionOne);
      setSectionTwo(resp.data.sectionTwo);

      setNewSupplementB((prevData)=>({
        ...prevData,
        firstName: resp.data.sectionOne.firstName,
        lastName: resp.data.sectionOne.lastName,
        middleInitial:resp.data.sectionOne.middleInitial,
        employerFirstName: resp.data.sectionTwo.employerFirstName,
        employerLastName: resp.data.sectionTwo.employerLastName
      }))
    
      setLoading(false)
      handleCloseModal()
   
    }
    checkData()
        

    },[update])
    if(loading){
        
      return (<LoaderModal open={true}/>)

} else {
    return(<Box sx={{ height: '100vh', background: '#F4F4F4' }}>
        <NavBar/>
        <TitleBar
        NavigationTitle="Back"
        Title={<Typography sx={{fontSize:24}} variant="button"><b>Supplement B - Reverification and Rehire</b></Typography>}
        To={-1}
        State={{a: id,b:i9Id}}
        />
        <Box sx={{padding:5}}>
        <Grid sx={{marginTop:2, padding:3}} component={Paper} container>
                  <Grid item xs={12}>
                    <hr/>
              <Typography sx={{fontSize:18}} variant="button"><b>Employee Information</b></Typography>
              <hr/>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>Last Name *</b></Typography>
              <Typography>{sectionOne.lastName}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>First Name *</b></Typography>
            <Typography>{sectionOne.firstName}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Middle Initial</b></Typography>
            <Typography>{sectionOne.middleInitial}</Typography>
            </Grid>
            </Grid>
        {sectionTwo.supplementB.map(i=>{
          console.log(i.documentFront)
            return (<Paper sx={{padding:5, marginTop:2}}>

                  <Grid container>

                  <Grid sx={{marginTop:2}} item xs={12} sm={12}>

                  <hr/>
                    <Typography variant="button"><b>Employee Information</b></Typography>
                    <hr/>
                    </Grid>
                 
            <Grid item xs={12} sm={3}>
          
              <Typography ><b>Last Name *</b></Typography>
              <Typography>{i.lastName}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <Typography ><b>First Name *</b></Typography>
            <Typography>{i.firstName}</Typography>
            </Grid>
            <Grid  item xs={12} sm={3}>
            <Typography ><b>Middle Initial</b></Typography>
            <Typography>{i.middleInitial}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <Typography ><b>Date of Rehire</b></Typography>
            <Typography>{formatDate(i.dateOfRehire)}</Typography>
            </Grid>
            
    
           
           <Grid spacing={2} container>
           { i.documentFront !== null && (
            <>
            <Grid item xs={12} sm={12}>
              <hr/>
           <Typography sx={{marginTop:2}} variant="button"><b>Document Information</b></Typography>
           <hr/>
          </Grid>
                 
                  <Grid sx={{marginBottom:1}} item xs={12} sm={12}>
            <Typography sx={{fontSize:16}} variant="button"><b>{i.listType === 'listA' ? 'List A Document' : 'List C Document'}</b></Typography>
            <br/>

            </Grid>
            
            <Grid item xs={12} sm={4}>
            <Typography ><b>Document Title</b></Typography>
            <Typography>{i.documentTitle}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>Document Number</b></Typography>
            <Typography>{i.documentNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>Expiration Date</b></Typography>
            <Typography>{formatDate(i.expirationDate)}</Typography>

            </Grid>
            </>
           )}
            
            { i.documentFront !== null && (
              <Grid item xs={12} sm={4}>
            <Typography ><b>View Document Front</b></Typography>
            <Button onClick={()=>handleOpenImgModal(i.documentFront)}>View Document</Button>

            </Grid>
            )}

      { i.documentBack !== null && (
            
            <Grid item xs={12} sm={4}>
            <Typography ><b>View Document Back</b></Typography>
            <Button onClick={()=>handleOpenImgModal(i.documentBack)}>View Document</Button>

            </Grid>
             )}

            </Grid>

           
            </Grid>

            <Typography sx={{marginTop:2}} variant="button"><b>Employer Information</b></Typography>

            <Grid sx={{marginTop:2}} container>

            <Grid spacing={2} container>
            <Grid item xs={12} sm={12}>
            <Typography ><b>Employer / Representitive Name:</b></Typography>
            <Typography>{i.employerFirstName+ ' ' + i.employerLastName}</Typography>
            </Grid>
           
            </Grid>
           
            <Grid sx={{marginTop:2}} item xs={12} sm={6}>
            <Typography ><b>Employer / Representative Signature:</b></Typography>
                 <img style={{width:'100%'}} src={i.signature} alt="Base64 Image"/>

            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
              <Typography ><b>Date:</b></Typography>
              <Typography>{formatDate(i.date)}</Typography>
              <IconButton onClick={()=>deleteItem(id,i9Id,i.id)} sx={{float:'right'}}><DeleteOutlined htmlColor="tomato"/></IconButton>

            </Grid>
            
            </Grid>

            </Paper>)
        })}
        <Box sx={{border: '1px black dashed', height:'250px', marginTop:5, justifyContent:'center', display:'flex', alignItems:'center'}}>
        <ToolTipButton
        Title="Add New"
         onClick={handleOpenModal}
        Icon={<AddCircleIcon sx={{height:45, width:45}}/>}
          
           />
        </Box>

        <SupplementBModal
        newSupplementB={newSupplementB}
        setNewSupplementB={setNewSupplementB}
        formModal={formModal}
        handleCheckboxChange={handleCheckboxChange}
        handleCloseModal={handleCloseModal}
        handleChange={handleChange}
        clearSignature={clearSignature}
        saveSignature={saveSignature}
        preparerSigCanvas={preparerSigCanvas}
        handleSubmit={handleSubmit}
        clearDocuments={clearDocuments}
        handleFileUploadFront={handleFileUploadFront}
        handleFileUploadBack={handleFileUploadBack}
        selectedData={selectedData}
        />
       
            <Modal 
            open={imgModal}
            onClose={handleCloseImgModal}
            >
              <Box sx={style}>
              <img style={{width:'100%', height:'auto'}} src={img}/>
              </Box>
            </Modal>
           
        </Box>
        <LoaderModal open={uploading}/>
    </Box>)
}
}