// src/App.js
import React from 'react';
import {
  Route,
  Routes,
 
} from 'react-router-dom';
import Login from './screens/Login';
import Register from './screens/Register';
import Home from './screens/Home';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import I9Setup from './screens/Forms/i9Setup';
import Everify from './screens/Forms/Everify';
import SectionOne from './screens/Forms/I9/SectionOne';
import { OutstandingI9 } from './screens/OutstandingI9';
import { I9Details } from './screens/detail-screens/i9Details';
import { SectionTwo } from './screens/Forms/I9/SectionTwo';
import { SectionTwoDetails } from './screens/detail-screens/Section-2-Details';
import { SupplementA } from './screens/detail-screens/SupplementA';
import { SupplementB } from './screens/detail-screens/SupplementB';
import { EverifyDetails } from './screens/detail-screens/Everify-Details';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AccountSettings from './screens/AccountSettings';
import { EverifySubmitDetail } from './screens/detail-screens/everify/EverifySubmitDetail';
import { I9Viewer } from './screens/detail-screens/I9Viewer';
import { I9Upload } from './screens/I9Upload';
import { I9Library } from './screens/I9Library';
import { AdminDashboard } from './Admin/screens/AdminDashboard';
import UserTransactions from './Admin/screens/UserTransactions';
import { SentSectionOne } from './screens/SentSectionOne';
import { InactiveI9s } from './screens/InactiveI9s';
import { ResourceLibrary } from './screens/ResourceLibrary';
import PDFViewerMobile from './components/PDFViewerMobile';
import ProtectedRoute from './screens/authRoutes/ProtectedRoutes';
function App() {
  const stripePromise = loadStripe('pk_test_51K49agG1vbBPoQdGCfPdytHc9OyliMvmJbqdH96aEbirAzwgNv8DWbetrre3lTZs5jLvpAli6syBaWV0yx40o7kz004OOJ4siR');

  return (
    
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path="/login" element={<Login/>} />

        <Route path="/register" element={<Register/>} />
        {/* <Route path="/account" element={<AccountSettings/>} /> */}
        <Route
          path="/account"
          element={
            <ProtectedRoute requireSubscription={false}>
              <AccountSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute requireSubscription={true}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Home/>} />
        <Route path="/forms/i9" element={<I9Setup/>} />
        <Route path="/forms/everify" element={<Everify/>} />
        <Route path="/forms/section-one" element={<SectionOne/>} />
        <Route path="/resource-library" element={<ResourceLibrary/>} />
        <Route path="/i9/section-one-sent" element={<SentSectionOne/>} />
        <Route path="/forms/section-two" element={<SectionTwo/>} />
        <Route path="/i9/pending" element={<OutstandingI9/>} />
        <Route path="/i9/library" element={<I9Library/>} />
        <Route path="/i9/inactives" element={<InactiveI9s/>} />
        <Route path="/i9s/details" element={<I9Details/>} />
        <Route path="/i9s/section-two-details" element={<SectionTwoDetails/>} />
        <Route path="/i9s/supplement-a" element={<SupplementA/>} />
        <Route path="/i9s/supplement-b" element={<SupplementB/>} />
        <Route path="/i9s/everify" element={<EverifyDetails/>} />
        <Route path="/pdf/view" element={<PDFViewerMobile/>} />

 
        <Route path="/everify/details" element={<EverifySubmitDetail/>} />
        <Route path="/i9/view" element={<I9Viewer/>} />
        <Route path="/i9/upload" element={<I9Upload/>} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin/user/transactions" element={<UserTransactions />} />


        {/* Add additional routes as needed */}

      </Routes>
      </Elements>

  );
}

export default App;
