import React, { useState } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Container,
} from '@mui/material';

const ConfirmDetailsForm = ({ updateField, setConfirmDetails, confirmDetails, updateValue }) => {
    const [selectedValue, setSelectedValue] = useState('confirm');
    const [updateValueState, setUpdateValueState] = useState(''); // Updated state name to avoid conflict
  
    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
    };
  
  
    const handleUpdateValueChange = (event) => {
        const updatedDetails = confirmDetails.map((detail) => {
          if (detail.field_name === updateField) {
            return {
              ...detail,
              value: event.target.value,
            };
          }
          return detail;
        });
    console.log(updatedDetails)
        setConfirmDetails(updatedDetails);
      };
  
    return (
      <Container>
           <RadioGroup
        name="confirmDetails"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="confirm"
          control={<Radio />}
          label="Information entered matches Form I-9"
        />
        <FormControlLabel
          value="update"
          control={<Radio />}
          label="Information entered does not match Form I-9 (field for update will be provided below)"
        />
      </RadioGroup>
  
        {selectedValue === 'update' && (
          <>
            <TextField
              label="Value for Update"
              variant="outlined"
              fullWidth
              value={confirmDetails.find((detail) => detail.field_name === updateField)?.value || ''}
              onChange={handleUpdateValueChange}
              />
              </>
        )}
      </Container>
    );
  };

export default ConfirmDetailsForm;
