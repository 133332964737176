import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { NavBar } from '../components/NavBar';
import { Box, Container, Divider, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { TitleBar } from '../components/TitleBar';
import { formatDate } from '../utils/Global-Functions';
import { SearchBar } from '../components/SearchBar';
import ToolTipButton from '../components/TooltipButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CheckCircle, ContentCopy, Delete, DeleteOutline, Visibility } from '@mui/icons-material';
import { API } from '../controllers/API';
import { Button } from 'react-bootstrap';
import LoaderModal from '../components/LoaderModal';




export const I9Library = ()=>{
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [update, setUpdate] = useState(true)
    const [anchorQmEl, setAnchorQmEl] = useState(null);


    const handleQuickMenuOpen = (event) => {
    
        const libLength = user.data.i9Library.length;
        const pendingLength = user.data.i9.length;
        const inactiveLength = user.data.inactive.length;
       const membership = user.membership;
  
    
        const total = libLength + pendingLength + inactiveLength;
        console.log(total)
       if(membership === 'basic' && total >= 50){
        window.alert('You have filled all of your I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
  
       }
       else if(membership === 'Pro' && total >= 200){
        window.alert('You have filled all of your I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
       } else {
        setAnchorQmEl(event.currentTarget);
       }
     
       
      
    };
  
    // Function to close the menu
    const handleQuickMenuClose = () => {
      setAnchorQmEl(null);
     
    };
    useEffect(() => {
        // Fetch user data from the server when the component mounts or when the user comes back to the page
        const fetchUserData = async () => {
          try {
            // Check if the token cookie exists before making the request
            const token = Cookies.get('token');
            if (!token) {
              console.error('Token not found. Redirecting to login.');
              navigate('/login');
              return;
            }
    
            const response = await axios.post(API+ '/auth/refresh-user', { token });
            const refreshedUser = response.data.user;
    
            // Update the user data in the state
            setUser(refreshedUser);
            console.log(user)
            setLoading(false)
          } catch (error) {
            console.error('Error fetching user data:', error.message);
            navigate('/login'); // Redirect to the login page in case of an error
          }
        };
    
        fetchUserData();
      }, [navigate, update]);


      const copyLink = async (a,b)=>{
  
        let localLink =`http://localhost:3000/#/forms/section-one?a=${a}&b=${b}`
        let link = `http://app.i9helpdesk.com/#/forms/section-one/?a=${a}&b=${b}`
        await navigator.clipboard.writeText(localLink)
        window.alert('Copied Link to clipboard. Now you can use this link to personally email the employee their link to complete the section 1 of the form.')
        }

        const deleteI9 = async (a,b, c)=>{
          const conf = window.prompt('Are you sure you would like to do this? It cannot be undone and will result in all of the I9 data being deleted. Please write a brief reason this I-9 is being deleted:')
          if(conf){
          let resp = await axios.post(`${API}/i9/delete-i9`,{id:a, i9Id:b, type:c, location:'library', reason:conf})

          if(resp.data.deleted){
            
            setUpdate(!update)
          } else{
            window.alert('Something went wrong. Please try again')
          }
        }
        }

        const markInactive = async (a,b)=>{
          const conf = window.confirm('Are you sure you would like to do this?')
          if(conf){
            const date = new Date()
            const updated = {
              ...b,
              dateOfTermination: date
            }
          let resp = await axios.post(`${API}/i9/mark-inactive`,{id:a, data:updated})

          if(resp.data.success){
            setLoading(true);
            setUpdate(!update)
          } else{
            window.alert('Something went wrong. Please try again')
          }
        }
        }

      if(loading){
          return (<LoaderModal open={true}/>)
      } else {
        
    return(
        <Box sx={{ height: '100vh', background: '#F4F4F4' }}>
        <NavBar />
        <TitleBar
        NavigationTitle="Back"
        To="/"
        Title={'I9 Library'}
        DisplayButton={true}
        ButtonTitle={'+ Add New I9'}
        onClick={handleQuickMenuOpen}
        // onClick={()=>navigate('/forms/i9')}
        Menu={
          <Menu
            
            id="profile-menu"
            anchorEl={anchorQmEl}
            open={Boolean(anchorQmEl)}
            onClose={handleQuickMenuClose} 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
         
          >
            <MenuItem>
              <Link style={ {marginRight:2, textDecoration:'none'}}  to='/forms/i9'> <ListItemText>
               <Typography variant='button'>Start New I9</Typography>
              </ListItemText>
              </Link>
             
              </MenuItem>
              <Divider/>
            <MenuItem>
              <Link style={ {marginRight:2, textDecoration:'none'}}  to='/i9/upload'> <ListItemText>
              <Typography variant='button'>Upload Employees I9</Typography>
              </ListItemText>
              </Link>
             
              </MenuItem>

          </Menu>}
        />
        <SearchBar
        label="SEARCH BY EMPLOYEE NAME"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        />
      <hr/>  
        <Box sx={{ margin:3 }} component={Paper}>
          {/* Search bar */}
          

          <Table>
            <TableHead>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Date Added</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell align='center'>
                <b>Actions</b>
              </TableCell>
            </TableHead>
            <TableBody>
            {user.data.i9Library.filter(i =>
          (i.sectionOne?.firstName ? i.sectionOne?.firstName : i.firstName).toLowerCase().includes(searchInput.toLowerCase()) ||
          (i.sectionOne?.lastName ? i.sectionOne?.lastName : i.lastName).toLowerCase().includes(searchInput.toLowerCase())
        ).map(i=>{
                    return (
                        <TableRow>
                        <TableCell>{i.sectionOne?.firstName ? i.sectionOne?.firstName : i.firstName} {i.sectionOne?.lastName ? i.sectionOne?.lastName : i.lastName}</TableCell>
                        <TableCell>{formatDate(i.date)}</TableCell>
                        <TableCell sx={{color:i.type === 'upload' ? 'green' : i.sectionOne?.submitted && i.sectionTwo?.submitted ? 'green' : i.sectionOne?.submitted && i.sectionTwo?.submitted === false  ? 'orange': 'orange'}}><b>{i.type === 'upload' ? 'UPLOADED AND VIEWABLE' : i.sectionOne?.submitted && i.sectionTwo?.submitted ? 'Completed' : i.sectionOne?.submitted === false && i.sectionTwo?.submitted === false  ? 'Section 1 Completed, Section 2 Ready': 'Sent to Employee'}</b></TableCell>
                        <TableCell align='center'>

                        <ToolTipButton
                         Title="View I9"
                         onClick={()=>{navigate('/i9/view',{ state:{a: user._id, b: i}})}}
                         Icon={<Visibility/>}
                         sx={{display:i.type === 'upload' ? 'inline': 'none' }}
                         />
                        
                         <ToolTipButton
                         Title="View Details"
                         onClick={()=>{navigate('/i9s/details',{ state:{a: user._id, b: i.id, c:'library'}})}}
                         Icon={<Visibility/>}
                         sx={{display:i.type === 'upload' ? 'none' : i.sectionOne?.submitted ? 'inline': 'none' }}
                         />
                         <ToolTipButton
                         Title="Copy link to Section One Form"
                         Icon={<ContentCopy/>}
                         onClick={()=>copyLink(user._id, i.id)}
                         sx={{display:i.type === 'upload' ? 'none' : i.sectionOne?.submitted ? 'none': 'inline'}}
                         />
                         <ToolTipButton
                         Title="Make I9 Inactive"
                         onClick={()=>markInactive(user._id, i)}
                         Icon={<RemoveCircleIcon/>}
                         sx={{color:'tomato'}}
                         />
                         <ToolTipButton
                         Title="Delete"
                         onClick={()=>deleteI9(user._id, i.id, i.type)}
                         Icon={<Delete/>}
                         sx={{color:'tomato'}}
                         />
                         
                       
                        </TableCell>
                        </TableRow>
                        
                    )
                })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )

}
}