import { Box, Typography } from '@mui/material';
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const issuingAuthoritiesData = {
    ListA: [
        { document: "U.S. Passport", issuingAuthority: "The issuing authority will be listed on the lower right of the photo page in the passport. (Ex: U.S. Department of State or National Passport Center or State Passport Agency)" },
        { document: "U.S. Passport Card", issuingAuthority: "U.S. Department of State" },
        { document: "Permanent Resident Card or Alien Registration Receipt Card (Form I-551)", issuingAuthority: "USCIS (United States Citizenship and Immigration Services)" },
        { document: "Foreign Passport contains temporary I-551 stamp or temporary printed notation on a machine-readable immigrant visa", issuingAuthority: "The Country from which the foreign passport was issued (Ex: China, France, Spain, etc.)" },
        { document: "Employment Authorization Document that contains a photograph (Form I-766)", issuingAuthority: "USDHS (United States Department of Homeland Security)" },
        { document: "Foreign Passport with Form I-94", issuingAuthority: "The Country from which the foreign passport was issued. (Ex: China)" },
        { document: "Passport from Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94", issuingAuthority: "The nation that issued the passport. (Ex: Federated States of Micronesia or Marshall Islands)" }
    ],
    ListB: [
        { document: "Driver’s License", issuingAuthority: "State from which the Driver’s License was issued (Ex: State of Florida, State of California, State of Pennsylvania, etc.)" },
        { document: "Learner’s Permit", issuingAuthority: "State from which the Driver’s License was issued (Ex: State of Florida, State of California, State of Pennsylvania, etc.)" },
        { document: "Identification Card", issuingAuthority: "State from which the Identification Card was issued (Ex: State of Florida, State of Massachusetts, State of Hawaii, etc.)" },
        { document: "School Identification Card", issuingAuthority: "School from which the School Identification Card was issued (Ex: University of Central Florida)" },
        { document: "Voter’s Registration Card", issuingAuthority: "County or territory from which the voter registration card was issued (Ex: Puerto Rico)" },
        { document: "U.S. Military Card or Draft Record", issuingAuthority: "Military Branch from which the Military Card or Draft Record was issued (Ex: Navy, Marines, Army, etc.)" },
        { document: "Military dependent’s ID Card", issuingAuthority: "Military Branch from which the Military dependent’s ID card was issued (Ex: Navy, Marines, Army, etc.)" },
        { document: "U.S. Coast Guard Merchant Mariner Card", issuingAuthority: "U.S. Coast Guard" },
        { document: "Native American Tribal Document", issuingAuthority: "Native American Tribe from which the document was issued (Ex: Choctaw, Dakota Sioux, Navajo, etc.)" },
        { document: "Driver’s License issued by a Canadian Government Authority", issuingAuthority: "Province or Territory from which the driver’s license was issued (Ex: Ontario, Alberta, etc.)" }
    ],
    ListB_Under18: [
        { document: "School record or report card", issuingAuthority: "School from which the school record or report card was issued (Ex: Lyman High School)" },
        { document: "Clinic, doctor, or hospital record", issuingAuthority: "Clinic, Doctor, or Hospital from which the record was issued (Ex: Florida Hospital)" },
        { document: "Day-care or nursery school record", issuingAuthority: "Day-care or nursery school from which the record was issued (Ex: Kids are Kids, etc.)" }
    ],
    ListC: [
        { document: "Social Security Card", issuingAuthority: "Listed on the Seal or ring in the middle of the Social Security Card (Ex: Social Security Administration or Department of Health and Human Services)" },
        { document: "Social Security Receipt", issuingAuthority: "Social Security Administration" },
        { document: "Certification of Birth Abroad (FS-545)", issuingAuthority: "U.S. Department of State" },
        { document: "Certification of Report of Birth (DS-1350)", issuingAuthority: "U.S. Department of State" },
        { document: "Original or certified copy of birth certificate bearing an official seal", issuingAuthority: "State of the U.S. from which the certificate was issued (Ex: State of Florida)" },
        { document: "Native American tribal document", issuingAuthority: "Native American Tribe from which the document was issued (Ex: Choctaw, Dakota Sioux, Navajo, etc.)" },
        { document: "U.S. Citizen ID Card (Form I-197)", issuingAuthority: "INS (Immigration and Nationality Service)" },
        { document: "Identification Card for Use of Resident Citizen in the U.S. (From I-179)", issuingAuthority: "INS (Immigration and Nationality Service)" },
        { document: "Employment authorization document other than those stated under the List A documents", issuingAuthority: "USDHS (United States Department of Homeland Security)" }
    ]
};

const DocumentsTable = ({ documents }) => {
    return (
     
        <Table striped bordered hover>
            <thead>
                <tr>
                    {/* <th>List</th> */}
                    <th>Document</th>
                    <th>Issuing Authority</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(documents).map(list => (
                    documents[list].map((doc, index) => (
                        <tr key={index}>
                            {/* <td>{list}</td> */}
                            <td>{doc.document}</td>
                            <td>{doc.issuingAuthority}</td>
                        </tr>
                    ))
                ))}
            </tbody>
        </Table>
    );
};

const IssuingAuthoritiesModal = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="documents-modal-title">
            <Modal.Header closeButton>
                <Modal.Title><Typography sx={{fontSize:18}}><b>Issuing Authorities for List A, B, and C Documents”</b></Typography>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>                
        {Object.keys(issuingAuthoritiesData).map((list, index) => (
            <Box sx={{marginTop:3}}>
                <Typography variant='h6'><b>{index === 0 ? 'List A': index === 1 ? 'List B': 'List C'}</b></Typography>
                    <DocumentsTable key={index} documents={{ [list]: issuingAuthoritiesData[list] }} />
                    </Box>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default IssuingAuthoritiesModal;
