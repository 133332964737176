import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

function ToolTipButton(props) {
  const tooltipStyle = {
    zIndex: 1000, // You can adjust this value as needed
  };

  return (
    <Tooltip sx={props.style} title={props.Title} arrow placement="top" style={tooltipStyle}>
      <IconButton sx={props.sx} onClick={props.onClick} color="primary">
        {props.Icon}
      </IconButton>
    </Tooltip>
  );
}

export default ToolTipButton;
