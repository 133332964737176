import React from "react";
import '../assets/css/title-bar.css'
import { Button, Typography, Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { ArrowBackIosNew } from '@mui/icons-material';

export const TitleBar = (props) => {
  return (
    <Grid container className="title-bar">
     
      <Grid item xs={12} sm={9}>
        <Typography
          sx={{
            display: 'flex',
            fontSize: { xs: 24, sm: 28 },
          }}
          variant='button'
        >
          <b>{props.Title}</b>
        </Typography>
        <Link
          state={props.State}
          style={{ textDecoration: 'none', color: 'white' }}
          to={props.To}
        >
          <Button
            style={{
              display: props.NavButtonDisplay ? props.NavButtonDisplay : 'flex',
              color: 'white',
              justifyContent: 'flex-end', // Align text to the right
            }}
          >
            <ArrowBackIosNew />
            {props.NavigationTitle}
          </Button>
        </Link>
      </Grid>
     
       <Grid sx={{textAlign:'end'}} item xs={12} sm={3}>
        <Button
          onClick={props.onClick}
          variant="contained"
          sx={{
            display: props.DisplayButton ? 'inline' : 'none',
            marginTop: { xs: 2, sm: 3 },
            backgroundColor: '#FCBD25',
          }}
        >
          {props.ButtonTitle} {props.ButtonIcon}
        </Button>
        {props.Menu}
      </Grid>
    </Grid>
  );
};
