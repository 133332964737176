import { Box, Typography } from '@mui/material';
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const documentsData = {
    ListA: [
        { fullName: "Permanent Resident Card (Form I-551)", abbreviation: "Form I-551 or PRC" },
        { fullName: "Alien Registration Receipt Card (Form I-551)", abbreviation: "Form I-551 or PRC" },
        { fullName: "Foreign passport containing a temporary I-551 stamp, also known as Alien Documentation, Identification and Telecommunication (ADIT) stamp.", abbreviation: "Foreign Passport, I-551 or ADIT Stamp" },
        { fullName: "Foreign passport containing a temporary I-551 printed notation on a machine-readable immigrant visa (MRIV)", abbreviation: "Foreign Passport, MRIV" },
        { fullName: "Employment Authorization Document (Form I-766)", abbreviation: "Form I-766 or EAD" },
        { fullName: "For a nonimmigrant noncitizen authorized to work for a specific employer because of his or her status, a foreign passport with Form I-94 that contains an endorsement of the noncitizen’s nonimmigrant status.", abbreviation: "Foreign Passport, Form I-94, Form I-20 or Form DS-2019" },
        { fullName: "Passport from the Federated States of Micronesia (FSM) with Form I-94", abbreviation: "FSM Passport with Form I-94, Form I-94" },
        { fullName: "Passport from the Republic of the Marshall Islands (RMI) with Form I-94", abbreviation: "RMI Passport with Form I-94, Form I-94" },
        { fullName: "Receipt: The arrival portion of Form I-94 containing a temporary I-551 (ADIT) stamp and photograph", abbreviation: "Receipt: Form I-94 w/I-551 stamp, photo" },
        { fullName: "Receipt: The departure portion of Form I-94 with an unexpired refugee admission stamp", abbreviation: "Receipt: Form I-94 w/refugee stamp" }
    ],
    ListB: [
        { fullName: "Driver's license issued by a State or outlying possession of the United States", abbreviation: "DL plus the state, e.g., VA DL" },
        { fullName: "ID card issued by a State or outlying possession of the United States", abbreviation: "ID plus the state, e.g., NY ID" },
        { fullName: "ID card issued by federal, state, or local government agencies or entities", abbreviation: "Government ID" },
        { fullName: "School ID card with photograph", abbreviation: "School ID" },
        { fullName: "U.S. Coast Guard Merchant Mariner Card", abbreviation: "USCG Merchant Mariner card" },
        { fullName: "Driver's license issued by a Canadian government authority", abbreviation: "Canadian DL" },
        { fullName: "School record (for persons under age 18 who are unable to present a document listed above)", abbreviation: "School record (under age 18)" },
        { fullName: "Report card (for persons under age 18 who are unable to present a document listed above)", abbreviation: "Report card (under age 18)" },
        { fullName: "Clinic record (for persons under age 18 who are unable to present a document listed above)", abbreviation: "Clinic record (under age 18)" },
        { fullName: "Doctor record (for persons under age 18 who are unable to present a document listed above)", abbreviation: "Doctor record (under age 18)" },
        { fullName: "Hospital record (for persons under age 18 who are unable to present a document listed above)", abbreviation: "Hospital record (under age 18)" },
        { fullName: "Day-care record (for persons under age 18 who are unable to present a document listed above)", abbreviation: "Day-care record (under age 18)" },
        { fullName: "Nursery school record (for persons under age 18 who are unable to present a document listed above)", abbreviation: "Nursery school record (under age 18)" },
        { fullName: "Individual under age 18 endorsement by parent or guardian", abbreviation: "Individual under Age 18" },
        { fullName: "Special placement endorsement for persons with disabilities", abbreviation: "Special Placement" }
    ],
    ListC: [
        { fullName: "Social Security Account Number card without restriction", abbreviation: "SS Card" },
        { fullName: "Certification of Birth Abroad (Form FS-545)", abbreviation: "Form FS-545" },
        { fullName: "Certification of Report of Birth (Form DS-1350)", abbreviation: "Form DS-1350" },
        { fullName: "Consular Report of Birth Abroad (Form FS-240)", abbreviation: "Form FS-240" },
        { fullName: "Original or certified copy of a U.S. birth certificate bearing an official seal", abbreviation: "Birth Certificate" },
        { fullName: "U.S. Citizen ID Card (Form I-197)", abbreviation: "Form I-197" },
        { fullName: "Identification Card for use of Resident Citizen in the United States (Form I-179)", abbreviation: "Form I-179" },
        { fullName: "Employment authorization document issued by DHS (List C #7)", abbreviation: "Employment Auth. document (DHS) List C #7" }
    ]
};

const DocumentsTable = ({ documents }) => {
    return (
     
        <Table striped bordered hover>
            <thead>
                <tr>
                    {/* <th>List</th> */}
                    <th>Full Name of Document</th>
                    <th>Common Abbreviation</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(documents).map(list => (
                    documents[list].map((doc, index) => (
                        <tr key={index}>
                            {/* <td>{list}</td> */}
                            <td>{doc.fullName}</td>
                            <td>{doc.abbreviation}</td>
                        </tr>
                    ))
                ))}
            </tbody>
        </Table>
    );
};

const DocumentsModal = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="documents-modal-title">
            <Modal.Header closeButton>
                <Modal.Title><Typography sx={{fontSize:18}}><b>Here are the “Common Abbreviations for Document Entry in Section 2”</b></Typography>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

        <Typography>This appendix includes common abbreviations for some of the documents that employees may present from the Lists of Acceptable Documents. This appendix is not an exhaustive list of all the documents an employee may choose to present. See the Lists of Acceptable Documents on Form I-9 for a complete list.</Typography>
                
        {Object.keys(documentsData).map((list, index) => (
            <Box sx={{marginTop:3}}>
                <Typography variant='h6'><b>{index === 0 ? 'List A': index === 1 ? 'List B': 'List C'}</b></Typography>
                    <DocumentsTable key={index} documents={{ [list]: documentsData[list] }} />
                    </Box>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DocumentsModal;
