import { Box, Divider, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { TitleBar } from "../../components/TitleBar";
import PDFViewer from "../../components/PDFViewer";
import { NavBar } from "../../components/NavBar";
import '../../assets/css/pdf-viewer.css'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ArrowDropDown } from "@mui/icons-material";
import uuid from "react-uuid";
import axios from "axios";
import { API } from "../../controllers/API";
import { SupplementBModal } from "../../components/SupplementBModal";

export const I9Viewer = ()=>{
    const {state} = useLocation();
    const {a, b} = state;
    const [anchorQmEl, setAnchorQmEl] = useState(null);
    const [selectedData, setSelectedData] = useState({})
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(()=>{
      console.log(a)
      console.log(b)

    },[])

      // SupB Modal
   let date = new Date();
   const preparerSigCanvas = useRef({});

   const [newSupplementB, setNewSupplementB] = useState(
       {
       id:uuid(),
       dateOfRehire:'',
       lastName: '',
       firstName: '',
       middleInitial: '',
       listType:'listA',
       documentTitle: '',
       documentNumber: '',
       expirationDate: '',
       signature: '',
       date: date,
       employerLastName: '',
       employerFirstName: '',
       additionalInformation:'',
       alternativeProcedure: false,
       documentFront:null,
       documentBack:null

       })

   const [formModal, setFormModal] = useState(false)

   const handleCheckboxChange = () => {
     setNewSupplementB((prevData) => ({
       ...prevData,
         alternativeProcedure: !newSupplementB.alternativeProcedure,
       }));
       
   };

   const handleFileUploadFront = (file, document) => {
     setNewSupplementB((prevData) => ({
         ...prevData,
           [document]: file,
         }));
   }

   const handleFileUploadBack = (file, document) => {
     setNewSupplementB((prevData) => ({
         ...prevData,
           [document]: file,
         }));
   }

   const handleCloseModal = (a)=>{

     setFormModal(false);
 }
 const handleChange = (e)=>{
   setNewSupplementB((prevData) => ({
       ...prevData,
       [e.target.name]: e.target.value,
     }));
     console.log(newSupplementB)
}

const clearDocuments = ()=>{
 setNewSupplementB((prevData) => ({
   ...prevData,
   listType:'',
   documentTitle: '',
   documentNumber: '',
   expirationDate: '',
   documentBack:null,
   documentFront:null
 }));
}

const clearSignature = () => {
 preparerSigCanvas.current.clear();
 setNewSupplementB((prevData) => ({
   ...prevData,
   signature: '',
 }));
};

const saveSignature = () => {
 const signatureImage = preparerSigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
 // Save or send the signatureImage as needed
 setNewSupplementB((prevData) => ({
   ...prevData,
   signature: signatureImage,
 }));

 console.log(newSupplementB);
};

async function uploadFile(a, folderText) {
  
 const file = a;
 const folder = folderText;

 if (!file || !folder) {
   alert('Please select a file and enter a folder name.');
   return;
 }

 const fileFormData = new FormData();
 fileFormData.append('file', file);
 fileFormData.append('folder', folder);

 try {
   const response = await axios.post(API+'/file/upload', fileFormData, {
     headers: {
       'Content-Type': 'multipart/form-data', // Important for file uploads
     },
   });

   // Ensure the response is successful (status code 2xx)
   if (response.data.ok) {
     // Extract the shared link directly as a string
     const sharedLink = await response.data.sharedLink;
console.log(sharedLink)
     let modifiedLink = sharedLink.replace("www.dropbox.com", "dl.dropboxusercontent.com");


         const updatedData = {
           ...newSupplementB,
           document: modifiedLink,
         };
       
         // Use updatedData for any immediate operations
       
         // For example, you can log it here
         console.log(updatedData);
         
return sharedLink
     

           
   } else {
     // Handle non-successful response (status code other than 2xx)
     console.error('Error uploading file:', response.statusText);
     alert('Error uploading file. Please try again.');
     setLoading(false)
     return false
   }
 } catch (error) {
   console.error('Error uploading file:', error);
   
   alert('Error uploading file. Please try again.');
   setLoading(false)

   return false
 }
}

const fetchIP = async () => {
 try {
   const response = await axios.get('https://api.ipify.org?format=json');
    console.log(response.data.ip)
   return response.data.ip;
 } catch (error) {
   console.error('Error fetching IP address:', error);
   // Handle error or set default value
   
 }
};


const handleSubmit = async()=>{

 setLoading(true)

if(newSupplementB.documentFront !== null && newSupplementB.documentBack !== null){
 const fileFront = await uploadFile(newSupplementB.documentFront, `${user._id}/${selectedData.id}/supplement-b/${newSupplementB.id}`)
 const fileBack = await uploadFile(newSupplementB.documentBack, `${user._id}/${selectedData.id}/supplement-b/${newSupplementB.id}`)
 const modifiedLinkFront = fileFront.replace("www.dropbox.com", "dl.dropboxusercontent.com");
 const modifiedLinkBack= fileBack.replace("www.dropbox.com", "dl.dropboxusercontent.com");

 
 const updatedSupData = {
   ...newSupplementB,
   documentFront: modifiedLinkFront,
   documentBack: modifiedLinkBack,
   ipAddress: fetchIP()
 };

 let preparedData = {
   ...selectedData,
   sectionTwo: {
     ...selectedData.sectionTwo,
     supplementB: [
       ...selectedData.sectionTwo.supplementB,
       updatedSupData
     ]
   }
 };// Make a copy of selectedData to avoid direct mutations

 
 
 // Now, preparedData is ready with the updated supplementB and can be used to reactivate an employee


} else {

   
 const updatedSupData = {
   ...newSupplementB,
   ipAddress: fetchIP()
 };
 let preparedData = {
   ...selectedData,
   sectionTwo: {
     ...selectedData.sectionTwo,
     supplementB: [
       ...selectedData.sectionTwo.supplementB,
       updatedSupData
     ]
   }
 };

}
 


}

 /// End Sup B


    function downloadFile(url, fileName) {
        // Create an anchor (<a>) element
        const anchor = document.createElement('a');
        
        // Set the href to the URL pointing to the file you want to download
        anchor.href = url;
        
        // Use the 'download' attribute to specify the filename
        anchor.download = fileName || 'downloadedFile';
        
        // Append the anchor to the body (required for Firefox)
        document.body.appendChild(anchor);
        
        // Trigger the download by simulating a click on the anchor
        anchor.click();
        
        // Clean up by removing the anchor from the body
        document.body.removeChild(anchor);
      }

      const handleQuickMenuOpen = (event) => {

            setAnchorQmEl(event.currentTarget);
     
     
      };

      const handleQuickMenuClose = () => {
        setAnchorQmEl(null);
      };
    

    return (
        <Box>
      <NavBar/>
            <TitleBar
            NavigationTitle="Back"
            To={-1}
            Title={`Form I-9`}
            DisplayButton={true}
            ButtonTitle={<span>Menu<ArrowDropDown/></span>}
            onClick={
                handleQuickMenuOpen
                // ()=>downloadFile(b.i9Link, `${b.firstName} ${b.lastName}'s FORM I-9`)
            }
            // onClick={handleQuickMenuOpen}
        // onClick={()=>navigate('/forms/i9')}
        Menu={
            <Menu
              
              id="profile-menu"
              anchorEl={anchorQmEl}
              open={Boolean(anchorQmEl)}
              onClose={handleQuickMenuClose} 
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
           
            >
              <MenuItem>
                <Link style={ {marginRight:2, textDecoration:'none'}}  to='/forms/i9'> <ListItemText>
                 <Typography variant='button'>Download</Typography>
                </ListItemText>
                </Link>
               
                </MenuItem>
                <Divider/>
              <MenuItem style={ {marginRight:2, textDecoration:'none'}} onClick={()=>{
                  setFormModal(true); 
                  setSelectedData(state.b); 
                  setNewSupplementB((prevData)=>({
                          ...prevData,
                          firstName:state.b.firstName,
                          lastName: state.b.lastName,
                  }))
                  }}>
                <ListItemText>
                <Typography variant='button'>+ Add Supplement B</Typography>
                </ListItemText>
           
               
                </MenuItem>
  
            </Menu>}
           />
           

            <Box sx={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                <PDFViewer file={state.b.i9Link}/>

            </Box>
       <SupplementBModal
        newSupplementB={newSupplementB}
        setNewSupplementB={setNewSupplementB}
        formModal={formModal}
        handleCheckboxChange={handleCheckboxChange}
        handleCloseModal={handleCloseModal}
        handleChange={handleChange}
        clearSignature={clearSignature}
        saveSignature={saveSignature}
        preparerSigCanvas={preparerSigCanvas}
        handleSubmit={handleSubmit}
        clearDocuments={clearDocuments}
        handleFileUploadFront={handleFileUploadFront}
        handleFileUploadBack={handleFileUploadBack}
        selectedData={selectedData}
        />
        </Box>
    )
}