// src/components/Home.js
import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API } from '../../controllers/API';
import { NavBar } from '../../components/NavBar';
import { TitleBar } from '../../components/TitleBar';
import { Box, Button, Grid, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import PDFFormExtractor from '../../components/PDFFormExtractor';
import { countries, formatSSN, listADocuments } from '../../utils/Global-Functions';
import { Form } from 'react-bootstrap';
import EverifyConfirm from '../../components/EverifyConfirm';

const stripe = Stripe('pk_test_51K49agG1vbBPoQdGCfPdytHc9OyliMvmJbqdH96aEbirAzwgNv8DWbetrre3lTZs5jLvpAli6syBaWV0yx40o7kz004OOJ4siR');

const Everify = () => {
  let navigate = useNavigate();
  const token = Cookies.get('token');
  const [listA, setListA] = useState({
    citizenShipStatus:'---',
    docType: '---',
    countryCode:'---'
   })

   const [listB, setListB] = useState({
    citizenShipStatus:'---',
    docType: '---'
   })

   const [listC, setListC] = useState({
    citizenShipStatus:'---',
    docType: '---'
   })
   const [dateOfHire, setDateOfHire] = useState(null);
  const [user, setUser] = useState(null);
  const [showSubmitAndPay, setShowSubmitAndPay] = useState(false);
  const [file, setFile] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [requiredFields,setRequiredFields] = useState([])
  const [formFields, setFormFields] = useState([]);
  const [case_fields, setcase_fields] = useState({})
  const [caseFields, setCaseFields] = useState({
  ["client_software_version"]:'0',
  ["case_creator_name"]: '',
  ["case_creator_email_address"]: '',
  ["case_creator_phone_number"]: '',
  ["ssn"]: '',
  ["date_of_birth"]: '',
  ["date_of_hire"]: '',
  ["citizenship_status_code"]: '',
  ["client_company_id"]: '',
  ["first_name"]:'',
  ["last_name"]:'' ,
  ["document_a_type_code"]:'',
  ["document_b_type_code"]:'',
  ["document_b_type_code"]:'',
  ["document_sub_type_code"]:'',
  ["document_bc_number"]:'',
  ["us_state_code"]:'',
  ["foreign_passport_number"]:'',
  ["country_code"]:'',
  // ["i94_number"]:'',
  ["us_passport_number"]:'',
  ["i766_number"]:'',
  ["alien_number"]:'',
  ["expiration_date"]:''
  });
  const [docSubType, setDocSubType] = useState('');
  const [docStateType, setUSStateCode] = useState('');
  const [listValue, setListValue] = useState('');
  const handleChange = (event) => {
    setListValue(event.target.value);
  };

  useEffect( ()=>{
    
    const fillForm = async ()=>{

   
    const response = await axios.post(API+ '/auth/refresh-user', { token });
    const refreshedUser = response.data.user;
console.log(refreshedUser)
  


    setCaseFields((prevData)=>({
        ...prevData,
        last_name: formFields.find(item => item.name === 'Last Name (Family Name)')?.value || '',
        first_name: formFields.find(item => item.name === 'First Name Given Name')?.value || '',
        document_a_type_code: listA.docType,
        document_b_type_code: listB.docType,
        document_c_type_code: listC.docType,
        document_sub_type_code: docSubType,
        document_bc_number:formFields.find(item => item.name === 'List B Document Number 1')?.value || '',
        us_state_code: docStateType,
        country_code: listA.countryCode,
        citizenship_status_code: listValue === 'listA' ? listA.citizenShipStatus : listB.citizenShipStatus, 
        client_company_id: Number(refreshedUser.companyId),
        case_creator_name:refreshedUser.firstName + ' ' + refreshedUser.lastName,
        case_creator_email_address: refreshedUser.email,
        case_creator_phone_number: refreshedUser?.phoneNumber,
        ssn: formatSSN(formFields.find(item => item.name === 'US Social Security Number')?.value || ''),
        foreign_passport_number:formFields.find(item => item.name === 'Document Number 0 (if any)')?.value || '',
        // i94_number:formFields.find(item => item.name === 'Document Number 0 (if any)')?.value || '',
        us_passport_number:formFields.find(item => item.name === 'Document Number 0 (if any)')?.value || '',
        i766_number: formFields.find(item => item.name === 'Document Number 0 (if any)')?.value || '',
        alien_number:formFields.find(item => item.name === 'USCIS ANumber')?.value || '',
        expiration_date:(() => {
          const dateString = listValue === 'listA'? formFields.find(item => item.name === 'Expiration Date if any')?.value || '' : formFields.find(item => item.name === 'List B Expiration Date 1')?.value || '';
          if (!dateString) return ''; // Early return if no date string
          
          const [month, day, year] = dateString.split('/');
          if (!month || !day || !year) return ''; // Check for incomplete date parts
          
          const date = new Date(`${year}-${month}-${day}`);
          if (isNaN(date.getTime())) return ''; // Check if the date is invalid
          
          return date.toISOString().split('T')[0]; // Return the formatted date string if valid
        })(),
        date_of_birth:
        (() => {
          const dateString = formFields.find(item => item.name === 'Date of Birth mmddyyyy')?.value || '';
          if (!dateString) return ''; // Early return if no date string
          
          const [month, day, year] = dateString.split('/');
          if (!month || !day || !year) return ''; // Check for incomplete date parts
          
          const date = new Date(`${year}-${month}-${day}`);
          if (isNaN(date.getTime())) return ''; // Check if the date is invalid
          
          return date.toISOString().split('T')[0]; // Return the formatted date string if valid
        })(),
        date_of_hire:dateOfHire

    }))

    console.log(caseFields)

    function cleanObject(obj) {
      const cleanObj = {};
      Object.keys(obj).forEach(key => {
        if (obj[key] !== '' && obj[key] !== '---' && obj[key] !== undefined) {
          cleanObj[key] = obj[key];
        }
      });
      return cleanObj;
    }
    let case_fields = cleanObject(caseFields);
    console.log(case_fields)
    setcase_fields(case_fields)
  }
  fillForm();

  },[formFields])

  const cleanObject = (obj) => {
    const cleanObj = {};
    const usPassportNo = obj.document_a_type_code !== 'US_PASSPORT' ? ['us_passport_number']:[];
    const docB = obj.document_a_type_code === 'US_PASSPORT' ? ['document_bc_number']:[];
    const alienNo = obj.citizenship_status_code !== 'LAWFUL_PERMANENT_RESIDENT' ? ['alien_number']:[];
    const foreignPassportNoI94 = obj.document_a_type_code !== 'FOREIGN_PASSPORT_WITH_FORM_I94' && obj.document_a_type_code !== 'FOREIGN_PASSPORT_WITH_I551_STAMP' ? ['foreign_passport_number','country_code']:[];
    const i766No = obj.document_a_type_code !== '"FORM_I766"' ? ['i766_number']:[];

    Object.keys(obj).forEach((key) => {
      if (
        obj[key] !== '' && 
        obj[key] !== '---' && 
        obj[key] !== undefined && 
        !usPassportNo.includes(key) &&
        !alienNo.includes(key) &&
        !foreignPassportNoI94.includes(key) &&
        !i766No.includes(key) &&
        !docB.includes(key)

      ) {
        cleanObj[key] = obj[key];
      }
    });
    return cleanObj;
  };



  const checkDuplicates = async (case_fields)=>{

   
    console.log(case_fields);
    console.log(case_fields)
    const response = await axios.post(API+ '/auth/refresh-user', { token });
    const refreshedUser = response.data.user;
 
      const resp2 = await axios.post(API+'/everify/authentication/check-duplicates',{ssn: case_fields.ssn})
      console.log(resp2.data)
      console.log(case_fields)

       let case_field = null
        if(listValue === 'listA'){
            case_field = {
          client_company_id: Number(refreshedUser.companyId),
          ssn: case_fields.ssn, 
          ['document_a_type_code']: listA.docType,
          citizenship_status_code:listA.citizenShipStatus,
            }
        } else {
            case_field = {
              client_company_id: Number(refreshedUser.companyId),
              ssn: case_fields.ssn, 
              ['document_b_type_code']: listB.docType,
              ['document_c_type_code']: listC.docType,
              citizenship_status_code: listB.citizenShipStatus
              }
        }


        checkFields(case_field, refreshedUser, formatSSN(formFields.find(item => item.name === 'US Social Security Number').value))
      
    
  }







const checkFields = async(case_field, refreshedUser, ssn, i9Info)=>{

  console.log(case_field)
  let resp = await axios.post(API + '/everify/authentication/check-case-fields', 
  {case_fields:case_field}
  )

console.log(resp.data)

  let filter = resp.data.case_fields.filter(o=> o.required === true)

console.log(filter)
const case_fs = cleanObject(caseFields);
console.log(case_fs)
const containsAllRequiredFields = filter.every(item => {
  return item.required ? case_fs.hasOwnProperty(item.field_name) : true;
});

const result = filter.reduce(
  (acc, item) => {
    if (item.required && !case_fs.hasOwnProperty(item.field_name)) {
      acc.missingFields.push(item.field_name);
      acc.allRequiredFields = false;
    }
    return acc;
  },
  { allRequiredFields: true, missingFields: [] }
);

console.log(result);

if(result.allRequiredFields === true){
  setShowSubmitAndPay(true);
  const updatedCaseFields = {
    ...case_fs,
    client_id: parseInt(case_fs.client_id, 10),
  };
  setcase_fields(updatedCaseFields);
} else {
  window.alert(`You are missing the following information: ${result.missingFields.map((i, index)=> {return(`${i.replace(/_/g, ' ').toUpperCase()}${ index === result.missingFields.length ? ',' : ''}`)})}`)
}

console.log(containsAllRequiredFields);

}


const createCase = async ()=>{
  const response = await axios.post(API+ '/auth/refresh-user', { token });
  const refreshedUser = response.data.user;
console.log(refreshedUser)
  const response2 = await axios.post(API+ '/everify/authentication/create-case-ev', { caseObject:case_fields, id: refreshedUser._id, stripeCustomerId: refreshedUser.stripeCustomerId});

  console.log(response2)

if(response2.data.error){
  window.alert(`You are missing the following information: ${response2.data.errors.map((i, index)=> {return(`${i.message}${ index === response2.data.errors.length ? ',' : ''}`)})}`)

} else {
  window.alert('The E-Verify case has been created. You can check your case status by going to the E-Verify section from the dashboard.')
  navigate('/everify/pending')
}

}


 

 const [text, setText] = useState('')
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleLogout = () => {
    // Remove the token and user data cookies
    Cookies.remove('token');
    Cookies.remove('user');

    navigate('/login');
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorQmEl, setAnchorQmEl] = useState(null);

  // Function to open the menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // useEffect(()=>{
  //   const checkData = async ()=>{
  //     let resp = await axios.post(API+'/everify/authentication/login', {
  //       username: 'LSMI4126',
  //       password: 'Axelt2015!',
  //     })
  
  //     console.log(resp)
  //   }
  //   checkData()
    
  // },[])


  useEffect(() => {
    // Fetch user data from the server when the component mounts or when the user comes back to the page
    const fetchUserData = async () => {
      try {
        // Check if the token cookie exists before making the request
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        const response = await axios.post(API+'/auth/refresh-user', { token });
        const refreshedUser = response.data.user;

        // Update the user data in the state
        setUser(refreshedUser);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(API+'/file/upload-i9', formData);
      console.log(response.data);
      setText(response.data.parsedText)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
     <NavBar/>
      <TitleBar Title={'Start New E-Verify'}
      To='/'
      NavigationTitle={'Back'}
      />
      <Box>
        <PDFFormExtractor
        setFormFields={setFormFields}
        formFields={formFields}
        listValue={listValue}
        setListValue={setListValue}
        handleChange={handleChange}
        setListA={setListA}
        setListB={setListB}
        setListC={setListC}
        listA={listA}
        listB={listB}
        listC={listC}
        dateOfHire={dateOfHire}
        setDateOfHire={setDateOfHire}
        setDocSubType={setDocSubType}
        setUSStateCode={setUSStateCode}
        showForm={showForm}
        setShowForm={setShowForm}
        />
        {showForm && (
          <Box sx={{margin:3, padding:2}} component={Paper}>
        <Grid spacing={2} sx={{marginTop:2}} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Employee Information</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>Last Name *</b></Typography>
              <TextField
              fullWidth
              value={caseFields.last_name}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                last_name: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}
              />
              {/* <Typography>{data.sectionOne.lastName}</Typography> */}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>First Name *</b></Typography>
            <TextField
            fullWidth
            value={caseFields.first_name}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                first_name: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}
              />
            {/* <Typography>{data.sectionOne.firstName}</Typography> */}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Middle Initial</b></Typography>
            <TextField
            fullWidth
              defaultValue={formFields.find(item => item.name === 'Employee Middle Initial (if any)')?.value || ''}
              />           
               </Grid>

            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>Date of Birth *</b></Typography>
              <TextField
              type='date'
            fullWidth
            value={caseFields.date_of_birth}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                date_of_birth: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}
              />              
              </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={4}>
      <Typography>
        <b>SSN *</b>
      </Typography>
      <TextField
              fullWidth
              value={caseFields.ssn}
              onChange={(e) => {
                // Update caseFields with the new last_name value
                setCaseFields(prevData => ({
                  ...prevData,
                  ssn: e.target.value // Correctly target last_name for the update
                }));
                console.log(caseFields)
                // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
              }}
              />
    </Grid>
       
            
            <Grid sx={{marginTop:2, display: listB.citizenShipStatus === "LAWFUL_PERMANENT_RESIDENT" ? 'inline-block':'none'}}  item xs={12} sm={4}>
            <Typography ><b>USCIS Number or A-Number</b></Typography>
            <TextField
            fullWidth
            value={caseFields.alien_number}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                alien_number: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}
             />     
            </Grid>

          
                  </Grid>

                  <Grid spacing={2} sx={{marginTop:2}} container>
            <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Document Information</b></Typography>
            </Grid>
            </Grid>
           
            {listValue === 'listA' && (
              <Grid spacing={2} sx={{marginTop:2}} container>
               <Grid  item xs={12} sm={6}>
               <Typography ><b>List Type: </b> {listValue === 'listA'? 'List A': 'ListB/C'}</Typography>
                 
               </Grid>
            <Grid  item xs={12} sm={6}>
            <Typography ><b>List A Document: </b> {listA.docType.replace(/_/g, ' ')}</Typography>
              
            </Grid>

          { listA.docType === 'US_PASSPORT' && (
            <>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>US Passport Number</b></Typography>
            <TextField
            fullWidth
            value={caseFields.us_passport_number}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                us_passport_number: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}  
             />     
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Expiration Date</b></Typography>
            <TextField
            
            fullWidth
              type={'date'}
              value={
                (() => {
                  const dateString = formFields.find(item => item.name === 'Expiration Date if any')?.value || '';
                  if (!dateString) return ''; // Early return if no date string
                  
                  const [month, day, year] = dateString.split('/');
                  if (!month || !day || !year) return ''; // Check for incomplete date parts
                  
                  const date = new Date(`${year}-${month}-${day}`);
                  if (isNaN(date.getTime())) return ''; // Check if the date is invalid
                  
                  return date.toISOString().split('T')[0]; // Return the formatted date string if valid
                })()
              }
              />     
            </Grid>
            </>
          )}  

          { listA.docType === 'FOREIGN_PASSPORT_WITH_I551_STAMP' && (
            <>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Foreign Passport Number</b></Typography>
            <TextField
            fullWidth
            value={caseFields.foreign_passport_number}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                foreign_passport_number: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}  
             />     
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Expiration Date</b></Typography>
            <TextField
            
            fullWidth
              type={'date'}
              value={
                (() => {
                  const dateString = formFields.find(item => item.name === 'Expiration Date if any')?.value || '';
                  if (!dateString) return ''; // Early return if no date string
                  
                  const [month, day, year] = dateString.split('/');
                  if (!month || !day || !year) return ''; // Check for incomplete date parts
                  
                  const date = new Date(`${year}-${month}-${day}`);
                  if (isNaN(date.getTime())) return ''; // Check if the date is invalid
                  
                  return date.toISOString().split('T')[0]; // Return the formatted date string if valid
                })()
              }
              />     
            </Grid>
            </>
          )}  

{ listA.docType === 'FOREIGN_PASSPORT_WITH_FORM_I94' && (
            <>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Foreign Passport Number</b></Typography>
            <TextField
            fullWidth
            value={caseFields.foreign_passport_number}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                foreign_passport_number: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}  
             />     
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Expiration Date</b></Typography>
            <TextField
            
            fullWidth
              type={'date'}
              value={caseFields.expiration_date}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                expiration_date: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}  
              />     
            </Grid>
            </>
          )}  

      
         

            </Grid>
            )}

{listValue === 'listB/C' && (
              <Grid spacing={2} sx={{marginTop:2}} container>
               <Grid  item xs={12} sm={12}>
               <Typography ><b>List Type: </b> {listValue === 'listA'? 'List A': 'ListB/C'}</Typography>
                 
               </Grid>
            <Grid  item xs={12} sm={12}>
            <Typography ><b>List B Document: </b> {listB.docType.replace(/_/g, ' ')}</Typography>
              
            </Grid>

            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Document Number</b></Typography>
            <TextField
            fullWidth
            value={caseFields.document_bc_number}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                document_bc_number: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}  
             />     
            </Grid>

           

            <Grid  item xs={12} sm={12}>
            <Typography ><b>List C Document: </b> { listC.docType.replace(/_/g, ' ')}</Typography>
              
            </Grid>

            <Grid sx={{marginTop:2, }}  item xs={12} sm={4}>
            <Typography ><b>Document Number</b></Typography>
            <TextField
            fullWidth

            value={caseFields.foreign_passport_number}
            onChange={(e) => {
              // Update caseFields with the new last_name value
              setCaseFields(prevData => ({
                ...prevData,
                foreign_passport_number: e.target.value // Correctly target last_name for the update
              }));
              console.log(caseFields)
              // Note: Directly logging caseFields right after setCaseFields won't reflect the update immediately due to the asynchronous nature of setState
            }}
             />     
            </Grid>

            

            

            </Grid>
            )}

<Button variant='contained' onClick={()=> checkDuplicates(caseFields)}>Check</Button>

        </Box>
        )}
        <EverifyConfirm onConfirm={()=>{createCase()}} onClose={()=>setShowSubmitAndPay(false)}  open={showSubmitAndPay}/>
      </Box>
    </div>
  );
};

export default Everify;
