import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from '../assets/images/i9HelpDesk.png';
import { API } from '../controllers/API';



export const NavBar = (props)=>{
  const [user, setUser] = useState(null);
  let navigate = useNavigate();
  const handleLogout = () => {
    // Remove the token and user data cookies
    Cookies.remove('token');
    Cookies.remove('user');

    navigate('/login');
  };

  useEffect(() => {
    // Fetch user data from the server when the component mounts or when the user comes back to the page
    const fetchUserData = async () => {
      try {
        // Check if the token cookie exists before making the request
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        const response = await axios.post(`${API}/auth/refresh-user`, { token });
        const refreshedUser = response.data.user;

        // Update the user data in the state
        setUser(refreshedUser);
        console.log(user)
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };

    fetchUserData();
  }, [navigate]);


  const [anchorEl, setAnchorEl] = useState(null);

  // Function to open the menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

   return (
    <AppBar style={props.sx} position="static">
    <Toolbar sx={{ background: '#102372', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ marginRight: '16px', width: '200px' }} />
      </div>

      {user && (
        <div>
          {/* Profile Button with Dropdown Menu */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="account of current user"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen} // Open the menu on click
            sx={props.ProfileSx}
          >
            <AccountCircleIcon />
          </IconButton>

          {/* Dropdown Menu */}
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose} // Close the menu on selection or outside click
          >
            {/* Account Settings Item */}
            <MenuItem onClick={() => {
              navigate('/account');
              // Add functionality for Account Settings
              handleMenuClose(); // Close the menu after selection
            }}>
              Account Settings
            </MenuItem>

            
            {props.ExtraMenuItems}

            {/* Logout Item */}
            <MenuItem onClick={() => {
              // Add functionality for Logout
              handleLogout(); // Call your logout function
            }}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      )}
    </Toolbar>
  </AppBar>
   )
}