import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Grid container  sx={{textAlign:'center'}}>
      <Grid item sm={12} xs={12}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        
      <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
       
        <Page width={window.innerWidth > 768 ? 600 : window.innerWidth - 20} pageNumber={pageNumber} />
      </div>
      </Document>
      </Grid>
      <Grid item sm={12} xs={12}>
      <Typography>Page {pageNumber} of {numPages}</Typography>
      <Button sx={{marginRight:2}} variant='contained' disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
        Previous
      </Button>
      <Button variant='contained' disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>
        Next
      </Button>
      </Grid>
    </Grid>
  );
};

export default PDFViewer;
