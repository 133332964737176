import React, { useState } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Container,
  Grid,
  Typography,
} from '@mui/material';

const ConfirmPhotoForm = ({  setSelectedValue, selectedValue, documentImage }) => {
  
    const [updateValueState, setUpdateValueState] = useState(''); // Updated state name to avoid conflict
  
    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
      console.log(selectedValue)
    };
  
  
  
  
    return (
      <Container>
       <Grid container >
       <Grid item xs={12} sm={6}>
       
        <img src={'data:image/png;base64,'+documentImage} width="200px" height="auto"/>
       </Grid>
        <Grid item xs={12} sm={6}>
        <RadioGroup
        name="confirmDetails"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="matching"
          control={<Radio />}
          label="Yes, this photo matches"
        />
        <FormControlLabel
          value="not-matching"
          control={<Radio />}
          label="No, this photo does not match"
        />

<FormControlLabel
          value="no-image"
          control={<Radio />}
          label="No photo displayed"
        />
      </RadioGroup>
        </Grid>
        </Grid> 
       
  
      
      </Container>
    );
  };

export default ConfirmPhotoForm;
