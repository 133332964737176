import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Container, Button, TextField, Typography, Snackbar } from '@mui/material';
import { Form, Spinner } from 'react-bootstrap';
import { API } from '../../controllers/API';

const classes = {
    root: {
      marginTop: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    input: {
      margin: 2,
    },
    button: {
      margin: 2,
    },
  };
  

const ScanAndUploadForm = (caseNumber) => {
  const [spinner, setSpinner] = useState(false);
  const [frontPhoto, setFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleFrontPhotoChange = (event) => {
    setFrontPhoto(event.target.files[0]);
  };

  const handleBackPhotoChange = (event) => {
    setBackPhoto(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(backPhoto === null && frontPhoto === null) {
      window.alert("Please upload both photo's before submitting.")
    } else {
   
    setSpinner(true)
    console.log(caseNumber)
    const formData = new FormData();
    formData.append('front_photo', frontPhoto);
    formData.append('back_photo', backPhoto);
    formData.append('case_number', caseNumber.caseNumber)
console.log(formData)
    try {
        
      const response = await axios.post(API+ `/everify/authentication/scan_and_upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
     
      setSpinner(false)

      window.history.back();
    } catch (error) {
      if (error) {
        setSpinner(false)
        window.alert('Something went wrong. Please try again.')
        // setSnackbarSeverity('error');
        // setSnackbarMessage('Error: ' + error);
      } else {
        setSpinner(false)
        window.alert('Something went wrong. Please try again.')

        // setSnackbarSeverity('error');
        // setSnackbarMessage('Error: ' + error);
      }
    } finally {
      setOpenSnackbar(true);
    }
  }
  };

  return (
    <Alert severity='warning'>
        <Typography><b>Scan and Upload Documents</b></Typography>
        <Typography>Because there was no photo found for the employee, submit a valid photo of the employee's document by uploading images from your computer.<b> The file can be a .jpg, .pdf, or .png file no greater than 5MB.</b></Typography>
    <Container maxWidth="md">
      <form className={classes.root} onSubmit={handleSubmit}>
        <Typography><b>Front of Document</b></Typography>
        <Form.Control
    
          accept=".pdf,.png,.jpg,.jpeg"
          className="mb-1 mt-2"
          id="front-photo"
          type="file"
          onChange={handleFrontPhotoChange}
        />
        <label htmlFor="front-photo">
       
        </label>
        <Typography><b>Back of Document</b></Typography>
        <Typography><b>Note:</b> If Document is a Passport, please upload the Passport Barcode page.</Typography>

        <Form.Control
          accept=".png,.jpg,.jpeg"
          className="mb-3"
          id="back-photo"
          type="file"
          onChange={handleBackPhotoChange}
        />
        <label htmlFor="back-photo">
    
        </label>
        <Button endIcon={<Spinner size='sm' style={{display:spinner ? 'flex':'none'}}/>} className="mb-3" type="submit" variant="contained" color="primary" >
          Submit Photos
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
    </Alert>
  );
};

export default ScanAndUploadForm;
