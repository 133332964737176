// src/components/Home.js
import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Grid, Card, CardContent, ListItemText, Divider, ListItem } from '@mui/material';
import '../assets/css/home.css';
import { NavBar } from '../components/NavBar';
import { API } from '../controllers/API';
import LoaderModal from '../components/LoaderModal';
import { formatDate } from '../utils/Global-Functions';
import uuid from 'react-uuid';
import { SupplementBModal } from '../components/SupplementBModal';



const Home = () => {
  let navigate = useNavigate();

 

  function getDayName()
{
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
    var date = new Date();
    return date.toLocaleDateString(undefined, options);        
}
const [log, setLog] = useState([]);
const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const data = [
    { title: "Pending I9s", number: user === null ?'': user.data.i9.length, description: 'See the status of the I9s you have started.', onClick: ()=>{navigate('/i9/pending')} },
    { title: 'I9 Library', number: user === null ?'': user.data.i9Library.length, description: 'Complete I9s are stored here.', onClick: ()=>{navigate('/i9/library')}, },
    { title: 'Inactive I9s', number: user === null ?'': user.data.inactive.length, description: 'Terminated Employees I9s will go here along with the date that the I9 can be destroyed.', onClick: ()=>{navigate('/i9/inactives')}, },
    { title: 'Resource Library', number: '', description: 'A library of FORM I9 resources.', onClick: ()=>{navigate('/resource-library')}, },
  ];

  const processPayment = async ()=>{
   const resp = await axios.post(API + '/payment/process-payment', {amount:945 , customerId: user.stripeCustomerId }) 

   console.log(resp)
  }


  const handleQuickI9 = () => {


    navigate('/forms/i9');
  };

  const handleQuickEverify = () => {


    navigate('/i9/upload');
  };

  const [anchorQmEl, setAnchorQmEl] = useState(null);


  const handleQuickMenuOpen = (event) => {

    const libLength = user.data.i9Library.length;
    const pendingLength = user.data.i9.length;
    const inactiveLength = user.data.inactive.length;
   const membership = user.membership;


    const total = libLength + pendingLength + inactiveLength;
    console.log(total)
   if(membership === 'basic' && total >= 50){
    window.alert('You have filled all of your I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')

   }
   else if(membership === 'Pro' && total >= 200){
    window.alert('You have filled all of your I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
   } else {
    setAnchorQmEl(event.currentTarget);
   }
 
  };

  // Function to close the menu
  const handleQuickMenuClose = () => {
    setAnchorQmEl(null);
  };


  useEffect(() => {
    // Fetch user data from the server when the component mounts or when the user comes back to the page
    const fetchUserData = async () => {
      try {
        // Check if the token cookie exists before making the request
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        const response = await axios.post(API+ '/auth/refresh-user', { token });
        const refreshedUser = response.data.user;

        // Update the user data in the state
        setUser(refreshedUser);
        setLog(refreshedUser.log.reverse())
        setLoading(false)
        console.log(refreshedUser)
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };

    fetchUserData();
  }, [navigate]);
if(loading) { return <LoaderModal open={loading}/>} else {
  

  return (
    <div>
     <NavBar
     ExtraMenuItems={
     <MenuItem sx={{display: user.role === 'admin' ? 'block':'none'}} onClick={() => {
      navigate('/admin');
      // Add functionality for Account Settings
      // Close the menu after selection
    }}>
      Admin Dashboard
    </MenuItem>}
     />

      <div className='title-bar' style={{padding:10 }}>
        {user ? (
          <div>
            <div style={{display:'inline-block', marginTop:15}}>
          <Typography sx={{fontSize:18}} variant='button'><b>Welcome, {user.firstName}!</b></Typography><br/>
          <Typography sx={{fontSize:14}} variant='button'>{getDayName()}</Typography>
         </div>
         <Button onClick={handleQuickMenuOpen} variant='contained' sx={{float:'right', color:'white', borderColor:'white', marginTop:3, backgroundColor:'#FCBD2A'}}><b>Quick Menu&#8681;</b></Button>
          <Menu
                id="profile-menu"
                anchorEl={anchorQmEl}
                open={Boolean(anchorQmEl)}
                onClose={handleQuickMenuClose} // Close the menu on selection or outside click
              >
                {/* Account Settings Item */}
                <MenuItem onClick={() => {
                  // Add functionality for Account Settings
                  handleQuickI9(); // Close the menu after selection
                }}>
                  <ListItemText>
                  <Typography variant='button'>Start New I9</Typography>
                  </ListItemText>
                </MenuItem>
                <Divider/>
                {/* Logout Item */}
                <MenuItem onClick={() => {
                  // Add functionality for Logout
                  handleQuickEverify(); // Call your logout function
                }}>
                  <ListItemText>
                  <Typography variant='button'>Upload Employee's I9</Typography>
                  </ListItemText>
                </MenuItem>
              </Menu>
              
         </div> 
        ) : (
          <p>Loading user data...</p>
        )}
        <br/>
     
        
      </div>
      <div style={{padding:20, backgroundColor:'#F4F4F4', height:'100vh'}}>
        <Grid container spacing={2}>
      <Grid  item xs={12} sm={8}>
      {data.map((item, index) => (
        <Grid sx={{marginTop:1}} key={index} item xs={12} sm={12} md={12} lg={12}>
          <Card className='custom-card'>
            <CardContent >
              <Typography variant="h6" component="div">
                {item.title}
              </Typography>
              <Typography variant="h4" component="div" color="primary">
                {item.number}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.description}
              </Typography>
              <Button onClick={item.onClick} variant='contained' sx={{float:'right', marginBottom:2, padding:0.5, backgroundColor:'#FCBD2A'}}>View</Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    <Grid sx={{marginTop:1}} item xs={12} sm={4}>
    <Card className='custom-card'>
            <CardContent >
            <Typography variant="h6" component="div">
                Recent Activity
              </Typography>
              <div style={{maxHeight:500, overflow:'scroll'}}>
                {log.map((i, index)=>{
                  if(index < 9){
                    
                  return (
                    <>
                    <ListItem alignItems="flex-start">
                    
                    <ListItemText
                      primary={formatDate(i.date)}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                        
                          </Typography>
                          {i.action}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                  </>
                  )

                }
                })}
                
              </div>
            </CardContent>
   </Card>
    </Grid>
    </Grid>
    </div>
    
    </div>
  );
}

};

export default Home;
