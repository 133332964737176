import React, { useState, useRef, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  FormControl,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import '../../../assets/css/home.css';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import logo from '../../../assets/images/i9HelpDesk.png';
import SignatureCanvas from 'react-signature-canvas';
import DocumentUploadForm from '../../../components/DocumentUploadForm'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Container, Form } from 'react-bootstrap';
import { countries, formatDate, statesArray } from '../../../utils/Global-Functions';
import axios from 'axios';
import {API} from '../../../controllers/API';
import LoaderModal from '../../../components/LoaderModal';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const steps = ['Personal Information', 'Address', 'Citizenship Status', 'Documents', 'Signature'];

const SectionOne = () => {
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
let date = new Date();
  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    middleInitial: '',
    otherLastNames: '',
    address: '',
    aptNumber: '',
    city: '',
    state: '',
    zipCode: '',
    dateOfBirth: '',
    socialSecurityNumber: '',
    emailAddress: '',
    telephoneNumber: '',
    citizenshipStatus: '',
    signature:'',
    signatureDate:date,
    expirationDate:'',
    additionalInfoType: '',
    additionalInfo: '',
    uscisANumber:'',
    usedPreparer: '---', // New state for the preparer and/or translator selection
    supplementA:[{     // New state for the preparer and/or translator information
      lastName: '',
      firstName: '',
      middleInitial: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      signature: '',
      date: date,
    }],
    listType:'listA',
    listADocumentCode:'',
    listBDocumentCode:'',
    listCDocumentCode:'',
    listADocumentFront:'',
    listADocumentBack:'',
    listBDocumentFront:'',
    listBDocumentBack:'',
    listCDocumentFront:'',
    listCDocumentBack:'',
    submitted: true
  });

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  // Check if 'a' and 'b' parameters are present
  const hasRequiredParams = searchParams.get('a') && searchParams.get('b');
  const [name, setName] = useState('')
  // ... (your other state and ref definitions)

  useEffect(() => {
    // Check if required parameters are present
    if (!hasRequiredParams) {
      // Redirect to a different page if parameters are missing
      // navigate('/errorPage'); 
      // Replace with the actual path
    } else {
      // Parameters are present, make a call to the server to check if the user has already submitted the form
      // You can use the 'fetch' API or your preferred method for making server requests
      // For example:
      const checkData = async ()=>{
        console.log(API)
        const resp = await axios.post(API+'/i9/check-form',{id: searchParams.get('a'), i9Id: searchParams.get('b'), location:'pending'})
         console.log(resp.data)
        if(resp.data.alreadySubmitted){
          navigate('/i9/section-one-sent');
        }
        if(resp.data.notFound){
          console.log('error')
          // navigate('/errorPage'); 


      }

      if(resp.data.foundNotSubmitted){
        setLoading(false)
        // navigate('/errorPage'); 
        if(name === ''){
          setName(resp.data.sectionOne.firstName)

        }
    }
      }
checkData()

    }
  }, [hasRequiredParams, searchParams, navigate]);

   // Adding the beforeunload event listener
 useEffect(() => {
  const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to leave? Changes you made may not be saved.';
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);

  useEffect(()=>{

    if(name !== ''){
      window.alert(`Welcome ${name}, please read the instructions before you begin!`)
    }
 
  },[loading])
  const [activeStep, setActiveStep] = useState(0);
  const sigCanvas = useRef({});
  const preparerSigCanvas = useRef({});

  const clearPreparerSignature = () => {
    preparerSigCanvas.current.clear();
    // Clear the signature in form data
    setFormData((prevData) => ({
      ...prevData,
      supplementA: [
        ...prevData.supplementA,
        { signature: '' },
      ],
    }));
  };

  const savePreparerSignature = () => {

    const signatureImage = preparerSigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    // Save or send the signatureImage as needed
    console.log('Preparer Signature Image:', signatureImage);
  
    setFormData((prevData) => ({
      ...prevData,
      supplementA: [
        {
          ...prevData.supplementA[0],
          signature: signatureImage,
        },
        ...prevData.supplementA.slice(1), // Keep the rest of the array unchanged
      ],
    }));
  };




  const [open, setOpen] = useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const clearSignature = () => {
    sigCanvas.current.clear();
    setFormData((prevData) => ({
      ...prevData,
      signature: '',
    }));
  };

  const saveSignature = () => {
    const signatureImage = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    // Save or send the signatureImage as needed
    setFormData((prevData) => ({
      ...prevData,
      signature: signatureImage,
    }));
 
    console.log(formData);
  };
  

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };


  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    console.log(formData)
  };

  const handleInputChangePrep = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      supplementA: [
        {
          ...prevData.supplementA[0],
          [fieldName]: value,
        },
        ...prevData.supplementA.slice(1), // Keep the rest of the array unchanged
      ],
    }));
    console.log(formData)
  };

  async function uploadFile(a, folderText, type, datsArray) {
   
    const file = a;
    const folder = folderText;
  
    if (!file || !folder) {
      alert('Please select a file and enter a folder name.');
      return;
    }
  
    const fileFormData = new FormData();
    fileFormData.append('file', file);
    fileFormData.append('folder', folder);
  
    try {
      const response = await axios.post(API+'/file/upload', fileFormData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });
  
      // Ensure the response is successful (status code 2xx)
      if (response.data.ok) {
        // Extract the shared link directly as a string
        const sharedLink = await response.data.sharedLink;
  console.log(sharedLink)
        let modifiedLink = sharedLink.replace("www.dropbox.com", "dl.dropboxusercontent.com");
  
        if(type === 'listADocumentFront'){
          setFormData((prevData) => {
            const updatedData = {
              ...prevData,
              [type]: modifiedLink,
            };
        
            // Use updatedData for any immediate operations
        
            // For example, you can log it here
            console.log('type A')
            console.log(updatedData);
        
            // Merge the new data with the previous data
            const mergedData = {
              ...updatedData,
              listADocumentBack: prevData.listADocumentBack, // Add previous listADocumentBack
            };
        
            uploadFile(formData.listADocumentBack, `${searchParams.get('a')}/${searchParams.get('b')}/listABack`, 'listADocumentBack')
        
            return mergedData; // Return the merged data
          });
        }

        if(type === 'listADocumentBack'){
          setFormData((prevData) => {
            const updatedData = {
              ...prevData,
              [type]: modifiedLink,
            };
          
            // Use updatedData for any immediate operations
          
            // For example, you can log it here
            console.log(updatedData);
          
             saveFormData(updatedData)// Update the state
            
          });
        }

       

        if(type === 'listBDocumentFront'){

          let dataArr = [modifiedLink];

          uploadFile(formData.listBDocumentBack, `${searchParams.get('a')}/${searchParams.get('b')}/listBBack`, 'listBDocumentBack', dataArr)// Update the state
          
      
        }

        if(type === 'listBDocumentBack'){

          datsArray.push(modifiedLink);
          uploadFile(formData.listCDocumentFront, `${searchParams.get('a')}/${searchParams.get('b')}/listCFront`, 'listCDocumentFront',datsArray)// Update the state

          
      }

        if(type === 'listCDocumentFront'){

          datsArray.push(modifiedLink);

          uploadFile(formData.listCDocumentBack, `${searchParams.get('a')}/${searchParams.get('b')}/listCBack`, 'listCDocumentBack', datsArray)// Update the state
     
        }

        if(type === 'listCDocumentBack'){
          console.log(datsArray)
          setFormData((prevData) => {
            const updatedData = {
              ...prevData,
              listBDocumentFront: datsArray[0],
              listBDocumentBack: datsArray[1],
              listCDocumentFront: datsArray[2],
              [type]: modifiedLink,
            };
          
            // Use updatedData for any immediate operations
          
            // For example, you can log it here
            console.log(updatedData);
          
            saveFormData(updatedData)// Update the state
            
          });
     
        }
       
        

      
        
  
      } else {
        // Handle non-successful response (status code other than 2xx)
        console.error('Error uploading file:', response.statusText);
        alert('Error uploading file. Please try again.');
        return false
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
      return false
    }
  }

  const saveFormData = async (formData ) => {
    try {
      
      // Make a POST request to the server route '/save-form'
      const response = await axios.post(API +'/i9/save-form', {
        id: searchParams.get('a'), 
        i9Id: searchParams.get('b'),
        formData: formData,
      });
  
      // Check the response and handle accordingly
      if (response.data.success) {
        // Form data saved successfully
        console.log('Form data saved successfully!');
        navigate('/i9/section-one-sent');
        // You can navigate to another page or perform additional actions here
      } else if (response.data.notFound) {
        // Form not found
        setSending(false)
        console.log('Form not found');
      } else if (response.data.sectionOneNotFound) {
        // sectionOne not found
        setSending(false)
        console.log('sectionOne not found');
      } else {
        // Handle other errors
        setSending(false)
        console.error('An error occurred:', response.data.error);
      }
    } catch (error) {
      // Handle network or other errors
      setSending(false)
      console.error('An error occurred:', error.message);
    }
  };

  const handleSubmit = async (e) => {
 
    
    // Validate combined Signature and Preparer/Translator step
    const isCombinedStepValid = validateSignatureAndPreparerTranslator();
  
    // Perform any final validation before submitting the form
    if (isCombinedStepValid) {
      // TODO: Implement form submission logic (e.g., send data to server)
      console.log('Form Data:', formData);
      if(formData.listType === 'listA'){
        setSending(true)
        uploadFile(formData.listADocumentFront, `${searchParams.get('a')}/${searchParams.get('b')}/listAFront`, 'listADocumentFront')

      } 

      if(formData.listType === 'listBC'){
        setSending(true)

        uploadFile(formData.listBDocumentFront, `${searchParams.get('a')}/${searchParams.get('b')}/listBFront`, 'listBDocumentFront', formData)

      }
      
     
    } else {

        setOpen(true)
 
      // Display an error message or take appropriate action if final validation fails
      console.log('Combined step validation failed');
    }
  };

  const handleDocumentUpload = (selectedOption, file) => {
    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData };
  
      if (selectedOption === 'listAFront') {
        updatedFormData.listADocumentFront = file;
      }
       else if (selectedOption === 'listABack') {
        updatedFormData.listADocumentBack = file;
      }
      
      else if (selectedOption === 'listBFront') {
        if(updatedFormData.listADocumentFront !== '' || updatedFormData.listADocumentBack !== ''){
          updatedFormData.listADocumentFront = ''
          updatedFormData.listADocumentBack = ''
        }
        updatedFormData.listBDocumentFront = file;
      }
      else if (selectedOption === 'listBBack') {
        if(updatedFormData.listADocumentFront !== '' || updatedFormData.listADocumentBack !== ''){
          updatedFormData.listADocumentFront = ''
          updatedFormData.listADocumentBack = ''
        }
        updatedFormData.listBDocumentBack = file;
      }
      
      else if (selectedOption === 'listCFront') {
        if(updatedFormData.listADocumentFront !== '' || updatedFormData.listADocumentBack !== ''){
          updatedFormData.listADocumentFront = ''
          updatedFormData.listADocumentBack = ''
        }
        updatedFormData.listCDocumentFront = file;
      }

      else if (selectedOption === 'listCBack') {
        if(updatedFormData.listADocumentFront !== '' || updatedFormData.listADocumentBack !== ''){
          updatedFormData.listADocumentFront = ''
          updatedFormData.listADocumentBack = ''
        }
        updatedFormData.listCDocumentBack = file;
      }
  
      return updatedFormData;
    });
    console.log(formData)
  };


  const validatePersonalInformation = () => {
    return formData.lastName !== '' && formData.dateOfBirth !== '' && formData.firstName !== '' && formData.socialSecurityNumber !== '' && formData.socialSecurityNumber.length === 9;
  };

  // Validation function for Address step
  const validateAddress = () => {
    return formData.address !== '' && formData.city !== '' && formData.state !== '' && formData.zipCode !== '';
  };

  // Validation function for Citizenship Status step
  const validateCitizenshipStatus = () => {
     if (formData.citizenshipStatus === 'LAWFUL_PERMANENT_RESIDENT') {
      console.log('Validating lawfulPermanentResident');
      return formData.uscisANumber !== '' || formData.uscisANumber.length > 9;
    }

    if (formData.citizenshipStatus === 'ALIEN_AUTHORIZED_TO_WORK') {
      console.log('Validating lawfulPermanentResident');
      return (
        formData.additionalInfoType !== '' &&
        formData.additionalInfo.length >= 8 
      )
    }
    // if (formData.citizenshipStatus === 'NONCITIZEN') {
    //   const isForeignPassport = formData.additionalInfoType === 'foreignPassport';
    //   return (
    //     formData.additionalInfoType !== '' &&
    //     formData.additionalInfo.length >= 9 &&
    //     formData.countryOfIssuance !== '' &&
    //     (isForeignPassport ? formData.countryOfIssuance !== '' : true) &&
    //     formData.expirationDate !== ''
    //   );
    // } 
    if (formData.citizenshipStatus === 'NONCITIZEN_AUTHORIZED_TO_WORK') {
      return (
        formData.additionalInfoType !== '' &&
        formData.additionalInfo.length >= 8 
      )
       
    } 
    // For other citizenship statuses, validation is not required
    return true;
  };

  // Validation function for Documents step
  const validateDocuments = () => {
    if(formData.listType === 'listA'){
      return formData.listADocumentFront !== '' &&  formData.listADocumentBack !== '' && formData.listADocumentCode !== '';
    } 
    if(formData.listType === 'listBC'){
      return formData.listBDocumentFront !== '' && formData.listBDocumentBack !== '' && formData.listCDocumentFront !== '' && formData.listCDocumentBack !== '' && formData.listBDocumentCode !== '' && formData.listCDocumentCode !== '';
    } 

    return true;
  };

  // Existing validation function for Signature step
const validateSignature = () => {
  // Return true if the signature is not empty (truthy), indicating it is valid
  return !!formData.signature;
};
// Existing validation function for Preparer and/or Translator step
const validatePreparerAndTranslator = () => {
  if (formData?.usedPreparer === 'yes') {
    const preparerInfo = formData.supplementA[0]; // Corrected line
    return (
      preparerInfo.lastName !== '' &&
      preparerInfo.firstName !== '' &&
      preparerInfo.address !== '' &&
      preparerInfo.city !== '' &&
      preparerInfo.state !== '' &&
      preparerInfo.zipCode !== '' &&
      preparerInfo.signature !== '' &&
      preparerInfo.date !== ''
    );
  }
  return true;
};

// Combined validation function for Signature and Preparer/Translator step
const validateSignatureAndPreparerTranslator = () => {
  // Perform validation for both steps
  const isSignatureValid = validateSignature();
  const isPreparerAndTranslatorValid = validatePreparerAndTranslator();

  // Return true if both validations pass
  return isSignatureValid && isPreparerAndTranslatorValid;
};

  const validationFunctions = [
    validatePersonalInformation,
    validateAddress,
    validateCitizenshipStatus,
    validateDocuments,
    validateSignature,
    validatePreparerAndTranslator,
  ];

  // ... (your existing functions)

  const handleNext = () => {
    window.scrollTo(0,0)
    if (validationFunctions[activeStep]()) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      setOpen(true);
      // Display an error message or take appropriate action if validation fails
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Personal Information</b></Typography>
            </Grid>
             <Grid item xs={12} sm={4}>
              <Typography ><b>Last Name *</b></Typography>
              <TextField
              
                required
                sx={{backgroundColor:'white'}}
                fullWidth
                value={formData.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>First Name *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
              required
         
                fullWidth
                value={formData.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>Middle Initial</b></Typography>
              <TextField
          sx={{backgroundColor:'white'}}
                
                fullWidth
                value={formData.middleInitial}
                onChange={(e) => handleInputChange('middleInitial', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
            <Typography ><b>Other Last Names Used</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                fullWidth
                value={formData.otherLastNames}
                onChange={(e) => handleInputChange('otherLastNames', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
          
            <Typography ><b>Date of Birth *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                required
                type='date'
                
                fullWidth
              
                value={formData.dateOfBirth}
                onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
              />
            </Grid>

            {/* Contact Information */}
            <Grid item xs={12} sm={4}>
            <Typography ><b>Social Security Number *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
              fullWidth
              inputProps={{ maxLength: 9, inputMode: 'numeric' }}
              value={formData.socialSecurityNumber}
              onChange={(e) => handleInputChange('socialSecurityNumber', e.target.value)}
            />
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>Email Address</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                fullWidth
                value={formData.emailAddress}
                onChange={(e) => handleInputChange('emailAddress', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>Telephone Number</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                fullWidth
                value={formData.telephoneNumber}
                onChange={(e) => handleInputChange('telephoneNumber', e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
           <Grid item xs={12}>
              <Typography variant='button' sx={{fontSize:18}}><b>Address</b></Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography ><b>Street Address *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
               
                fullWidth
                value={formData.address}
                onChange={(e) => handleInputChange('address', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
            <Typography ><b>Apt. Number</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                fullWidth
                value={formData.aptNumber}
                onChange={(e) => handleInputChange('aptNumber', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
            <Typography ><b>City *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                
                fullWidth
                value={formData.city}
                onChange={(e) => handleInputChange('city', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>State *</b></Typography>
            <Select
                 fullWidth
                 value={formData.state}  // Use `value` instead of `defaultValue`
                 sx={{ backgroundColor: 'white' }}
                 onChange={(e) => handleInputChange('state', e.target.value)}
               >
                 {statesArray.map((state, index) => (
                   <MenuItem key={index} value={index}>{state}</MenuItem>
                 ))}
               </Select>
            
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography ><b>ZIP Code *</b></Typography>
              <TextField
             sx={{backgroundColor:'white'}}
               
                fullWidth
                value={formData.zipCode}
                onChange={(e) => handleInputChange('zipCode', e.target.value)}
              />
            </Grid>
          </Grid>
        );
      
      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Grid sx={{marginBottom:2}} item xs={12}>
            <Typography variant='button' sx={{fontSize:18}}><b>Citizenship Status</b></Typography>
   <br/>
   <br/>
  <Typography variant="p">
   <b>Check one of the following boxes to attest to your citizenship or immigration status (See page 2 and 3 of the instructions.):</b> 
  </Typography>
</Grid>
<RadioGroup
  value={formData.citizenshipStatus}
  onChange={(e) => handleInputChange('citizenshipStatus', e.target.value)}
  
>
  <FormControlLabel
    value="US_CITIZEN"
    control={<Radio />}
    label="A citizen of the United States"
    labelPlacement="end"
  />

  <FormControlLabel
    value="NONCITIZEN"
    control={<Radio />}
    label={<span>Noncitizen National of the United States (<a target='_blank' href='https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf'>See Instructions.</a>)</span>}
    labelPlacement="end"
  />

  <FormControlLabel
    value="LAWFUL_PERMANENT_RESIDENT"
    control={<Radio />}
    label="Lawful Permanent Resident (Enter USCIS or A-Number.)"
    labelPlacement="end"
  />

  <FormControlLabel
    value="ALIEN_AUTHORIZED_TO_WORK"
    control={<Radio />}
    label="Alien Authorized to Work (A-Number)"
    labelPlacement="end"
  />

<FormControlLabel
    value="NONCITIZEN_AUTHORIZED_TO_WORK"
    control={<Radio />}
    label="Noncitizen Authorized to Work (A-Number)"
    labelPlacement="end"
  />
</RadioGroup>
{formData.citizenshipStatus === 'NONCITIZEN_AUTHORIZED_TO_WORK' && (
  <>
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
      <Typography sx={{marginTop:2}}><b>Select Type</b></Typography>
        <Select
        sx={{backgroundColor:'white'}}
          labelId="additional-info-type-label"
          id="additional-info-type"
          value={formData.additionalInfoType}
          onChange={(e) => handleInputChange('additionalInfoType', e.target.value)}
          
        >
          <MenuItem value="---">--Select Type---</MenuItem>
          <MenuItem value="uscisANumber">USCIS A-Number</MenuItem>
          <MenuItem value="i94AdmissionNumber">Form I-94 Admission Number</MenuItem>
          <MenuItem value="foreignPassport">Foreign Passport</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
    <Typography sx={{marginTop:2}}><b>Enter the Number *</b></Typography>
      <TextField
      sx={{backgroundColor:'white'}}
        variant="outlined"
        fullWidth
        value={formData.additionalInfo}
        onChange={(e) => handleInputChange('additionalInfo', e.target.value)}
      />
    </Grid>
    {formData.additionalInfoType === 'foreignPassport' && (
      <Grid item xs={12}>
        <Typography sx={{marginTop:2}}><b>Country of Issuance *</b></Typography>
         <Select
          sx={{backgroundColor:'white'}}
         fullWidth
        onChange={(e) => handleInputChange('countryOfIssuance', e.target.value)}

        >
          <MenuItem value="">---</MenuItem>
          {countries.map((i,index)=>{
            return <MenuItem value={i.code}>{i.name} - {i.code}</MenuItem>
          })}
        </Select>
      </Grid>
    )}
  
  </>
)}

{formData.citizenshipStatus === 'ALIEN_AUTHORIZED_TO_WORK'   && (
  <>
  <Grid item xs={12}>
    <FormControl fullWidth variant="outlined">
    <Typography sx={{marginTop:2}}><b>Select Type</b></Typography>
      <Select
      sx={{backgroundColor:'white'}}
        labelId="additional-info-type-label"
        id="additional-info-type"
        value={formData.additionalInfoType}
        onChange={(e) => handleInputChange('additionalInfoType', e.target.value)}
        
      >
        <MenuItem value="---">--Select Type---</MenuItem>
        <MenuItem value="uscisANumber">USCIS A-Number</MenuItem>
        <MenuItem value="i94AdmissionNumber">Form I-94 Admission Number</MenuItem>
        <MenuItem value="foreignPassport">Foreign Passport</MenuItem>
      </Select>
    </FormControl>
  </Grid>
  <Grid item xs={12}>
  <Typography sx={{marginTop:2}}><b>Enter the Number *</b></Typography>
    <TextField
    sx={{backgroundColor:'white'}}
      variant="outlined"
      fullWidth
      value={formData.additionalInfo}
      onChange={(e) => handleInputChange('additionalInfo', e.target.value)}
    />
  </Grid>
  {formData.additionalInfoType === 'foreignPassport' && (
    <Grid item xs={12}>
      <Typography sx={{marginTop:2}}><b>Country of Issuance *</b></Typography>
       <Select
        sx={{backgroundColor:'white'}}
       fullWidth
      onChange={(e) => handleInputChange('countryOfIssuance', e.target.value)}

      >
        <MenuItem value="">---</MenuItem>
        {countries.map((i,index)=>{
          return <MenuItem value={i.code}>{i.name} - {i.code}</MenuItem>
        })}
      </Select>
    </Grid>
  )}

</>
)}



{formData.citizenshipStatus === 'LAWFUL_PERMANENT_RESIDENT'   && (
    <Grid item xs={12}>
   <Typography sx={{marginTop:2}}><b>USCIS or A-Number *</b></Typography>
      <TextField
        label="Enter USCIS or A-Number"
        fullWidth
        sx={{backgroundColor:'white'}}
        value={formData.uscisANumber}
        onChange={(e) => handleInputChange('uscisANumber', e.target.value)}
      />
    </Grid>
  )}
            </Grid>
          </Grid>
        );
    
      case 3:
        return (
          <>
         
            <Typography variant='button' sx={{fontSize:18}}><b>Documents</b></Typography>
            <br/>
            <br/>
            <DocumentUploadForm
              selectedOption={formData.listType}
              setSelectedOption={(e) => {
                if(e === 'listA'){
                  setFormData((prevData) => ({
                    ...prevData,
                    listBDocument: '',
                  }));

                  setFormData((prevData) => ({
                    ...prevData,
                    listCDocument: '',
                  }));
                } else {
                  setFormData((prevData) => ({
                    ...prevData,
                    listADocument: '',
                  }));
                }
                setFormData((prevData) => ({
                  ...prevData,
                  listType: e,
                }));
              }}
              valueA={formData.listADocumentFront}
              valueB={formData.listBDocumentFront} 
              valueC={formData.listCDocumentFront}
              valueABack={formData.listADocumentBack}
              valueBBack={formData.listBDocumentBack} 
              valueCBack={formData.listCDocumentBack}
              valueACode={formData.listADocumentCode}
              valueBCode={formData.listBDocumentCode} 
              valueCCode={formData.listCDocumentCode}
              citizenshipStatus={formData.citizenshipStatus}
              RemoveValueB={()=>{
                setFormData((prevData) => ({
                  ...prevData,
                  listBDocumentFront: '',
                }));
              }}
              RemoveValueBBack={()=>{
                setFormData((prevData) => ({
                  ...prevData,
                  listBDocumentBack: '',
                }));
              }}
              RemoveValueC={()=>{
                setFormData((prevData) => ({
                  ...prevData,
                  listCDocumentFront: '',
                }));
              }}
              RemoveValueCBack={()=>{
                setFormData((prevData) => ({
                  ...prevData,
                  listCDocumentBack: '',
                }));
              }}
              RemoveValueA={()=>{
                setFormData((prevData) => ({
                  ...prevData,
                  listADocumentFront: '',
                }));
              }}
              RemoveValueABack={()=>{
                setFormData((prevData) => ({
                  ...prevData,
                  listADocumentBack: '',
                }));
              }}
              setFormData={setFormData}
              handleDocumentUpload={handleDocumentUpload}
              formData={formData}
            />


          </>
        );
      case 4:
        return (
          <>
           
           <Typography variant='button' sx={{fontSize:18}}><b>Signature of Employee</b></Typography>
            <br />
            <Typography>I am aware that federal law provides for imprisonment and/or fines for false statements, or the use of false documents, in connection with the completion of this form. I attest, under penalty of perjury, that this information, including my selection of the box attesting to my citizenship or immigration status, is true and correct.</Typography>
            <br />
            <div style={{ border: '2px solid black',  display:formData?.signature === "" ? 'block': 'none' }}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  style: {
                    width: '100%',
                    height: 100,
                    backgroundColor:'white'
                  },
                }}
                ref={sigCanvas}
              />
            </div>
            <img style={{width:'100%', height:'auto'}} src={formData?.signature}/>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button sx={{display:formData?.signature === '' ? 'none': 'block', marginTop:2}} onClick={clearSignature} variant="outlined" color="secondary">
                  Clear Signature
                </Button>
              </Grid>
              <Grid item>
                <Button sx={{display:formData?.signature === '' ? 'block': 'none'}} onClick={saveSignature} variant="contained" color="primary">
                  Save Signature
                </Button>
              </Grid>
              
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Today's Date:</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  disabled
                  fullWidth
                  value={formatDate(formData?.signatureDate)}
                />
              </Grid>
            <Grid item xs={12}>
           
              <hr/>
              <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
              <Typography id="preparer-translator-label">
                <b>Did you use a preparer and/or translator?</b>
              </Typography>
              <br />

              <Select
              sx={{backgroundColor:'white'}}
                labelId="preparer-translator-label"
                id="preparer-translator"
                value={formData?.usedPreparer}
                onChange={(e) => handleInputChange('usedPreparer', e.target.value)}
              >
                <MenuItem value="---">---</MenuItem>
                <MenuItem value="no">No</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
              </Select>
            </FormControl>

          {formData?.usedPreparer === 'yes' && (
          <>
            <Grid item xs={12}>
            <br/>
              <Typography variant="h6">Preparer and/or Translator Information</Typography>
            </Grid>
            {/* Instructions */}
            <Grid item xs={12}>
              <Typography variant="body1">
                Instructions: This supplement must be completed by any preparer and/or translator who assists an employee in completing Section 1 of Form I-9.
                The preparer and/or translator must enter the employee's name in the spaces provided above.
                Each preparer or translator must complete, sign, and date a separate certification area.
                Employers must retain completed supplement sheets with the employee's completed Form I-9.
              </Typography>
            </Grid>
            {/* Certification Area */}
            <Grid container spacing={2}>
              {/* Last Name, First Name, Middle Initial */}
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Last Name *</b></Typography>
              <TextField
             sx={{backgroundColor:'white'}}
                  fullWidth
                  value={formData.supplementA[0].lastName}
                  onChange={(e) => handleInputChangePrep('lastName', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>First Name *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  fullWidth
                  value={formData.supplementA[0].firstName}
                  onChange={(e) => handleInputChangePrep('firstName', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Middle Initial</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  fullWidth
                  value={formData.supplementA[0].middleInitial}
                  onChange={(e) => handleInputChangePrep('middleInitial', e.target.value)}
                />
              </Grid>
              {/* Address, City, State, ZIP Code */}
              <Grid item xs={12}>
              <Typography sx={{marginTop:2}}><b>Address *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  fullWidth
                  value={formData.supplementA[0].address}
                  onChange={(e) => handleInputChangePrep('address', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>City *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  fullWidth
                  value={formData.supplementA[0].city}
                  onChange={(e) => handleInputChangePrep('city', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>State *</b></Typography>
              <Select
            fullWidth
            sx={{backgroundColor:'white'}}
            onChange={(e) => handleInputChangePrep('state', e.target.value)}
            >
             {statesArray.map((i,index)=>{
              return (<MenuItem value={index}>{i}</MenuItem>)
             })}
            </Select>
        
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>ZIP Code *</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  fullWidth
                  value={formData.supplementA[0].zipCode}
                  onChange={(e) => handleInputChangePrep('zipCode', e.target.value)}
                />
              </Grid>
              {/* Signature, Date */}
              <Grid item xs={12}>
              <div style={{ border: '2px solid black', marginBottom: 5, display:formData.supplementA[0].signature === '' ? 'block': 'none' }}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    style: {
                      width: '100%',
                      height: 100,
                      backgroundColor:'white'
                    },
                  }}
                  ref={preparerSigCanvas}
                />
              </div>
              <img style={{width:'100%', height:'auto'}} src={formData.supplementA[0].signature}/>
              <div>
               
              </div>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button sx={{display:formData.supplementA[0].signature === '' ? 'none': 'block', marginTop:2}} onClick={clearPreparerSignature} variant="outlined" color="secondary">
                    Clear Preparer/Translator's Signature
                  </Button>
                </Grid>
                <Grid item>
                  <Button sx={{display:formData.supplementA[0].signature === '' ? 'block': 'none'}} onClick={savePreparerSignature} variant="contained" color="primary">
                    Save Preparer/Translator's Signature
                  </Button>
                </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Today's Date:</b></Typography>
              <TextField
              sx={{backgroundColor:'white'}}
                  disabled
                  fullWidth
                  value={formatDate(formData.supplementA[0].date)}
                />
              </Grid>
     
            </Grid>
          </>
        )}
           <hr/>
        </Grid>

          </>
        );
      default:
        return 'Unknown step';
    }
  };
if(loading){
  return (<LoaderModal  open={true}/>)
} else {
  
  return (
    <div>
      <AppBar position="static">
        <Toolbar sx={{ background: '#102372', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ marginRight: '16px', width: '200px' }} />
          </div>
        </Toolbar>
      </AppBar>
      <div className="title-bar" style={{ padding: '16px', alignItems: 'center' }}>
        <Typography variant="h4">FORM I-9</Typography>
      </div>

      <Box sx={{ padding: 3, marginTop: 3 }}>
        <Typography><b>START HERE:</b> Employers must ensure the form instructions are available to employees when completing this form. Employers are liable for failing to comply with the requirements for completing this form. <a href='https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf' target='_blank'><b>See Instructions</b></a>.</Typography> 
        <br/>
        <Typography><b>ANTI-DISCRIMINATION NOTICE:</b> All employees can choose which acceptable documentation to present for Form I-9. Employers cannot ask employees for documentation to verify information in Section 1, or specify which acceptable documentation employees must present for Section 2 or Supplement B, Reverification and Rehire. Treating employees differently based on their citizenship, immigration status, or national origin may be illegal.</Typography>
        <br/>
        <Typography><b>SECTION 1.</b> Employee Information and Attestation: Employees must complete and sign Section 1 of Form I-9 no later than the first day of employment, but not before accepting a job offer.</Typography>
        <br/>

        <Stepper sx={{overflowY:'scroll'}} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form >
         <Box sx={{padding:2, paddingTop:5}}>
          {getStepContent(activeStep)}
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Grid container justifyContent="space-between">
            <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" color="primary">
              Back
            </Button>
            {activeStep !== steps.length - 1 ? (
              <Button onClick={handleNext} variant="contained" color="primary">
                Next
              </Button>
            ) : (
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Submit
              </Button>
            )}
          </Grid>
        </form>
        <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="error">Please make sure you have filled out all required fields before moving on.</Alert>

      </Snackbar>
      </Box>
      <LoaderModal title={<div style={{textAlign:'center'}}><Typography sx={{color:'white', fontSize:'24px', fontWeight:'bold'}} >Sending...</Typography><Typography sx={{color:'white', fontSize:'18px', fontWeight:'bold'}}>Please do not close this page.</Typography></div>} open={sending}/>
    </div>
  );

}
};

export default SectionOne;



