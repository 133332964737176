import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Form } from 'react-bootstrap';
import {Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Button, TextField } from '@mui/material';
import { countries, listADocuments, listBDocuments, listCDocuments, statesArray } from '../utils/Global-Functions';

function PDFFormExtractor({setFormFields, formFields, setListValue, listValue, handleChange, setListA, setListB, setListC, listA, listB,listC, dateOfHire, setDateOfHire, setDocSubType, setUSStateCode, setShowForm, showForm}) {
  
    

  const handleFileChange = async (event) => {
    setShowForm(true)
    const file = event.target.files[0];
    if (file) {
      const arrayBuffer = await file.arrayBuffer();
      extractFormFields(arrayBuffer);
    }
  };

  const extractFormFields = async (arrayBuffer) => {
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    const fieldData = fields.map(field => {
      // Determine field type and extract value accordingly
      const fieldType = field.constructor.name;
      let value = 'N/A'; // Default value if we cannot extract specific data

      if (fieldType === 'PDFTextField' || fieldType === 'PDFTextAreaField') {
        value = field.getText();
      } else if (fieldType === 'PDFCheckBox') {
        value = field.isChecked() ? 'Yes' : 'No';
      } else if (fieldType === 'PDFRadioButton') {
        value = field.getSelected();
      } else if (fieldType === 'PDFDropdown') {
        value = field.getSelected();
      }

      return {
        name: field.getName(),
        type: fieldType,
        value: value,
      };
    });
    console.log(fieldData)
  let filter = fieldData.filter(i=>{
    return i.value !== '';
  })
  console.log(filter)
    setFormFields(filter);
  };

  return (
    <div>
    <Box sx={{margin:2, padding:3}}>

    <Typography variant='button'><b>Step One: Citizenship Status</b></Typography>
    <br/>
    <Typography ><b>What is the employee's Citizenship Status?</b></Typography>

    <Form.Select
    style={{marginTop:5, marginBottom:5}}
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setListA((prevData)=>({
            ...prevData,
            citizenShipStatus: value,
          }))
          setListB((prevData)=>({
            ...prevData,
            citizenShipStatus: value,
          }));
          setListC((prevData)=>({
            ...prevData,
            citizenShipStatus: value,
          }))
      
    }}
        >
          <option value={"---"}>---</option>
          <option value={"US_CITIZEN"}>US CITIZEN</option>
          <option value={"NONCITIZEN"}>NONCITIZEN</option>
          <option value={"LAWFUL_PERMANENT_RESIDENT"}>LAWFUL PERMANENT RESIDENT</option>
          <option value={"NONCITIZEN_AUTHORIZED_TO_WORK"}>NONCITIZEN AUTHORIZED TO WORK</option>

        </Form.Select>
{listA.citizenShipStatus !== '---' && (
  <>
  <Box sx={{marginTop:2}}>
    <Typography variant='button' sx={{marginTop:2}}><b>Step Two: Document Types</b></Typography>

    <Typography ><b>Select the Employee's Document Type:</b></Typography>

    <FormControl component="fieldset">

      <RadioGroup aria-label="list" name="list1" value={listValue} onChange={handleChange}>
        <FormControlLabel value="listA" control={<Radio />} label="List A" />
        <FormControlLabel value="listB/C" control={<Radio />} label="List B/C" />
      </RadioGroup>
    </FormControl>
    {listValue === 'listA' && (
  <>
      <Typography ><b>Specify Document A:</b></Typography>

  <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setListA((prevData)=>({
            ...prevData,
            docType: value,
          })
          

      )
      
    }}
        >
          {listADocuments.filter(doc => doc.citizenShipType.includes(listA.citizenShipStatus)).map((i,index)=>{
            return <option value={i.code}>{i.documentName}</option>
          })}
        </Form.Select>

       { listA.docType === 'FOREIGN_PASSPORT_WITH_FORM_I94'  && ( 
           <> 
       <Typography ><b>Please select the country code:</b></Typography>
     
        <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setListA((prevData)=>({
            ...prevData,
      
            countryCode: value
          })
          

      )
      
    }}
        >
          <option value="">---</option>
          {countries.map((i,index)=>{
            return <option value={i.code}>{i.name}</option>
          })}
        </Form.Select>
        </>
       )}

{ listA.docType === 'FOREIGN_PASSPORT_WITH_I551_STAMP'  && ( 
           <> 
       <Typography ><b>Please select the country code:</b></Typography>
     
        <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setListA((prevData)=>({
            ...prevData,
      
            countryCode: value
          })
          

      )
      
    }}
        >
          <option value="">---</option>
          {countries.map((i,index)=>{
            return <option value={i.code}>{i.name}</option>
          })}
        </Form.Select>
        </>
       )}

        <Box sx={{marginTop:2}}>
    <Typography sx={{marginTop:2}}><b>Date of Hire:</b></Typography>
     <Form.Control
     type='date'
     value={dateOfHire}
     onChange={(e)=>{
      setDateOfHire(e.target.value)
     }}
     />
    </Box>

    
  </> 
  )}
  {listValue === 'listB/C' && (
  <>
        <Typography ><b>Specify Document B:</b></Typography>

  <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setListB((prevData)=>({
            ...prevData,
            docType: value,
          })
          

      )
      
    }}
        >
          {listBDocuments.map((i,index)=>{
            return <option value={i.code}>{i.documentName}</option>
          })}
        </Form.Select>

{listB.docType === 'DRIVERS_LICENSE' && (
  <>
          <Typography sx={{marginTop:2}}><b>Specify Subdocument Type:</b></Typography>

  <Form.Select
      onChange={(e)=>{
        let value = e.target.value;
        console.log(value)
        setDocSubType(value)
    
    
  }}
      >
        <option value="">---</option>
        <option value="DRIVERS_LICENSE">Driver's License</option>
      <option value="STATE_ID_CARD">State Issued ID Card</option>
      </Form.Select>

      <Typography sx={{marginTop:2}}><b>State Card Was Issued:</b></Typography>

      <Form.Select
      onChange={(e)=>{
        let value = e.target.value;
        console.log(value)
        setUSStateCode(value)
     
    
  }}
      >
       {statesArray.map(i=>{
        return <option value={i}>{i}</option>
       })}
      </Form.Select>
      
      </>
)}



  </> 
  )}

{listValue === 'listB/C' && (
  <>
        <Typography sx={{marginTop:2}}><b>Specify Document C:</b></Typography>

  <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setListC((prevData)=>({
            ...prevData,
            docType: value,
          }))
      
    }}
        >
          {listCDocuments.filter(doc => doc.citizenShipType.includes(listA.citizenShipStatus)).map((i,index)=>{
            return <option value={i.code}>{i.documentName}</option>
          })}
        </Form.Select>

        <Box sx={{marginTop:2}}>
    <Typography sx={{marginTop:2}}><b>Date of Hire:</b></Typography>
     <Form.Control
     type='date'
     value={dateOfHire}
     onChange={(e)=>{
      setDateOfHire(e.target.value)
     }}
     />
    </Box>
  </> 
  )}
    

    </Box>
   

    {dateOfHire !== null && (
     <>
        <Box sx={{marginTop:2}}>
    <Typography variant='button' sx={{marginBottom:2}}><b>Step Three: Complete Employee Information:</b></Typography>
    <Typography  sx={{marginBottom:2, marginTop:2}}><b>Upload Employee's I-9:</b></Typography>

     <Form.Control type="file" onChange={handleFileChange} accept="application/pdf" />
     </Box>

     <Box sx={{marginTop:2}}>
     <Button onClick={()=>setShowForm(!showForm)}><b>{showForm ? 'Hide' : 'Click here to enter the information manually'}</b></Button>
     </Box>
     </>
    )}


    </>

)}
    
    </Box>
    </div>
    
  );
}

export default PDFFormExtractor;
