import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { NavBar } from '../components/NavBar';
import { Box, Container, Divider, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { TitleBar } from '../components/TitleBar';
import { formatDate } from '../utils/Global-Functions';
import { SearchBar } from '../components/SearchBar';
import ToolTipButton from '../components/TooltipButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CheckCircle, ContentCopy, Delete, DeleteOutline, Visibility } from '@mui/icons-material';
import { API } from '../controllers/API';
import { Button } from 'react-bootstrap';
import LoaderModal from '../components/LoaderModal';
import { SupplementBModal } from '../components/SupplementBModal';
import uuid from 'react-uuid';




export const InactiveI9s = ()=>{
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [update, setUpdate] = useState(true)
    const [anchorQmEl, setAnchorQmEl] = useState(null);
    const [selectedData, setSelectedData] = useState({})
    // SupB Modal
    let date = new Date();
    const preparerSigCanvas = useRef({});

    const [newSupplementB, setNewSupplementB] = useState(
        {
        id:uuid(),
        dateOfRehire:'',
        lastName: '',
        firstName: '',
        middleInitial: '',
        listType:'listA',
        documentTitle: '',
        documentNumber: '',
        expirationDate: '',
        signature: '',
        date: date,
        employerLastName: '',
        employerFirstName: '',
        additionalInformation:'',
        alternativeProcedure: false,
        documentFront:null,
        documentBack:null

        })

    const [formModal, setFormModal] = useState(false)

    const handleCheckboxChange = () => {
      setNewSupplementB((prevData) => ({
        ...prevData,
          alternativeProcedure: !newSupplementB.alternativeProcedure,
        }));
        
    };

    const handleFileUploadFront = (file, document) => {
      setNewSupplementB((prevData) => ({
          ...prevData,
            [document]: file,
          }));
    }

    const handleFileUploadBack = (file, document) => {
      setNewSupplementB((prevData) => ({
          ...prevData,
            [document]: file,
          }));
    }

    const handleCloseModal = (a)=>{

      setFormModal(false);
  }
  const handleChange = (e)=>{
    setNewSupplementB((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
      console.log(newSupplementB)
}

const clearDocuments = ()=>{
  setNewSupplementB((prevData) => ({
    ...prevData,
    listType:'',
    documentTitle: '',
    documentNumber: '',
    expirationDate: '',
    documentBack:null,
    documentFront:null
  }));
}

const clearSignature = () => {
  preparerSigCanvas.current.clear();
  setNewSupplementB((prevData) => ({
    ...prevData,
    signature: '',
  }));
};

const saveSignature = () => {
  const signatureImage = preparerSigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
  // Save or send the signatureImage as needed
  setNewSupplementB((prevData) => ({
    ...prevData,
    signature: signatureImage,
  }));

  console.log(newSupplementB);
};

async function uploadFile(a, folderText) {
   
  const file = a;
  const folder = folderText;

  if (!file || !folder) {
    alert('Please select a file and enter a folder name.');
    return;
  }

  const fileFormData = new FormData();
  fileFormData.append('file', file);
  fileFormData.append('folder', folder);

  try {
    const response = await axios.post(API+'/file/upload', fileFormData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important for file uploads
      },
    });

    // Ensure the response is successful (status code 2xx)
    if (response.data.ok) {
      // Extract the shared link directly as a string
      const sharedLink = await response.data.sharedLink;
console.log(sharedLink)
      let modifiedLink = sharedLink.replace("www.dropbox.com", "dl.dropboxusercontent.com");


          const updatedData = {
            ...newSupplementB,
            document: modifiedLink,
          };
        
          // Use updatedData for any immediate operations
        
          // For example, you can log it here
          console.log(updatedData);
          
return sharedLink
      

            
    } else {
      // Handle non-successful response (status code other than 2xx)
      console.error('Error uploading file:', response.statusText);
      alert('Error uploading file. Please try again.');
      setLoading(false)
      return false
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    
    alert('Error uploading file. Please try again.');
    setLoading(false)

    return false
  }
}

const fetchIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
     console.log(response.data.ip)
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    // Handle error or set default value
    
  }
};


const handleSubmit = async()=>{

  setLoading(true)

if(newSupplementB.documentFront !== null && newSupplementB.documentBack !== null){
  const fileFront = await uploadFile(newSupplementB.documentFront, `${user._id}/${selectedData.id}/supplement-b/${newSupplementB.id}`)
  const fileBack = await uploadFile(newSupplementB.documentBack, `${user._id}/${selectedData.id}/supplement-b/${newSupplementB.id}`)
  const modifiedLinkFront = fileFront.replace("www.dropbox.com", "dl.dropboxusercontent.com");
  const modifiedLinkBack= fileBack.replace("www.dropbox.com", "dl.dropboxusercontent.com");

  
  const updatedSupData = {
    ...newSupplementB,
    documentFront: modifiedLinkFront,
    documentBack: modifiedLinkBack,
    ipAddress: fetchIP()
  };

  let preparedData = {
    ...selectedData,
    sectionTwo: {
      ...selectedData.sectionTwo,
      supplementB: [
        ...selectedData.sectionTwo.supplementB,
        updatedSupData
      ]
    }
  };// Make a copy of selectedData to avoid direct mutations

  
  
  // Now, preparedData is ready with the updated supplementB and can be used to reactivate an employee
  reactivateEmployee(user._id, preparedData);

} else {

    
  const updatedSupData = {
    ...newSupplementB,
    ipAddress: fetchIP()
  };
  let preparedData = {
    ...selectedData,
    sectionTwo: {
      ...selectedData.sectionTwo,
      supplementB: [
        ...selectedData.sectionTwo.supplementB,
        updatedSupData
      ]
    }
  };
  reactivateEmployee(user._id, preparedData)

}
  

 
}

  /// End Sup B

    const handleQuickMenuOpen = (event) => {
      setAnchorQmEl(event.currentTarget);
    };
  
    // Function to close the menu
    const handleQuickMenuClose = () => {
      setAnchorQmEl(null);
    };
    useEffect(() => {
        // Fetch user data from the server when the component mounts or when the user comes back to the page
        const fetchUserData = async () => {
          try {
            // Check if the token cookie exists before making the request
            const token = Cookies.get('token');
            if (!token) {
              console.error('Token not found. Redirecting to login.');
              navigate('/login');
              return;
            }
    
            const response = await axios.post(API+ '/auth/refresh-user', { token });
            const refreshedUser = response.data.user;
       console.log(refreshedUser)
            // Update the user data in the state
            setUser(refreshedUser);
            console.log(user)
            setLoading(false)
          } catch (error) {
            console.error('Error fetching user data:', error.message);
            navigate('/login'); // Redirect to the login page in case of an error
          }
        };
    
        fetchUserData();
      }, [navigate, update]);

     

      function calculateDestructionDate(dateOfHire, dateOfTermination) {
        // Convert strings to Date objects if they are not already
        console.log(dateOfHire)
        console.log(dateOfTermination)
        const hireDate = new Date(dateOfHire);
        const terminationDate = new Date(dateOfTermination);
      
        // Calculate one year from the date of termination
        const oneYearAfterTermination = new Date(terminationDate.setFullYear(terminationDate.getFullYear() + 1));
        console.log('One after termination:'+oneYearAfterTermination)
        // Calculate three years from the date of hire
        const threeYearsAfterHire = new Date(hireDate.setFullYear(hireDate.getFullYear() + 3));
      console.log('Three after hire:'+threeYearsAfterHire)
        // Determine which date is later
        const dateOfDestruction = new Date(Math.max(oneYearAfterTermination, threeYearsAfterHire));
        console.log('Destruction Date:'+dateOfDestruction)
        // Return the date of destruction in YYYY-MM-DD format
        return dateOfDestruction.toISOString().split('T')[0];
      }


      function getColorBasedOnDestructionDate(dateOfHire, dateOfTermination, a , b) {
        const destructionDate = calculateDestructionDate(dateOfHire, dateOfTermination);
        const today = new Date();
        const todayFormatted = new Date(today.toISOString().split('T')[0]); // formatted to match destructionDate's format
      
        // Check if the destruction date is today or in the future
        return destructionDate >= todayFormatted.toISOString().split('T')[0] ? a : b;
      }

      const copyLink = async (a,b)=>{
  
        let localLink =`http://localhost:3000/#/forms/section-one?a=${a}&b=${b}`
        let link = `http://app.i9helpdesk.com/#/forms/section-one/?a=${a}&b=${b}`
        await navigator.clipboard.writeText(localLink)
        window.alert('Copied Link to clipboard. Now you can use this link to personally email the employee their link to complete the section 1 of the form.')
        }

        const deleteI9 = async (a,b, c)=>{
          const conf = window.prompt('Are you sure you would like to do this? It cannot be undone and will result in all of the I9 data being deleted. Please write a brief reason this I-9 is being deleted:')
          if(conf){
          let resp = await axios.post(`${API}/i9/delete-i9`,{id:a, i9Id:b, type:c, location:'inactive', reason:conf})

          if(resp.data.deleted){
            
            setUpdate(!update)
          } else{
            window.alert('Something went wrong. Please try again')
          }
        }
        }


        const reactivateEmployee = async (a,b)=>{
          const conf = window.confirm('Are you sure you would like to do this?')
          if(conf){
            const updatedB = {
              ...b,
              recentDateOfHire: newSupplementB.dateOfRehire
            }
            try {
              const response = await fetch(`${API}/i9/reactivate-employee`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: a, data: updatedB }),
              });
            
              if (response.ok) {
                const responseData = await response.json();
                if (responseData.success) {
                  setFormModal(false)
                  setLoading(true);
                  setUpdate(!update)
                } else {
                  window.alert('Something went wrong. Please try again');
                }
              } else {
                throw new Error('Network response was not ok.');
              }
            } catch (error) {
              console.error('Fetch error:', error.message);
            }
        }
        }

       

      

      if(loading){
          return (<LoaderModal open={true}/>)
      } else {
        
    return(
        <Box sx={{ height: '100vh', background: '#F4F4F4' }}>
        <NavBar />
        <TitleBar
        NavigationTitle="Back"
        To="/"
        Title={'Inactive I9s'}
        DisplayButton={false}
        ButtonTitle={'+ Add New I9'}
        onClick={handleQuickMenuOpen}
        // onClick={()=>navigate('/forms/i9')}
        Menu={
          <Menu
            
            id="profile-menu"
            anchorEl={anchorQmEl}
            open={Boolean(anchorQmEl)}
            onClose={handleQuickMenuClose} 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
         
          >
            <MenuItem>
              <Link style={ {marginRight:2, textDecoration:'none'}}  to='/forms/i9'> <ListItemText>
               <Typography variant='button'>Start New I9</Typography>
              </ListItemText>
              </Link>
             
              </MenuItem>
              <Divider/>
            <MenuItem>
              <Link style={ {marginRight:2, textDecoration:'none'}}  to='/i9/upload'> <ListItemText>
              <Typography variant='button'>Upload Employees I9</Typography>
              </ListItemText>
              </Link>
             
              </MenuItem>

          </Menu>}
        />
        <SearchBar
        label="SEARCH BY EMPLOYEE NAME"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        />
      <hr/>  
        <Box sx={{ margin:3 }} component={Paper}>
          {/* Search bar */}
          

          <Table>
            <TableHead>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Date of Hire</b>
              </TableCell>
              <TableCell>
                <b>Termination Date</b>
              </TableCell>
              <TableCell>
                <b>Destruction Date</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell align='center'>
                <b>Actions</b>
              </TableCell>
            </TableHead>
            <TableBody>
            {user.data.inactive.filter(i =>
          (i.sectionOne?.firstName ? i.sectionOne?.firstName : i.firstName).toLowerCase().includes(searchInput.toLowerCase()) ||
          (i.sectionOne?.lastName ? i.sectionOne?.lastName : i.lastName).toLowerCase().includes(searchInput.toLowerCase())
        ).map(i=>{
          let hireDate = i.recentDateOfHire ? i.recentDateOfHire : i.sectionTwo.firstDayOfEmployment
                    return (
                        <TableRow>
                        <TableCell>{i.sectionOne?.firstName ? i.sectionOne?.firstName : i.firstName} {i.sectionOne?.lastName ? i.sectionOne?.lastName : i.lastName}</TableCell>
                        <TableCell>{i.recentDateOfHire ? formatDate(i.recentDateOfHire) : formatDate(hireDate)}</TableCell>
                        <TableCell>{formatDate(i.dateOfTermination)}</TableCell>
                        <TableCell>{formatDate(calculateDestructionDate( hireDate, i.dateOfTermination))}</TableCell>
                        <TableCell sx={{color:getColorBasedOnDestructionDate(i.date, i.dateOfTermination, 'orange', 'green')}}><b>{getColorBasedOnDestructionDate(i.date, i.dateOfTermination, 'Do not delete this I9 until destruction date', 'You can now delete this I9')}</b></TableCell>
                        <TableCell align='center'>

                        <ToolTipButton
                         Title="View I9"
                         onClick={()=>{navigate('/i9/view',{ state:{a: user._id, b: i}})}}
                         Icon={<Visibility/>}
                         sx={{display:i.type === 'upload' ? 'inline': 'none' }}
                         />
                        
                         <ToolTipButton
                         Title="View Details"
                         onClick={()=>{navigate('/i9s/details',{ state:{a: user._id, b: i.id, c:'inactive'}})}}
                         Icon={<Visibility/>}
                         sx={{display:i.type === 'upload' ? 'none' : i.sectionOne?.submitted ? 'inline': 'none' }}
                         />
                         <ToolTipButton
                         Title="Copy link to Section One Form"
                         Icon={<ContentCopy/>}
                         onClick={()=>copyLink(user._id, i.id)}
                         sx={{display:i.type === 'upload' ? 'none' : i.sectionOne?.submitted ? 'none': 'inline'}}
                         />
                          <ToolTipButton
                         Title="Reactivate I9"
                         onClick={()=>{setFormModal(true); setSelectedData(i); 
                          setNewSupplementB((prevData)=>({
                          ...prevData,
                          firstName:i.sectionOne.firstName,
                          lastName: i.sectionOne.lastName,
                          middleInitial:i.sectionOne.middleInitial,
                          employerFirstName:i.sectionTwo.employerFirstName,
                          employerLastName: i.sectionTwo.employerLastName,


                         }))}}
                         Icon={<AddCircleIcon/>}
                         sx={{color:'green'}}
                         />
                         <ToolTipButton
                         Title="Delete"
                         onClick={()=>deleteI9(user._id, i.id, i.type)}
                         Icon={<Delete/>}
                         sx={{color:'tomato'}}
                         />
                         
                       
                        </TableCell>
                        </TableRow>
                        
                    )
                })}
            </TableBody>
          </Table>
        </Box>

        <SupplementBModal
        newSupplementB={newSupplementB}
        setNewSupplementB={setNewSupplementB}
        formModal={formModal}
        handleCheckboxChange={handleCheckboxChange}
        handleCloseModal={handleCloseModal}
        handleChange={handleChange}
        clearSignature={clearSignature}
        saveSignature={saveSignature}
        preparerSigCanvas={preparerSigCanvas}
        handleSubmit={handleSubmit}
        clearDocuments={clearDocuments}
        handleFileUploadFront={handleFileUploadFront}
        handleFileUploadBack={handleFileUploadBack}
        selectedData={selectedData}
        />
      </Box>
    )

}
}