import React, { useEffect, useState } from "react";
import { NavBar } from "../../../components/NavBar";
import { TitleBar } from "../../../components/TitleBar";
import { Alert, Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { EditableForm } from "../../../components/Everify/EditableForm";
import { useLocation } from "react-router-dom";
import LoaderModal from "../../../components/LoaderModal";
import { NonEditableForm } from "../../../components/Everify/NonEditableForm";
import axios from "axios";
import { API } from "../../../controllers/API";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ConfirmDetailsForm from "../../../components/Everify/ConfirmDetailsForm";


export const EverifySubmitDetail = ()=>{
    const {state} = useLocation();
    const [edit, setEdit] = useState(false)
    const [update, setUpdate] = useState(false)
    const token = Cookies.get('token');
    const [formFields, setFormFields] = useState(null);
    const [loading, setLoading] = useState(true);
    const [confirmDetails, setConfirmDetails] = useState([])
    const {data} = state;
    let navigate = useNavigate();

useEffect(()=>{
const getData = async ()=>{

    let resp = await axios.post(API + '/everify/authentication/check-case', {caseNumber: state.data.case_number})
    console.log(resp.data)
setFormFields(resp.data.sendBack);

if(resp.data.sendBack.case_status === 'UNCONFIRMED_DATA'){
  let checkDataResp = await axios.post(API + '/everify/authentication/confirm-case-fields', {caseNumber: state.data.case_number});
  console.log(checkDataResp.data.result.case_fields)

  if (checkDataResp.data.result.case_fields) {
    // Compare the array of strings with formFields and create confirmDetails
    const matchingFields = Object.entries(resp.data.sendBack)
      .filter(([property]) => checkDataResp.data.result.case_fields.includes(property))
      .map(([property, value]) => ({
        field_name: property,
        value: value /* You can set the value based on your requirements */,
      }));

    setConfirmDetails(matchingFields);
    console.log(matchingFields)
    setLoading(false)
  }

  
} else {
  setLoading(false)
}







}
getData()

},[update])
const handleSubmit = async () =>{
    
  let resp = await axios.post(API + '/everify/authentication/submit-case',{caseNumber:formFields.case_number})
    if(resp.data.submitted){
        window.alert('The case has been submitted. Check back occasionally review the case status.')
        navigate('/everify/pending')
    } else {
        window.alert('Something went wrong. Please try again.')
    }
}

const handleConfirmDetails = async ()=>{
  let resp = await axios.post(API + '/everify/authentication/confirm-case',{caseNumber:formFields.case_number, case_fields:confirmDetails})
  console.log(resp.data);

  window.location.reload();
}

const handleEdit = ()=>{
   


    setEdit(!edit);
}

if(loading){
    return <LoaderModal open={loading}/>
} else {

    return(
    <Box>
      <NavBar/>
            <TitleBar
            NavigationTitle="Back"
            To="-1"
            Title="E-Verify"
            />

            <Box sx={{padding:5}} >
             
                <Paper sx={{ padding:5, width:'100%'}}>
                
                    <Box sx={{paddingBottom:4}}>
                        <Typography sx={{fontSize:18}}><b>REVIEW EMPLOYEE INFORMATION</b></Typography>
                  
                    <Typography variant="p">Review the information in case information below. When you are ready click "SUBMIT" at the bottom of the form.</Typography>
                   
               
                    </Box>
                    
                    <Box sx={{display:confirmDetails.length > 0 ? 'block': 'none'}}>
                    <Alert sx={{ border:'solid orange 1px'}} severity="warning">
                    <Grid container>
                      <Typography>
                      {`Check your data entry with ${formFields.first_name} ${formFields.last_name} Form I-9.`}
                      </Typography>

                      <Grid  item sm={12}>
                        
                        {confirmDetails.map((i)=>{
                          return (
                            <Box sx={{marginTop:2}}>
                            <Typography><b>{i.field_name.replace(/_/g,' ').toUpperCase()}</b></Typography>
                            <Typography>{i.value}</Typography>

                            <ConfirmDetailsForm
                            confirmDetails={confirmDetails}
                            updateField={i.field_name}
                            updateValue={i.value}
                            setConfirmDetails={setConfirmDetails}
                            />
                            </Box>
                          )
                        })}
                    
                      </Grid>
                    </Grid>
                    </Alert>
                    <Box sx={{textAlign:'end', marginTop:2}}>
                      <Button onClick={handleConfirmDetails} variant="contained">Confirm Details</Button>
                    </Box>
                    </Box>
                  
                    <Grid container>
                 <Grid sx={{marginBottom:2}} item xs={12} sm={6}>
                 <Typography>
              <b>CASE NUMBER</b>
            </Typography>

            <Typography>
              {formFields.case_number}
            </Typography>    
                
                </Grid>      
                <Grid sx={{marginBottom:2}} item xs={12} sm={6}>
                 <Typography>
              <b>CASE STATUS</b>
            </Typography>

            <Typography sx={{color: formFields.case_status === 'COMPLETE' ? 'green':'orange'}}>
            <b>{formFields.case_status}</b>
            </Typography>    
                
                </Grid>  
                {edit &&(
              <EditableForm initialData={formFields}/>

                )} 

                 {edit === false &&(
              <NonEditableForm initialData={formFields}/>

                )}     

              </Grid>
              </Paper>
              <Box sx={{padding:2, textAlign:'end'}}>
              {/* <Button sx={{marginRight:2}} onClick={()=>setEdit(!edit)} variant="contained">{edit ? 'Save': 'Edit'} Information</Button> */}

                <Button sx={{display:formFields.case_status === 'DRAFT'? 'inline':'none'}} onClick={handleSubmit} variant="contained">Submit</Button>
              </Box>
            </Box>
    
    </Box>
    )
}
}