import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const EverifyConfirm = ({ open, onClose, onConfirm }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography variant="h6" component="div" gutterBottom>
          Confirm Case Creation
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          All information to create the case is complete. The price for this service is $9.95.
        </Typography>
        <Button variant="contained" onClick={onConfirm}>
          Pay and Create E-Verify Case Number
        </Button>
      </Box>
    </Modal>
  );
};

export default EverifyConfirm;
