import React, { useState } from 'react';
import { Card, CardContent, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';

const MembershipSelection = ({handleMembershipChange, selectedMembership}) => {
  const membershipOptions = [
    { id: 'basic', title: 'Basic', i9Limit: '1-100', price: 299 },
    { id: 'pro', title: 'Pro', i9Limit: '1-500', price: 499 },
    { id: 'enterprise', title: 'Enterprise', i9Limit: 'Unlimited', price: 999 },
  ];

  
  return (
    
    <div>
      <FormControl component="fieldset">
    
        <RadioGroup
          aria-label="membership"
          name="membership"
          value={selectedMembership}
          onChange={handleMembershipChange}
          // Set flex container and row direction
        >
          <Grid spacing={2} container>
      
          {membershipOptions.map((option) => (
          <Grid item sm={12} xs={12}>
          <Card >
            <CardContent>
              <FormControlLabel
                value={option.title}
                control={<Radio />}
                label={
                  <>
                  <Typography gutterBottom variant="button" sx={{fontSize:20}} component="div">
            <b>{option.title} Membership</b>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Create and Store up to {option.i9Limit} I-9's
            </Typography>
            <Typography variant="h6" component="div" sx={{ paddingTop: 2 }}>
              ${option.price}/yearly
            </Typography>
                  </>
                }
              />
            </CardContent>
          </Card>
          </Grid>
          ))}
         
          </Grid>
          
        </RadioGroup>
 
      </FormControl>
      
    </div>
  );
};

export default MembershipSelection;
