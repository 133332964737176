import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { MenuItem, Select } from '@mui/material';

export const EditableForm = ({ initialData }) => {
  const [formFields, setFormFields] = useState(initialData);

  const handleFieldChange = (fieldName, value) => {
    setFormFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container spacing={2}>
      {Object.entries(initialData).map(([fieldName, initialValue]) => {
        if( fieldName === 'citizenship_status_code' || fieldName.includes('_type_code')){
          if(initialValue !== null){
            
            return (
            <Grid key={fieldName} item xs={12} sm={6}>
            <Typography>
              <b>{fieldName.includes('_') ?  fieldName.replace(/_/g,' ').toUpperCase() : fieldName.toUpperCase()}</b>
            </Typography>

            <Typography>
              {initialValue.replace(/_/g,' ')}
            </Typography>
            </Grid>
            
          )

        }
        } else{
            
        return (
            <>
       { initialValue !== null && initialValue !== undefined && typeof initialValue !== 'boolean' && fieldName !== 'case_status_display' && fieldName !== 'case_number' && fieldName !== 'client_software_version' && fieldName !== 'client_company_id' && !Array.isArray(initialValue) && fieldName !== 'case_status' &&  (
  
  <Grid key={fieldName} item xs={12} sm={6}>
  <Typography>
    <b>{fieldName.replace(/_/g,' ').toUpperCase()}</b>
  </Typography>
  <TextField
  type={fieldName.includes('date') ? 'date': 'text'}
    fullWidth
    value={formFields[fieldName] || ''}
    onChange={(e) => handleFieldChange(fieldName, e.target.value)}
  />
</Grid>
       
        )}
        </>
        )

    }
      })
    }
    </Grid>
  );
};

