import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Route, Routes, useLocation } from 'react-router-dom';
import { NavBar } from '../../components/NavBar';
import { Dashboard } from './Dashboard';
import { Users } from './Users'; // Import your Users component
import { Settings } from './Settings'; // Import your Settings component
import Cookies from 'js-cookie';
import {  useNavigate } from 'react-router-dom';
import { API } from '../../controllers/API';
import axios from 'axios';
import LoaderModal from '../../components/LoaderModal';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import { MenuItem } from '@mui/material';

const drawerWidth = 240;

export const AdminDashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(true);
  const [mainComponent, setMainComponent] = useState(<Dashboard/>)
  
  const navigationMenu = [
    {
      text: 'Dashboard',

      component: ()=> setMainComponent(<Dashboard/>),
    },
    {
      text: 'Users',

      component:()=> setMainComponent(<Users/>),
    },
    {
      text: 'Settings',

      component: ()=> setMainComponent(<Settings/>) ,
    },
  ];

  useEffect(() => {
    // Fetch user data from the server when the component mounts or when the user comes back to the page
    const fetchUserData = async () => {
      try {
        // Check if the token cookie exists before making the request
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        

        const response = await axios.post(API+ '/auth/refresh-user', { token });
        const refreshedUser = response.data.user;
         if(refreshedUser.role === 'admin'){
          setUser(refreshedUser);
          console.log(user)
          setLoading(false)
         } else {
          navigate('/');
         }
        // Update the user data in the state
       
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };

    fetchUserData();
  }, [update]);
if(loading){
    return <LoaderModal open={loading}/>
} else {
  
  return (
    <Box sx={{ display: 'block' }}>
      <CssBaseline />
      <NavBar 
      sx={{paddingLeft:'240px' }} 
      ExtraMenuItems={
        <MenuItem sx={{display: user.role === 'admin' ? 'block':'none'}} onClick={() => {
          navigate('/');
          // Add functionality for Account Settings
       // Close the menu after selection
        }}>
          User Dashboard
        </MenuItem>
      }
      />
    
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {navigationMenu.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={item.component}>
                <ListItemIcon>
                  {item.text === 'Dashboard' ? <DashboardIcon /> : item.text === 'Users' ? <GroupIcon/> : <SettingsIcon />}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
      sx={{paddingLeft:'250px', display:'block', paddingTop:1}}
      >
       {mainComponent}
      </Box>
    </Box>
  );
}

};
