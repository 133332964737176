// src/components/Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Cookies from 'js-cookie';
import logo from '../assets/images/i9HelpDesk.png';
import '../assets/css/login.css';
import { Box, CircularProgress } from '@mui/material';
import { API } from '../controllers/API';


const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(API + '/auth/login', { email, password, keepLoggedIn });
      const { token, user } = response.data;
  
      // Set the token and user data in cookies
      Cookies.set('token', token, { expires: keepLoggedIn ? 7 : 1 });
      Cookies.set('user', JSON.stringify(user), { expires: keepLoggedIn ? 7 : 1 });
  
      if (!user.subscriptionActive) {
        alert('Your subscription is not active. Please renew your subscription.');
        navigate('/account');
      } else {
        alert(`Login Successful. Welcome back, ${user.firstName} ${user.lastName}!`);
        navigate('/');
      }
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Login failed. Please try again.';
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Grid container className="root">
      <Grid className='grid-item' item xs={12} sm={6} md={4}>
        <div style={{padding:10, marginRight:10}}>
      <img width="100%" style={{padding:5}} src={logo} alt="Logo" />
      </div>
        <Paper sx={{padding:2}} className="paper">
          {/* Add your logo here */}
         

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />
          <FormControlLabel
            control={<Checkbox checked={keepLoggedIn} onChange={() => setKeepLoggedIn(!keepLoggedIn)} />}
            label="Keep me logged in"
          />
          <Button endIcon={<CircularProgress sx={{display:loading ? 'inline':'none'}} color='inherit' size={20}/>} variant="contained" color="primary" fullWidth onClick={handleLogin} style={{ marginTop: '16px' }}>
            Login
          </Button>
        </Paper>
        <Box sx={{textAlign:'center', paddingTop:5}}>
       <Link to="/register"> <Button sx={{color:'white'}}><b>Create an Account</b></Button></Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
