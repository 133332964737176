import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { NavBar } from '../../components/NavBar';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { API } from '../../controllers/API';
import LoaderModal from '../../components/LoaderModal';

const UserTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true)

  const {state} = useLocation();
  const {subscriptionId} = state;
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        console.log(state)
        const response = await axios.post(API + '/admin/transactions', { subscriptionId: subscriptionId});
       
        console.log(response)
        setTransactions(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [subscriptionId]);
if(loading){
    return <LoaderModal open={loading}/>
} else {
    
  return (
    <Box>
        <NavBar/>
        <Box sx={{padding:2}}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Date</TableCell>
            {/* Add more fields as needed */}
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow key={transaction.id}>
              <TableCell>{transaction.id}</TableCell>
              <TableCell>{transaction.amount}</TableCell>
              <TableCell>{transaction.currency}</TableCell>
              <TableCell>{transaction.date.toLocaleString()}</TableCell>
              {/* Add more fields as needed */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    </Box>
  );
}
};

export default UserTransactions;
