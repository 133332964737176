import React, {useState, useEffect, useRef} from "react";
import { NavBar } from "../../../components/NavBar";
import { Box, Divider, Modal, Grid, Step, StepLabel, Stepper, Typography, Button, Snackbar, Alert, Container, TextField, FormControlLabel, Select, MenuItem } from "@mui/material";
import { TitleBar } from "../../../components/TitleBar";
import { useLocation, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import SignatureCanvas from 'react-signature-canvas';
import { API } from "../../../controllers/API";
import axios from "axios";
import { listADocuments, listBDocuments, listCDocuments, statesArray } from "../../../utils/Global-Functions";
import { checkDuplicates, handleSubmitEverify } from "../../../utils/Everify";
import Cookies from 'js-cookie';
import { StartEverifyModal } from "../../../components/Everify/StartEverifyModal";
import DocumentsModal from "../../../components/DocumentsModal";
import IssuingAuthoritiesModal from "../../../components/IssuingAuthoritiesModal";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export const SectionTwo = ()=>{
  const token = Cookies.get('token');
    const steps = ['Employer Review', 'Verification'];
    const {state} = useLocation();
    const [loading, setLoading] = useState(true);
    const [gettingData, setGettingData] = useState(false);
    const {id,i9Id, data} = state;
    const [imgModal, setImgModal] = useState(false)
    const [img, setImg] = useState(false)
    const [sendingSubmitting, setSendingSubmitting] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEverify, setOpenEverify] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [sectionOne, setSectionOne] = useState(null)
    const [everifyData, setEverifyData] = useState({data:null})
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [user, setUser] = useState({})
    const signCanvas = useRef({});
    const [listAExp, setListAExp] = useState(false) 
    const [listBExp, setListBExp] = useState(false) 
    const [listCExp, setListCExp] = useState(false) 

    const [documentModalShow, setDocumentModalShow] = useState(false);


    const handleOpenDocumentModal = () => {
      setDocumentModalShow(true);
  };

  const handleCloseDocumentModal = () => {
    setDocumentModalShow(false);
  };


  const [issuingAuthoritiesModalShow, setIssuingAuthoritiesModalShow] = useState(false);


  const handleOpenIssuingAuthoritiesModal = () => {
    setIssuingAuthoritiesModalShow(true);
};

const handleCloseIssuingAuthoritiesModal = () => {
  setIssuingAuthoritiesModalShow(false);
};

    let navigate = useNavigate();

    const clearSignature = () => {
      signCanvas.current.clear();
      setFormData((prevData) => ({
        ...prevData,
        employerSignature: '',
      }));
    };
  
    const saveSignature = () => {
      const signatureImage = signCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      // Save or send the signatureImage as needed
      setFormData((prevData) => ({
        ...prevData,
        employerSignature: signatureImage,
      }));
   
      console.log(formData);
    };

    const [formData, setFormData] = useState({
        listA:{
        documentTitle: '',
        issuingAuthority: '',
        documentNumber: '',
        expirationDate: '',

        },
        listB: {
        documentTitle: '',
        issuingAuthority: '',
        documentNumber: '',
        expirationDate: '',
   
        },
        listC:{
        documentTitle: '',
        issuingAuthority: '',
        documentNumber: '',
        expirationDate: '',
   
        },
        alternativeProcedure: false,
        firstDayOfEmployment: '',
        employerLastName: '',
        employerFirstName: '',
        employerTitle: '',
        employerSignature: '',
        todayDate: '',
        employerBusinessName: '',
        employerAddress: '',
        employerCity: '',
        employerState: '',
        employerZipCode: '',
        supplementB:[],
        submitted: true
      });

      const handleCheckboxChange = () => {
        setFormData((prevData) => ({
          ...prevData,
            alternativeProcedure: !formData.alternativeProcedure,
          }));
          
      };
      const handleInputChange = (fieldName, value) => {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
      };

      const handleFormChange = (e) => {
   
        setFormData({
          ...formData,
          [e.target.id]: e.target.value,
        });
        console.log(formData)
      };
    
 // Adding the beforeunload event listener
 useEffect(() => {
  const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to leave? Changes you made may not be saved.';
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);

    
    
      const handleChange = (e) => {
        const { name, value } = e.target;
      
        setFormData((prevData) => ({
          ...prevData,
          [name]: {
            ...prevData[name],
            [e.target.id]: value,
          },
        }));
        console.log(name, value, e.target.id)
      };

      const handleChangeDT = (e) => {
        const { name, value } = e.target;
      console.log(name);
      console.log(value)
        setFormData((prevData) => ({
          ...prevData,
          [name]: {
            ...prevData[name],
            documentTitle: value,
          },
        }));
        console.log(formData)
       
      };
      let missingFields = []

      useEffect(()=>{
           if(everifyData.error){
            setOpenEverify(false); 
            setEverifyData([]); 
            setGettingData(false)
            setSendingSubmitting(false)
           }
      },[everifyData])
      const handleSubmit = async (e) => {
    
       
        // Handle form submission, e.g., send data to the server or perform any necessary actions
        if (isStepTwoValid()) {
          console.log('Form submitted:', formData);
           let resp = await axios.post(API + '/i9/save-section-2', {id: id, i9Id:i9Id, formData:formData})
          
        if(resp.data.success){
          if(everifyData.length === 0){
            console.log(`e: ${e}`)
            if(e === true){
              window.alert('The E-Verify was successful and employment has been authorized. To review the  The information has been saved to the I-9.')

            } else {
              window.alert('The information has been saved to the I-9.')

            }


          } else{
            window.alert('Section Two of the I9 is completed. You can start the E-Verify process on the I-9 detail page.')

          }
          navigate('/');
        } else {
          window.alert('Something went wrong. Please try again.')
        }
        } else{
          const missingFields = activeStep === 0 ? getMissingFieldsStepOne() : getMissingFieldsStepTwo();
          const missingFieldsMessage = missingFields.map((field) => `- ${field}`).join('\n');
          const alertMessage = `Please make sure you have filled out all required fields before moving on:\n${missingFieldsMessage}`;
          setSnackbarMessage(alertMessage)
          setOpen(true)
        }
      };

      const getMissingFields = () => {
        const missingFields = [];
        // Check for missing fields in listA
        const listAFields = ['documentTitle', 'issuingAuthority', 'documentNumber', 'expirationDate'];
        listAFields.forEach((field) => {
          if (!formData.listA[field]) {
            missingFields.push(`List A - ${field}`);
          }
        });
      
        // Check for missing fields in listB
        const listBFields = ['documentTitle', 'issuingAuthority', 'documentNumber', 'expirationDate'];
        listBFields.forEach((field) => {
          if (!formData.listB[field]) {
            missingFields.push(`List B - ${field}`);
          }
        });
      
        // Check for missing fields in listC
        const listCFields = ['documentTitle', 'issuingAuthority', 'documentNumber', 'expirationDate'];
        listCFields.forEach((field) => {
          if (!formData.listC[field]) {
            missingFields.push(`List C - ${field}`);
          }
        });
      
        // Check for missing fields in other sections
        const otherFields = ['firstDayOfEmployment', 'employerLastName', 'employerFirstName', 'employerTitle', 'employerBusinessName', 'employerAddress', 'employerCity', 'employerState', 'employerZipCode'];
        otherFields.forEach((field) => {
          if (!formData[field]) {
            missingFields.push(field);
          }
        });
      
        return missingFields;
      };
      
     
      
    useEffect(()=>{
        setSectionOne(data)
        setFormData((prevData) => ({
            ...prevData,
            ['listA']: {
              ...prevData['listA'],
              documentTitle:data.listADocumentCode,
              documentLink: data.listADocument,
            },
          }));
          setFormData((prevData) => ({
            ...prevData,
            ['listB']: {
              ...prevData['listB'],
              documentTitle:data.listBDocumentCode,
              documentLink: data.listBDocument,
            },
          }));

          setFormData((prevData) => ({
            ...prevData,
            ['listC']: {
              ...prevData['listC'],
              documentTitle:data.listCDocumentCode,
              documentLink: data.listCDocument,
            },
          }));
        setLoading(false)
      console.log(data, id, i9Id)
    },[])

    const isStepOneValid = () => {
      const validateFields = (list) => {
        const requiredFields = ['documentTitle', 'issuingAuthority', 'documentNumber', 'expirationDate'];
        const isValid = requiredFields.every(field => !!formData[list][field]);
    
        if (!isValid) {
          return false;
        }
    
        // Additional validation for SOCIAL_SECURITY_CARD
        if (formData[list].documentTitle === 'SOCIAL_SECURITY_CARD' && formData[list].documentNumber.length < 9) {
          alert('Document Number for SOCIAL_SECURITY_CARD must be at least 9 characters.');
          return false;
        }
    
        return true;
      };
    
      if (sectionOne.listType === 'listA') {
        return validateFields('listA');
      } else {
        return validateFields('listB') && validateFields('listC');
      }
    };
    
    // Function to check if all required fields in step 2 are filled
    const isStepTwoValid = () => {
      const requiredFields = ['firstDayOfEmployment', 'employerLastName', 'employerFirstName', 'employerTitle', 'employerBusinessName', 'employerAddress', 'employerCity', 'employerState', 'employerZipCode', 'todayDate', 'employerSignature'];
     let newFilter = [];
     let filter = requiredFields.filter(o=> formData[o] === '');
     console.log(filter)
      return requiredFields.every(field => !!formData[field]);
    };



    const getMissingFieldsStepOne = () => {
      const requiredFields = ['documentTitle', 'issuingAuthority', 'documentNumber', 'expirationDate'];
      let newFilter = [];
      if(sectionOne.listType === 'listA'){
       let filter = requiredFields.filter(field => formData.listA[field] === '')
 
       filter.map((i)=>{
          if(i=== 'documentTitle'){
            newFilter.push('Document Title')

          }
          if(i=== 'issuingAuthority'){
            newFilter.push('Issuing Authority')
            
          }

          if(i=== 'documentNumber'){
            newFilter.push('Document Number')
            
          }

          if(i=== 'expirationDate'){
            newFilter.push('Expiration Date')
            
          }
       })
      } else {
        let filterB = requiredFields.filter(field => formData.listB[field] === '')
        let filterC = requiredFields.filter(field => formData.listC[field] === '')
      
       filterB.map((i)=>{
          if(i=== 'documentTitle'){
            newFilter.push('List B: Document Title')

          }
          if(i=== 'issuingAuthority'){
            newFilter.push('List B: Issuing Authority')
            
          }

          if(i=== 'documentNumber'){
            newFilter.push('List B: Document Number')
            
          }

          if(i=== 'expirationDate'){
            newFilter.push('List B: Expiration Date')
            
          }
       })

       filterC.map((i)=>{
        if(i=== 'documentTitle'){
          newFilter.push('List C: Document Title')

        }
        if(i=== 'issuingAuthority'){
          newFilter.push('List C: Issuing Authority')
          
        }

        if(i=== 'documentNumber'){
          newFilter.push('List C: Document Number')
          
        }

        if(i=== 'expirationDate'){
          newFilter.push('List C: Expiration Date')
          
        }
     })
      }
        
      return newFilter;
    };
    
    const getMissingFieldsStepTwo = () => {
      const requiredFields = ['firstDayOfEmployment', 'employerLastName', 'employerFirstName', 'employerTitle', 'employerBusinessName', 'employerAddress', 'employerCity', 'employerState', 'employerZipCode', 'todayDate', 'employerSignature'];
      let newFilter = [];
      let filter = requiredFields.filter(o=> formData[o] === '');

      filter.map(i=>{
        if(i === 'firstDayOfEmployment'){
          newFilter.push('First Day of Employment')
        }

        if(i === 'employerLastName'){
          newFilter.push('Employer Last Name')
        }

        if(i === 'employerFirstName'){
          newFilter.push('Employer First Name')
        }

        if(i === 'employerTitle'){
          newFilter.push('Employer Title')
        }

        if(i === 'employerBusinessName'){
          newFilter.push('Employer Business Name')
        }

        if(i === 'employerAddress'){
          newFilter.push('Employer Address')
        }

        if(i === 'employerCity'){
          newFilter.push('Employer City')
        }

        if(i === 'employerState'){
          newFilter.push('Employer State')
        }

        if(i === 'employerZipCode'){
          newFilter.push('Employer Zip Code')
        }

        if(i === 'todayDate'){
          newFilter.push("Today's Date")
        }

        if(i === 'employerSignature'){
          newFilter.push("Employer Signature")
        }
      })
      
      return newFilter
    };

    const handleNext = () => {
   
      let isValid = false;
    
      if (activeStep === 0) {
        isValid = isStepOneValid();
        if(isValid === true){
          window.scrollTo(0,0)

        }

      } else if (activeStep === 1) {
        isValid = isStepTwoValid();
      }
    
      if (isValid) {
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        // Display a Snackbar alert with a list of missing fields
        const missingFields = activeStep === 0 ? getMissingFieldsStepOne() : getMissingFieldsStepTwo();
        const missingFieldsMessage = missingFields.map((field) => `- ${field}`).join('\n');
        const alertMessage = `Please make sure you have filled out all required fields before moving on:\n${missingFieldsMessage}`;
        setOpen(true);
        console.error('Missing fields:', missingFields);
        setSnackbarMessage(alertMessage);
      }
    };
    

      const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

    
        setOpen(false);
      };


      const handleOpenModal = (a)=>{
        console.log(a)
        setImg(a)
        setImgModal(true);
    }

    const handleCloseModal = (a)=>{
        setImg('')
        setImgModal(false);
    }


    const getStepContent = (step) => {
        switch (step) {
          case 0:
            return (
             <Container>
             <Grid sx={{marginTop:2}} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>List Type and Documents</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listA' ? 'inline-block' : 'none'}} item xs={12} sm={4}>
              <Typography ><b>List Type</b></Typography>
              <Typography>{sectionOne.listType === 'listA' ? 'List A' : 'List B/C'}</Typography>
            </Grid>

            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listA' ? 'inline-block' : 'none'}} item xs={12} sm={4}>
              <Typography ><b>List A Document Front:</b></Typography>
              <Button onClick={()=>handleOpenModal(sectionOne.listADocumentFront)}>View Document</Button>
            </Grid>

            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listA' ? 'inline-block' : 'none'}} item xs={12} sm={4}>
              <Typography ><b>List A Document Back:</b></Typography>
              <Button onClick={()=>handleOpenModal(sectionOne.listADocumentBack)}>View Document</Button>
            </Grid>


            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={12}>
              <Typography ><b>List Type</b></Typography>
              <Typography>{sectionOne.listType === 'listA' ? 'List A' : 'List B/C'}</Typography>
            </Grid>

            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List B Document Front:</b></Typography>
              <Button onClick={()=>handleOpenModal(sectionOne.listBDocumentFront)}>View Document</Button>
            </Grid>

            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List B Document Back:</b></Typography>
              <Button onClick={()=>handleOpenModal(sectionOne.listBDocumentBack)}>View Document</Button>
            </Grid>

            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List C Document Front:</b></Typography>
              <Button onClick={()=>handleOpenModal(sectionOne.listCDocumentFront)}>View Document</Button>
            </Grid>

            <Grid sx={{marginTop:2, display: sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List C Document Back:</b></Typography>
              <Button onClick={()=>handleOpenModal(sectionOne.listCDocumentBack)}>View Document</Button>
            </Grid>
            </Grid>
            <div style={{display: sectionOne.listType === 'listA' ? 'block' : 'none'}}>

            <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Document Information</Typography>
          <Typography><b>NOTE:</b> If the document does not have an issuing authority or document number, just input <b>NA</b> into the field.</Typography>

        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Document Title *</b></Typography>
        <Select
          fullWidth
        value={formData.listA.documentTitle}
        name="listA"
        onChange={handleChangeDT}
        >
          {listADocuments.filter(doc => doc.citizenShipType.includes(sectionOne.citizenshipStatus)).map(i=>{
            return (<MenuItem value={i.code}>{i.documentName}</MenuItem>)
          })}

        </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Issuing Authority *</b></Typography>

          <TextField
          name="listA"
            id="issuingAuthority"
            fullWidth
            value={formData.listA.issuingAuthority}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Document Number *</b></Typography>

          <TextField
            name="listA"
            id="documentNumber"
            fullWidth
            value={formData.listA.documentNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography><b>Expiration Date *</b></Typography>

          <TextField
          name="listA"
            id="expirationDate"
            type="date"
            fullWidth
            disabled={listAExp}
            value={formData.listA.expirationDate}
            onChange={handleChange}
          />
           <FormControlLabel
            control={
              <Checkbox
                checked={listAExp}
                onChange={()=>setListAExp(!listAExp)}
              />
            }
            label={`Check here if there is no expiration date.`}
          />
          
        </Grid>
     

      </Grid>
   </div>
      <div style={{display: sectionOne.listType === 'listBC' ? 'block' : 'none'}}>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">List B Document Information</Typography>
          <Typography><b>NOTE:</b> If the document does not have an issuing authority or document number, just input <b>NA</b> into the field.</Typography>

        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Document Title *</b></Typography>
          <Select
          fullWidth
        value={formData.listB.documentTitle}
        id="documentTitle"
        name="listB"
        onChange={handleChangeDT}
        >
          {listBDocuments.map(i=>{
            return (<MenuItem value={i.code}>{i.documentName}</MenuItem>)
          })}

        </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Issuing Authority *</b></Typography>

          <TextField
          name="listB"
            id="issuingAuthority"
            fullWidth
            value={formData.listB.issuingAuthority}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Document Number *</b></Typography>

          <TextField
            name="listB"
            id="documentNumber"
            fullWidth
            value={formData.listB.documentNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography><b>Expiration Date *</b></Typography>

          <TextField
          name="listB"
            id="expirationDate"
            type="date"
            disabled={listBExp}
            fullWidth
            value={formData.listB.expirationDate}
            onChange={handleChange}
          />
            <FormControlLabel
            control={
              <Checkbox
                checked={listBExp}
                onChange={()=>{
                  setListBExp(!listBExp)
                  if(listBExp === true){
                  setFormData((prevData) => ({
                    ...prevData,
                    ['listB']: {
                      ...prevData['listB'],
                      ['expirationDate']: 'NA',
                    },
                  }));
                }
              }
                }
              />
            }
            label={`Check here if there is no expiration date.`}
          />
        </Grid>
        <Grid item xs={12}>
    
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">List C Document Information</Typography>
          <Typography><b>NOTE:</b> If the document does not have an issuing authority or document number, just input <b>NA</b> into the field.</Typography>

        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Document Title *</b></Typography>
        <Select
          fullWidth
        value={formData.listC.documentTitle}
        name="listC"
        onChange={handleChangeDT}
        >
          {listCDocuments.filter(doc => doc.citizenShipType.includes(sectionOne.citizenshipStatus)).map(i=>{
            return (<MenuItem value={i.code}>{i.documentName}</MenuItem>)
          })}

        </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Issuing Authority *</b></Typography>

          <TextField
          name="listC"
            id="issuingAuthority"
            fullWidth
            value={formData.listC.issuingAuthority}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography ><b>Document Number *</b></Typography>

          <TextField
            name="listC"
            id="documentNumber"
            fullWidth
            value={formData.listC.documentNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography><b>Expiration Date *</b></Typography>
        <TextField
          name="listC"
            id="expirationDate"
            type="date"
            fullWidth
            disabled={listCExp}
            value={formData.listC.expirationDate}
            onChange={handleChange}
          />
           <FormControlLabel
            control={
              <Checkbox
                checked={listCExp}
                onChange={()=>{setListCExp(!listCExp)
                  if(listCExp === false){
                    setFormData((prevData) => ({
                      ...prevData,
                      ['listC']: {
                        ...prevData['listC'],
                        ['expirationDate']: 'NA',
                      },
                    }));
                  }
                  
                }
                
                
                }
              />
            }
            label={`Check here if there is no expiration date.`}
          />
         
        </Grid>
        <Grid item xs={12}>
    
        </Grid>

      </Grid>
      </div>
      <Grid item xs={12} sm={12}>
      <Typography><b>Additional Information</b></Typography>

      <TextField

      multiline
      rows={4} // You can adjust the number of rows as needed
      value={formData.additionalInformation}
      onChange={(e) => {
        setFormData((prevData) => ({
          ...prevData,
          additionalInformation: e.target.value,
          }));
          console.log(formData)
      }}
      fullWidth
      sx={{ marginBottom: 2 }}
    />



        <FormControlLabel
            control={
              <Checkbox
                checked={formData.alternativeProcedure}
                onChange={handleCheckboxChange}
              />
            }
            label={`Check here if you used an alternative procedure authorized by DHS to examine documents.`}
          />
        </Grid>

             </Container>
            );
          case 1:
            return (
              <Box sx={{paddingLeft:2, paddingRight:2}} >
           
            <Grid container spacing={2}>
            <Grid item xs={6}>
            <Typography sx={{marginTop:2, marginBottom:2}}><b>First Day of Employment (mm/dd/yyyy)</b></Typography>

          <TextField
            type="date"
            id="firstDayOfEmployment"
            value={formData.firstDayOfEmployment}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
     
        <Grid item xs={12}>
            <Typography variant="button" sx={{marginTop:2, marginBottom:2, fontSize:18}}><b>Employer Information</b></Typography>

        </Grid>
        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>Last Name of Employer or Authorized Representitive</b></Typography>
         
          <TextField
            id="employerLastName"
            onChange={handleFormChange}
            value={formData.employerLastName}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>First Name of Employer or Authorized Representitive</b></Typography>

          <TextField
          id="employerFirstName"
            value={formData.employerFirstName}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>Title of Employer or Authorized Representitive</b></Typography>

          <TextField
          id="employerTitle"
            value={formData.employerTitle}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        
        <Grid item xs={12}>
            <Typography variant="button" sx={{marginTop:2, marginBottom:2, fontSize:18}}><b>Employer Address</b></Typography>

        </Grid>
      
        <Grid item xs={12}>
        <Typography sx={{ marginBottom:2}}><b>Employer's Business or Organization Name</b></Typography>

          <TextField
          id="employerBusinessName"
            value={formData.employerBusinessName}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
        <Typography sx={{ marginBottom:2}}><b>Employer's Business or Organization Address</b></Typography>

          <TextField
          id="employerAddress"
            value={formData.employerAddress}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
        <Typography sx={{ marginBottom:2}}><b>City or Town</b></Typography>

          <TextField
          id="employerCity"
            value={formData.employerCity}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
        <Typography sx={{ marginBottom:2}}><b>State</b></Typography>
        <Select
            fullWidth
          
            value={formData.employerState}
            // sx={{marginTop:1}}
            onChange={(e) => handleInputChange('employerState', e.target.value)}
            >
             {statesArray.map((i,index)=>{
              return (<MenuItem value={index}>{i}</MenuItem>)
             })}
            </Select>
        </Grid>
        <Grid item xs={4}>
        <Typography sx={{ marginBottom:2}}><b>ZIP Code</b></Typography>

          <TextField
            id="employerZipCode"
            value={formData.employerZipCode}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        
        <Grid item xs={12}>
      
        <Grid item xs={12}>
        <hr/>
            <Typography variant="button" sx={{marginTop:2, marginBottom:2, fontSize:18}}><b>Signature</b></Typography>
            <hr/>
            <Typography sx={{marginTop:2, marginBottom:2}}><b>Certification:</b> I attest, under penalty of perjury, that (1) I have examined the documentation presented by the above-named
            employee, (2) the above-listed documentation appears to be genuine and to relate to the employee named, and (3) to the
            best of my knowledge, the employee is authorized to work in the United States.</Typography>

        </Grid>

   
        <div style={{ border: '2px solid black', marginBottom: 5, display:formData.employerSignature === "" ? 'block': 'none' }}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  style: {
                    width: '100%',
                    height: 100,
                  },
                }}
                ref={signCanvas}
              />
            </div>
            <img style={{width:'100%', height:'auto'}} src={formData.employerSignature}/>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button sx={{display:formData.employerSignature === '' ? 'none': 'block', marginTop:2}} onClick={clearSignature} variant="outlined" color="secondary">
                  Clear Signature
                </Button>
              </Grid>
              <Grid item>
                <Button sx={{display:formData.employerSignature === '' ? 'block': 'none'}} onClick={saveSignature} variant="contained" color="primary">
                  Save Signature
                </Button>
              </Grid>
              
            </Grid>
            <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>Today's Date (mm/dd/yyyy)</b></Typography>

          <TextField
            type='date'
            id="todayDate"
            value={formData.todayDate}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        
              </Grid>
        
      </Grid>
            </Box>
            );
          
      
          default:
            return 'Unknown step';
        }
      };



   if(loading){
    return <p>Loading...</p>
   } else{
    
    return (
    <Box sx={{height:'100%', background:'white'}}>
     <NavBar
     ProfileSx={{display:'none'}}
     />   
     <TitleBar
     Title="Section Two"
     NavButtonDisplay="none"
     />
     <Box sx={{ padding: 3, marginTop: 3 }}>
      <Typography><b>Section 2.</b> Employer Review and Verification: Employers or their authorized representative must complete and sign Section 2 within three
business days after the employee's first day of employment, and must physically examine, or examine consistent with an alternative procedure
authorized by the Secretary of DHS, documentation from List A OR a combination of documentation from List B and List C. Enter any additional
documentation in the Additional Information box; see Instructions</Typography> 
         <br/>
         <Button sx={{color:'dodgerBlue'}} onClick={handleOpenDocumentModal} variant="primary">Show Document Abrievation Guide</Button>
         <Button sx={{color:'dodgerBlue'}} onClick={handleOpenIssuingAuthoritiesModal} variant="primary">Show Issuing Authorities Guide</Button>


    </Box>
    <Modal 
            open={imgModal}
            onClose={handleCloseModal}
            >
              <Box sx={style}>
              <img style={{width:'100%', height:'auto'}} src={img}/>
              </Box>
            </Modal>
           
    <Stepper sx={{overflowY:'scroll'}} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <form >
         <Box sx={{padding:2, paddingTop:5}}>
          {getStepContent(activeStep)}
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Grid sx={{padding:5}} container justifyContent="space-between">
            <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" color="primary">
              Back
            </Button>
            {activeStep !== steps.length - 1 ? (
              <Button onClick={handleNext} variant="contained" color="primary">
                Next
              </Button>
            ) : (
              <Button onClick={()=>{if (isStepTwoValid()) { handleSubmit()} else {
                const missingFields = activeStep === 0 ? getMissingFieldsStepOne() : getMissingFieldsStepTwo();
          const missingFieldsMessage = missingFields.map((field) => `- ${field}`).join('\n');
          const alertMessage = `Please make sure you have filled out all required fields before moving on:\n${missingFieldsMessage}`;
          setSnackbarMessage(alertMessage)
          setOpen(true)
              }}} variant="contained" color="primary">
                Submit
              </Button>
            )}
          </Grid>
          
        </form>
        <StartEverifyModal
        onSkip={handleSubmit}
      
        onSubmit={async () =>{ 
          setGettingData(true)
          setEverifyData(await checkDuplicates(sectionOne, formData, sectionOne.listType, token, setGettingData, i9Id, handleSubmit, 'pending', ()=>{setOpenEverify(false); setEverifyData({data:null}); setGettingData(false)}))}}
        onClose={()=>{setOpenEverify(false); setEverifyData([]); setGettingData(false)}}
        gettingData={gettingData}
        initialData={everifyData}
        open={openEverify}
        onSubmitEverify={()=>{setSendingSubmitting(true); handleSubmitEverify(everifyData, token, i9Id, handleSubmit,()=>setSendingSubmitting(false)); }}
        sendingSubmitting={sendingSubmitting}
        />

        <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="error">
    {snackbarMessage}
  </Alert>

      </Snackbar>
      <DocumentsModal show={documentModalShow} onHide={handleCloseDocumentModal} />
      <IssuingAuthoritiesModal show={issuingAuthoritiesModalShow} onHide={handleCloseIssuingAuthoritiesModal} />

    
    </Box>
        )
}

}