import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { NavBar } from '../components/NavBar';
import { Box, Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { TitleBar } from '../components/TitleBar';
import { formatDate } from '../utils/Global-Functions';
import { SearchBar } from '../components/SearchBar';
import ToolTipButton from '../components/TooltipButton';
import { CheckCircle, ContentCopy, Delete, DeleteOutline, Visibility } from '@mui/icons-material';
import { API } from '../controllers/API';
import { Button } from 'react-bootstrap';
import LoaderModal from '../components/LoaderModal';




export const OutstandingI9 = ()=>{
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [update, setUpdate] = useState(true)
    useEffect(() => {
        // Fetch user data from the server when the component mounts or when the user comes back to the page
        const fetchUserData = async () => {
          try {
            // Check if the token cookie exists before making the request
            const token = Cookies.get('token');
            if (!token) {
              console.error('Token not found. Redirecting to login.');
              navigate('/login');
              return;
            }
    
            const response = await axios.post(API+ '/auth/refresh-user', { token });
            const refreshedUser = response.data.user;
    
            // Update the user data in the state
            setUser(refreshedUser);
            console.log(refreshedUser)
            setLoading(false)
          } catch (error) {
            console.error('Error fetching user data:', error.message);
            navigate('/login'); // Redirect to the login page in case of an error
          }
        };
    
        fetchUserData();
      }, [navigate, update]);


      const copyLink = async (a,b)=>{
  
        let localLink =`http://localhost:3000/#/forms/section-one?a=${a}&b=${b}`
        let link = `http://app.i9helpdesk.com/#/forms/section-one/?a=${a}&b=${b}`
        await navigator.clipboard.writeText(link)
        window.alert('Copied Link to clipboard. Now you can use this link to personally email the employee their link to complete the section 1 of the form. ')
        }

        const deleteI9 = async (a,b, c)=>{
          const conf = window.prompt('Are you sure you would like to do this? It cannot be undone and will result in all of the I9 data being deleted. Please write a brief reason this I-9 is being deleted:')
          if(conf){
          let resp = await axios.post(`${API}/i9/delete-i9`,{id:a, i9Id:b, type:c, location: 'pending', reason: conf})

          if(resp.data.deleted){
            setUpdate(!update)
          } else{
            window.alert('Something went wrong. Please try again')
          }
        }
        }

        const markComplete = async (a,b)=>{
          const conf = window.confirm('Are you sure you would like to do this? It cannot be undone and will result in all of the I9 being filed into the I9 Library.')
          if(conf){
          let resp = await axios.post(`${API}/i9/mark-completed`,{id:a, data:b})

          if(resp.data.success){
            setUpdate(!update)
          } else{
            window.alert('Something went wrong. Please try again')
          }
        }
        }

      if(loading){
          return (<LoaderModal open={true}/>)
      } else {
        
    return(
        <Box sx={{ height: '100vh', background: '#F4F4F4' }}>
        <NavBar />
        <TitleBar
        NavigationTitle="Back"
        To={-1}
        Title={'Pending I9s'}
        DisplayButton={true}
        ButtonTitle={'Start New I9'}
        onClick={
          
          
          ()=>{

        const libLength = user.data.i9Library.length;
        const pendingLength = user.data.i9.length;
        const inactiveLength = user.data.inactive.length;
       const membership = user.membership;
  
    
        const total = libLength + pendingLength + inactiveLength;
        console.log(total)
       if(membership === 'basic' && total >= 50){
        window.alert('You have filled all of your I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
  
       }
       else if(membership === 'Pro' && total >= 200){
        window.alert('You have filled all of your I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
       } else {
        navigate('/forms/i9')       }
     
          }
           
        
        }
        />
        <SearchBar
        label="SEARCH BY EMPLOYEE NAME"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        />
      <hr/>  
        <Box sx={{ margin:3 }} component={Paper}>
          {/* Search bar */}
          

          <Table>
            <TableHead>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Date Added</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell align='center'>
                <b>Actions</b>
              </TableCell>
            </TableHead>
            <TableBody>
            {user.data.i9.filter(i =>
          (i.sectionOne?.firstName ? i.sectionOne?.firstName : i.firstName).toLowerCase().includes(searchInput.toLowerCase()) ||
          (i.sectionOne?.lastName ? i.sectionOne?.lastName : i.lastName).toLowerCase().includes(searchInput.toLowerCase())
        ).map(i=>{
                    return (
                        <TableRow>
                        <TableCell>{i.sectionOne?.firstName ? i.sectionOne?.firstName : i.firstName} {i.sectionOne?.lastName ? i.sectionOne?.lastName : i.lastName}</TableCell>
                        <TableCell>{formatDate(i.date)}</TableCell>
                        <TableCell sx={{color:i.type === 'upload' ? 'green' : i.sectionOne?.submitted && i.sectionTwo?.submitted ? 'green' : i.sectionOne?.submitted && i.sectionTwo?.submitted === false  ? 'orange': 'orange'}}><b>{i.type === 'upload' ? 'UPLOADED AND VIEWABLE' : i.sectionOne?.submitted && i.sectionTwo?.submitted ? 'Completed' : i.sectionOne?.submitted === true && !i.sectionTwo ? 'Section 1 Completed, Section 2 Ready': 'Sent to Employee'}</b></TableCell>
                        <TableCell align='center'>

                        
                         <ToolTipButton
                         Title="View Details"
                         onClick={()=>{navigate('/i9s/details',{ state:{a: user._id, b: i.id, c:'pending'}})}}
                         Icon={<Visibility/>}
                         sx={{display:i.type === 'upload' ? 'none' : i.sectionOne?.submitted ? 'inline': 'none' }}
                         />
                         <ToolTipButton
                         Title="Copy link to Section One Form"
                         Icon={<ContentCopy/>}
                         onClick={()=>copyLink(user._id, i.id)}
                         sx={{display:i.type === 'upload' ? 'none' : i.sectionOne?.submitted ? 'none': 'inline'}}
                         />
                         <ToolTipButton
                         Title="Delete"
                         onClick={()=>deleteI9(user._id, i.id, i.type)}
                         Icon={<Delete/>}
                         sx={{color:'tomato'}}
                         />
                         <ToolTipButton
                         Title="Mark I9 complete and file."
                         Icon={<CheckCircle/>}
                         sx={{display: i.sectionOne?.submitted && i.sectionTwo?.submitted ? 'inline': 'none',color:'green'}}
                         onClick={()=>markComplete(user._id, i)}
                         />
                         
                        </TableCell>
                        </TableRow>
                        
                    )
                })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )

}
}