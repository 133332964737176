import { Box, Typography } from "@mui/material";
import React from "react";



export const Dashboard = ()=>{



    return (
   
        <Typography>Dashboard...</Typography>

    )
}