// BulkUploadModal.js
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from '@mui/material';

import sampleCsv from '../assets/documents/sample-csv.csv';
import { Form } from 'react-bootstrap';

const BulkUploadModal = ({ open, onClose, updateHiringSites }) => {
  const [csvData, setCsvData] = useState(null);
useEffect(()=>{
console.log(csvData)
}, [csvData])
  const handleCsvUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        setCsvData(parseCsvContent(content));
      };

      reader.readAsText(file);
    }
  };

  const parseCsvContent = (content) => {
    const rows = content.split('\n');
    const headers = rows[0].split(',');
  
    const csvObjects = rows.slice(1).map((row) => {
      const values = row.split(',');
  
      // Check if the row has enough values before accessing them
      if (values.length === headers.length) {
        return headers.reduce((obj, header, index) => {
          obj[header.trim()] = values[index].trim();
          return obj;
        }, {});
      } else {
        // Log or handle the case where the row doesn't match the expected format
        console.error('CSV row does not match the expected format:', row);
        return null;
      }
    });
  
    // Filter out rows that didn't match the expected format
    return csvObjects.filter((row) => row !== null);
  };
  const handleProcessCsv = () => {
    if (csvData) {
      // Assuming formData is a state variable
      updateHiringSites([
        ...csvData.map((site) => ({
          streetAddress: site['Street Address'],
          suite: site['Suite/Other'],
          city: site['City'],
          state: site['State'],
          zipCode: site['ZIP Code'],
        })),
      ]);
      setCsvData(null);
      // Close the modal after processing
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Bulk Upload CSV</DialogTitle>
      <DialogContent>
        <Typography><b>Make sure your CSV column headers include: Street Address, Suite/Other, City, State, and Zip Code to help ensure a successful upload.</b></Typography>
      <Box sx={{textAlign:'end', marginTop:2}}>
        <a style={{marginTop:5, marginBottom:5}} href={sampleCsv} download="sample-csv.csv"><Button variant='outlined'>Download Template</Button></a>
      </Box>

        <Form.Control style={{marginTop:10}} type="file" accept=".csv" onChange={handleCsvUpload} />
        {csvData && (
          <>
            <Typography variant="h6">Uploaded CSV Data</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(csvData[0]).map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Object.values(row).map((value, columnIndex) => (
                      <TableCell key={columnIndex}>{value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProcessCsv}>Add Hiring Sites</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadModal;
