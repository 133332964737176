import React, { useEffect, useState, useRef } from "react";
import { AppBar, Box, Grid, Paper, Typography, Button, Modal, TextField } from "@mui/material";
import { NavBar } from "../../components/NavBar";
import { TitleBar } from "../../components/TitleBar";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { formatDate, statesArray } from "../../utils/Global-Functions";
import ToolTipButton from "../../components/TooltipButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SignatureCanvas from 'react-signature-canvas';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


export const SupplementA = ()=>{
    const [loading, setLoading] = useState(true)
    const [formModal, setFormModal] = useState(false)
    const [sectionOne, setSectionOne] = useState(false)
    let date = new Date();
    const preparerSigCanvas = useRef({});

    const [newSupplementA, setNewSupplementA] = useState(
        {
        lastName: '',
        firstName: '',
        middleInitial: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        signature: '',
        date: date,
        })
    let navigate = useNavigate();
    const {state} = useLocation();
    const {sOne, id, i9Id} = state;

    const handleOpenModal = (a)=>{

        setFormModal(true);
    }

    const handleCloseModal = (a)=>{

        setFormModal(false);
    }
    const handleChange = (e)=>{
        setNewSupplementA((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
          }));
    }

    const clearSignature = () => {
        preparerSigCanvas.current.clear();
        setNewSupplementA((prevData) => ({
          ...prevData,
          signature: '',
        }));
      };
    
      const saveSignature = () => {
        const signatureImage = preparerSigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        // Save or send the signatureImage as needed
        setNewSupplementA((prevData) => ({
          ...prevData,
          signature: signatureImage,
        }));
     
        console.log(newSupplementA);
      };


    useEffect(()=>{
        setSectionOne(sOne)
        console.log(sOne)
        setLoading(false)
    })
    if(loading){
        
    return (<p></p>)

} else {
    return(<Box sx={{ height: '100vh', background: '#F4F4F4' }}>
        <NavBar/>
        <TitleBar
        NavigationTitle="Back"
        Title={<Typography sx={{fontSize:24}} variant="button"><b>Supplement A - Preparer and/or Translator Certification</b></Typography>}
        To={-1}
        State={{a: id,b:i9Id}}
        />
        <Box sx={{padding:5}}>
        {sectionOne.supplementA.map(i=>{
            return (<Paper sx={{padding:5}}>
                  <Grid container>
            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>Last Name *</b></Typography>
              <Typography>{i.lastName}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>First Name *</b></Typography>
            <Typography>{i.firstName}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Middle Initial</b></Typography>
            <Typography>{i.middleInitial}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={12}>
              <Typography ><b>Address *</b></Typography>
              <Typography>{i.address}</Typography>
            </Grid>

            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>City *</b></Typography>
              <Typography>{i.city}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>State *</b></Typography>
            <Typography>{statesArray[i.state]}</Typography>
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Zip Code</b></Typography>
            <Typography>{i.zipCode}</Typography>
            </Grid>
            </Grid>
            <Grid sx={{marginTop:2}} container>
           
            <Grid sx={{marginTop:2}} item xs={12} sm={6}>
            <Typography ><b>Preparer / Translator Signature:</b></Typography>
                 <img style={{width:'100%'}} src={i.signature} alt="Base64 Image"/>

            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
              <Typography ><b>Date:</b></Typography>
              <Typography>{formatDate(i.date)}</Typography>

            </Grid>
            </Grid>
            </Paper>)
        })}
        

        <Modal 
            open={formModal}
            onClose={handleCloseModal}
            >
              <Box sx={style}>
              <Grid container spacing={2}>
              {/* Last Name, First Name, Middle Initial */}
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Last Name *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  name="lastName"
                  onChange={handleChange}
                  value={newSupplementA.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>First Name *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementA.firstName}
                  name="firstName"
                  onChange={handleChange}
                  />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Middle Initial *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementA.middleInitial}
                  name="middleInitial"
                  onChange={handleChange}
                />
              </Grid>
             
              </Grid>
         
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Today's Date:</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  disabled
                  fullWidth
                  value={formatDate(newSupplementA.date)}
                />
              </Grid>
    
              </Box>
            </Modal>
          
        </Box>
    </Box>)
}
}