import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import ToolTipButton from "../../components/TooltipButton";
import { useNavigate } from 'react-router-dom';
import { API } from "../../controllers/API";
import { formatDate } from "../../utils/Global-Functions";
import { SearchBar } from "../../components/SearchBar";

export const Users = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');

    const getUsers = async () => {
        try {
            const response = await axios.get(API + '/admin/users');
            const data = await response.data;
            console.log(data);
            setUsers(data); // Handle the fetched data as needed
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleSort = (field) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredUsers = users.filter(user => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
    });

    const sortedUsers = filteredUsers.slice().sort((a, b) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];

        if (valueA && valueB) {
            if (sortOrder === 'asc') {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        } else {
            return 0;
        }
    });

    useEffect(() => {
        getUsers();
    }, []);

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
            </Box>
        )
    } else {
        return (
            <Box sx={{ padding: 2 }}>
               <Box sx={{marginBottom:2}}>
                <SearchBar
                    label="Search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearch}
                    fullWidth
               
                />
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSort('lastName')}>Name</TableCell>
                                <TableCell onClick={() => handleSort('subscriptionDate')}>Date Subscribed</TableCell>
                                <TableCell onClick={() => handleSort('role')}>Role</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedUsers.map((user) => (
                                <TableRow key={user._id}>
                                    <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                    <TableCell>{formatDate(user.subscriptionDate)}</TableCell>
                                    <TableCell><b>{user.role.toUpperCase()}</b></TableCell>
                                    <TableCell>
                                        <ToolTipButton
                                            Title="Transactions"
                                            Icon={<AttachMoneyIcon />}
                                            onClick={() => { navigate('/admin/user/transactions', { state: { subscriptionId: user.stripeSubscriptionId } }) }}
                                        />
                                        <ToolTipButton
                                            Title="User Information"
                                            Icon={<InfoIcon />}
                                            onClick={() => { navigate('/admin/user/transactions', { state: { subscriptionId: user.stripeSubscriptionId } }) }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }
}
