import { AppBar, Box, Grid, Paper, Typography, Button, Modal, TextField, MenuItem, Select, Menu, Input } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TitleBar } from "../../components/TitleBar";
import { NavBar } from "../../components/NavBar";
import Cookies from 'js-cookie';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { formatDate, listADocuments, listBDocuments, listCDocuments, statesArray } from "../../utils/Global-Functions";
import ToolTipButton from "../../components/TooltipButton";
import { Close, EditOutlined, Refresh, ReplyTwoTone, Save } from "@mui/icons-material";
import { Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { API } from "../../controllers/API";
import LoaderModal from "../../components/LoaderModal";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



export const SectionTwoDetails = ()=>{
    const [loading, setLoading] = useState(true);
    const [sectionOne, setSectionOne] = useState(null)
    const [sectionTwo, setSectionTwo] = useState(null)
    const [editing, setEditing] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(null);
    const [dataSectionOneUpdate, setDataSectionOneUpdate] = useState(null);
    const [statusText, setStatusText] = useState('');
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState(null);
    let navigate = useNavigate();
    const {state} = useLocation();
    const {sOne, sTwo, id, i9Id, location, everifyData} = state
    const [imgModal, setImgModal] = useState(false)
    const [img, setImg] = useState(false)
    const token = Cookies.get('token');
    const isFile = (value) => {
     const isAFile = value instanceof File;

     console.log(isAFile);
     return isAFile;
    }

    const isNotAFile = (value) => {
    const isNotFile = !isFile(value);
    console.log(isNotFile)
    return isNotFile;
    }
    
    const handleOpenModal = (a)=>{
        console.log(a)
        setImg(a)
        setImgModal(true);
    }

    const handleCloseModal = (a)=>{
        setImg('')
        setImgModal(false);
    }

    useEffect(()=>{

      const checkData = async ()=>{
        
        const resp = await axios.post(API+'/i9/check-form',{id: id, i9Id: i9Id, location: location})

        const response = await axios.post(API+ '/auth/refresh-user', { token });
        const refreshedUser = response.data.user;

        // Update the user data in the state
    
         console.log(resp.data)
         setSectionOne(resp.data.sectionOne)
          setSectionTwo(resp.data.sectionTwo)
          setLoading(false)
          console.log(everifyData)
       
        if(resp.data.notFound){
          console.log('error')
          console.log(sOne, sTwo)
          
          // navigate('/errorPage'); 

      }
    }
   checkData()
     
    },[update])


    const handleInputChange = (section, fieldName, value) => {
      setDataUpdate((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [fieldName]: value,
        },
      }));
      console.log(dataUpdate);
    };

    const handleInputChangeListItems = (section, fieldName, value, list) => {
      setDataUpdate((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [list]: {
            ...prevData[section][list],
            [fieldName]:value
          },
        },
      }));
      console.log(dataUpdate);
    };


    async function uploadFile(a, folderText, fileLocation) {
   setLoading(true);
   setStatusText('Uploading File \n Do not close this page.')
      const file = a;
      const folder = folderText;
    
      if (!file || !folder) {
        alert('Please select a file and enter a folder name.');
        return;
      }
    
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('folder', folder);
    
      try {
        const response = await axios.post(API+'/file/upload', fileFormData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for file uploads
          },
        });
    
        // Ensure the response is successful (status code 2xx)
        if (response.data.ok) {
          // Extract the shared link directly as a string
          const sharedLink = await response.data.sharedLink;
    console.log(sharedLink)
          let modifiedLink = sharedLink.replace("www.dropbox.com", "dl.dropboxusercontent.com");
          const updateFileLinkResponse = await axios.post(API +'/i9/update-file-link', {id: id, i9Id:i9Id, location, fileLocation, modifiedLink });
          console.log(updateFileLinkResponse)
          if (updateFileLinkResponse.data.success) {
    
      setDataUpdate((prevData) => ({
        ...prevData,
        sectionOne: {
          ...prevData.sectionOne,
          [fileLocation]: modifiedLink
        }
      }));
      setLoading(false);
      setStatusText('')
    }
    
  } 

} catch {
    
}
    }


   const removeFile = async (fileLocation, link)=>{
    console.log(fileLocation, link, location)
    setStatusText('Removing File \n Do not close this page.')
    setLoading(true);
    try {
      const response = await fetch(API + '/file/delete-dropbox-file', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sharedLink: link,
       
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('File deleted successfully:', data);
      // Add New File
      const removeFileLinkResponse = await axios.post(API +'/i9/remove-file-link', {id: id, i9Id:i9Id, location, fileLocation });


  console.log(removeFileLinkResponse.data.success)
      // Ensure the response is successful (status code 2xx)
      if (removeFileLinkResponse.data.success) {
        // Extract the shared link directly as a string
       
  setDataUpdate((prevData) => ({
    ...prevData,
    sectionOne: {
      ...prevData.sectionOne,
      [fileLocation]: ''
    }
  }));
  setLoading(false);
  
      }
    } catch (error) {
      setLoading(false);
      setStatusText('')
      console.error('Error deleting file:', error);
    }
      
   }


   const compareAndLogChanges = (obj1, obj2) => {
    let changeLog = '';
  
    const parsePropertyName = (property) => {
      return property
        .split('.')
        .map((segment) =>
          segment.replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before capital letters
                 .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')  // Add space between consecutive capitals if followed by lowercase
                 .replace(/^./, (str) => str.toUpperCase()) // Capitalize first letter of each segment
        )
        .join(' ');
    };
  
    const compare = (key, value1, value2, path) => {
      const fullPath = path ? `${path}.${key}` : key;
      const readableProperty = parsePropertyName(fullPath);
  
      if (typeof value1 === 'object' && typeof value2 === 'object') {
        compareObjects(value1, value2, fullPath);
      } else if (value1 !== value2) {
        changeLog += `${readableProperty} was changed from "${value1}" to "${value2}".\n`;
      }
    };
  
    const compareObjects = (obj1, obj2, path = '') => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      keys1.forEach((key) => {
        if (keys2.includes(key)) {
          compare(key, obj1[key], obj2[key], path);
        }
      });
    };
  
    compareObjects(obj1, obj2);
  
    console.log(changeLog);
    return changeLog;
  };

  const handleUpdate = async ()=>{
    setLoading(true)
    setStatusText('Updating Section Two')

 let reason = window.prompt('This cannot be undone and will be logged. Please give a reason for the edits:')

 if(reason) {
  
    const changeLog = compareAndLogChanges(sectionTwo, dataUpdate.sectionTwo)

    const response = await axios.post(API +'/i9/update-section-two', {
      id: id, 
      i9Id: i9Id,
      formData: dataUpdate,
      changeLog:changeLog,
      reason:reason,
      location: location
    });

    if(dataUpdate.sectionOne.listType !== sectionOne.listType){
      const response = await axios.post(API +'/i9/update-section-one-two', {
        id: id, 
        i9Id: i9Id,
        formData: dataUpdate,
        changeLog:changeLog,
        reason:reason,
        location: location
      });
    }

    // Check the response and handle accordingly
    if (response.data.success) {
      // Form data saved successfully
      console.log('Form data updated successfully!');
      setLoading(false)
    setStatusText('')
      setUpdate(!update)
      // You can navigate to another page or perform additional actions here
    }


 } else {

 }

  }

   



 
    if(loading){
        return <LoaderModal title={<Typography variant="button" sx={{margin:1, color:'white'}}>{statusText}</Typography>} open={true}/>
    } else {
        

    return(
        <Box sx={{ height: '100%', background: '#F4F4F4' }}>
    <NavBar/>
    <TitleBar
        NavigationTitle="Back"
        Title={<Typography sx={{fontSize:24}} variant="button"><b>Section 2 Employer Review and Verification</b></Typography>}
        To={-1}
        State={{a: id,b:i9Id}}
        />
    <Box sx={{padding:5}}>
    <Paper sx={{padding:5}}>
    <Box sx={{textAlign:'end'}}>
                  {!editing && everifyData.data === null && ( <ToolTipButton Title={<Box><Typography><b>Edit Section 2</b></Typography><Typography><b>Note:</b> Editing is only allowed before <br/>E-Verfiy has been started. All edit actions will be saved to the account log.<br/>You will be asked to give a reason for the edit. <br/> If you edit the document files while in editing mode they will immediatly be saved.</Typography></Box>} Icon={<EditOutlined/>} onClick={()=>{setEditing(true); setDataUpdate({sectionOne, sectionTwo})}}/>)}
                     { editing  && (<><ToolTipButton onClick={()=>{setEditing(false); setDataUpdate(null)}} Title={<Typography>Cancel Editing</Typography>} Icon={<Close/>}/> <ToolTipButton Title={<Box><Typography><b>Save Section 2 Edits</b></Typography><Typography><b>Note:</b> Editing is only allowed before <br/>E-Verfiy has been started. All edit actions will be saved to the account log.<br/>You will be asked to give a reason for the edit.</Typography></Box>} Icon={<Save/>} onClick={()=>{handleUpdate(); setEditing(!editing)}}/></>)}

                    </Box>
     <Grid container>
   
            <Grid spacing={2} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>List Type and Documents</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={12}>
              <Typography ><b>List Type</b></Typography>
              {!editing && (
              <Typography>{sectionOne.listType === 'listA' ? 'List A' : 'List B/C'}</Typography>
            )}
              {editing && (
                <Select
                fullWidth
                value={dataUpdate.sectionOne.listType}  // Use `value` instead of `defaultValue`
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  handleInputChange('sectionOne','listType', e.target.value);
                   if(e.target.value === 'listA'){
                    setDataUpdate((prevData) => ({
                      ...prevData,
                      sectionOne: {
                        ...prevData.sectionOne,
                        listBDocumentFront: '',
                        listBDocumentBack:'',
                        listCDocumentFront:'',
                        listCDocumentBack:''
                      },
                      sectionTwo:{
                        ...prevData.sectionTwo,
                        listB:{documentTitle: '', issuingAuthority: '', documentNumber: '', expirationDate: ''},
                        listC:{documentTitle: '', issuingAuthority: '', documentNumber: '', expirationDate: ''}
                      }
                    }));
                   } else {
                    setDataUpdate((prevData) => ({
                      ...prevData,
                      sectionOne: {
                        ...prevData.sectionOne,
                        listADocumentFront: '',
                        listADocumentBack:'',
                        
                      },
                      sectionTwo:{
                        ...prevData.sectionTwo,
                        listA:{documentTitle: '', issuingAuthority: '', documentNumber: '', expirationDate: ''},
                      }
                    }));
                   }
                }}
              >
              
                  <MenuItem value='listA'>{'List A'}</MenuItem>
                  <MenuItem value='listBC'>{'List B/C'}</MenuItem>

              </Select>
              )}
            </Grid>

            <Grid sx={{marginTop:2, display: !editing ? sectionOne.listType === 'listA' ? 'inline-block' : 'none' : dataUpdate.sectionOne.listType === 'listA' ? 'inline-block' : 'none'}} item xs={12} sm={6}>
              <Typography ><b>List A Document Front:</b></Typography>
             
              {!editing && (
              <Button onClick={()=>handleOpenModal(sectionOne.listADocumentFront)}>View Document</Button>
            )}
              {editing && (
              <>
              {dataUpdate.sectionOne.listADocumentFront.toString().includes('dl.')  && (
                <>
                    <Button onClick={() => handleOpenModal(dataUpdate.sectionOne.listADocumentFront)}>View Document</Button>
                    
                    <ToolTipButton onClick={() => {
                      const conf = window.confirm('This will remove and delete the current document image permanently. Are you sure you would like to do this?')
                      if(conf) {removeFile('listADocumentFront', dataUpdate.sectionOne.listADocumentFront);}
                    }} Title={<Typography>Replace Image</Typography>} Icon={<Refresh />} />

                </>
            )}
            
            {/* Condition to show file input when the value is a file or is an empty string */}
            {(dataUpdate.sectionOne.listADocumentFront === '' || isFile(dataUpdate.sectionOne.listADocumentFront)) && (
                <InputGroup className="mb-3">
                    <Form.Control
                        type="file"
                        onChange={(e) => {
                            const file = e.target.files[0]; // Get the file from the event
                            if (file) {
                                setDataUpdate((prevData) => ({
                                    ...prevData,
                                    sectionOne: {
                                        ...prevData.sectionOne,
                                        listADocumentFront: file
                                    }
                                }));
                            }
                        }}
                    />
                    <Button disabled={!dataUpdate.sectionOne.listADocumentFront} onClick={()=>uploadFile(dataUpdate.sectionOne.listADocumentFront, `${id}/${i9Id}/listAFront`, 'listADocumentFront')} variant="contained">Upload</Button>
                </InputGroup>
            )}
             </>
              )}
            </Grid>

            <Grid sx={{marginTop:2, display: !editing ?  sectionOne.listType === 'listA' ? 'inline-block' : 'none': dataUpdate.sectionOne.listType === 'listA' ? 'inline-block' : 'none'}} item xs={12} sm={6}>
              <Typography ><b>List A Document Back:</b></Typography>
              {!editing && (
              <Button onClick={()=>handleOpenModal(sectionOne.listADocumentBack)}>View Document</Button>
            )}
              {editing && (
              <>
              {dataUpdate.sectionOne.listADocumentBack.toString().includes('dl.')  && (
                <>
                    <Button onClick={() => handleOpenModal(dataUpdate.sectionOne.listADocumentBack)}>View Document</Button>
                    
                    <ToolTipButton onClick={() => {
                      const conf = window.confirm('This will remove and delete the current document image permanently. Are you sure you would like to do this?')
                      if(conf) {removeFile('listADocumentBack', dataUpdate.sectionOne.listADocumentBack);}
                    }} Title={<Typography>Replace Image</Typography>} Icon={<Refresh />} />

                </>
            )}
            
            {/* Condition to show file input when the value is a file or is an empty string */}
            {(dataUpdate.sectionOne.listADocumentBack === '' || isFile(dataUpdate.sectionOne.listADocumentBack)) && (
                <InputGroup className="mb-3">
                    <Form.Control
                        type="file"
                        onChange={(e) => {
                            const file = e.target.files[0]; // Get the file from the event
                            if (file) {
                                setDataUpdate((prevData) => ({
                                    ...prevData,
                                    sectionOne: {
                                        ...prevData.sectionOne,
                                        listADocumentBack: file
                                    }
                                }));
                            }
                        }}
                    />
                    <Button disabled={!dataUpdate.sectionOne.listADocumentBack} onClick={()=>uploadFile(dataUpdate.sectionOne.listADocumentBack, `${id}/${i9Id}/listABack`, 'listADocumentBack')} variant="contained">Upload</Button>
                </InputGroup>
            )}
             </>
              )}
            </Grid>


            <Grid sx={{marginTop:2, display: !editing ? sectionOne.listType === 'listBC' ? 'inline-block' : 'none' : dataUpdate.sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List B Document Front:</b></Typography>
              {!editing && (
              <Button onClick={()=>handleOpenModal(sectionOne.listBDocumentFront)}>View Document</Button>
            )}
              {editing && (
              <>
              {dataUpdate.sectionOne.listBDocumentFront.toString().includes('dl.')  && (
                <>
                    <Button onClick={() => handleOpenModal(dataUpdate.sectionOne.listBDocumentFront)}>View Document</Button>
                    
                    <ToolTipButton onClick={() => {
                      const conf = window.confirm('This will remove and delete the current document image permanently. Are you sure you would like to do this?')
                      if(conf) {removeFile('listBDocumentFront', dataUpdate.sectionOne.listBDocumentFront);}
                    }} Title={<Typography>Replace Image</Typography>} Icon={<Refresh />} />

                </>
            )}
            
            {/* Condition to show file input when the value is a file or is an empty string */}
            {(dataUpdate.sectionOne.listBDocumentFront === '' || isFile(dataUpdate.sectionOne.listBDocumentFront)) && (
                <InputGroup className="mb-3">
                    <Form.Control
                        type="file"
                        onChange={(e) => {
                            const file = e.target.files[0]; // Get the file from the event
                            if (file) {
                                setDataUpdate((prevData) => ({
                                    ...prevData,
                                    sectionOne: {
                                        ...prevData.sectionOne,
                                        listBDocumentFront: file
                                    }
                                }));
                            }
                        }}
                    />
                    <Button disabled={!dataUpdate.sectionOne.listBDocumentFront} onClick={()=>uploadFile(dataUpdate.sectionOne.listBDocumentFront, `${id}/${i9Id}/listBFront`, 'listBDocumentFront')} variant="contained">Upload</Button>
                </InputGroup>
            )}
             </>
              )}
            </Grid>

            <Grid sx={{marginTop:2, display: !editing ? sectionOne.listType === 'listBC' ? 'inline-block' : 'none': dataUpdate.sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List B Document Back:</b></Typography>
              {!editing && (
              <Button onClick={()=>handleOpenModal(sectionOne.listBDocumentBack)}>View Document</Button>
            )}
              {editing && (
              <>
              {dataUpdate.sectionOne.listBDocumentBack.toString().includes('dl.')  && (
                <>
                    <Button onClick={() => handleOpenModal(dataUpdate.sectionOne.listBDocumentBack)}>View Document</Button>
                    
                    <ToolTipButton onClick={() => {
                      const conf = window.confirm('This will remove and delete the current document image permanently. Are you sure you would like to do this?')
                      if(conf) {removeFile('listBDocumentBack', dataUpdate.sectionOne.listBDocumentBack);}
                    }} Title={<Typography>Replace Image</Typography>} Icon={<Refresh />} />

                </>
            )}
            
            {/* Condition to show file input when the value is a file or is an empty string */}
            {(dataUpdate.sectionOne.listBDocumentBack === '' || isFile(dataUpdate.sectionOne.listBDocumentBack)) && (
                <InputGroup className="mb-3">
                    <Form.Control
                        type="file"
                        onChange={(e) => {
                            const file = e.target.files[0]; // Get the file from the event
                            if (file) {
                                setDataUpdate((prevData) => ({
                                    ...prevData,
                                    sectionOne: {
                                        ...prevData.sectionOne,
                                        listBDocumentBack: file
                                    }
                                }));
                            }
                        }}
                    />
                    <Button disabled={!dataUpdate.sectionOne.listBDocumentBack} onClick={()=>uploadFile(dataUpdate.sectionOne.listBDocumentBack, `${id}/${i9Id}/listBBack`, 'listBDocumentBack')} variant="contained">Upload</Button>
                </InputGroup>
            )}
             </>
              )}
            </Grid>

            <Grid sx={{marginTop:2, display: !editing ? sectionOne.listType === 'listBC' ? 'inline-block' : 'none': dataUpdate.sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List C Document Front:</b></Typography>
              {!editing && (
              <Button onClick={()=>handleOpenModal(sectionOne.listCDocumentFront)}>View Document</Button>
            )}
              {editing && (
              <>
              {dataUpdate.sectionOne.listCDocumentFront.toString().includes('dl.')  && (
                <>
                    <Button onClick={() => handleOpenModal(dataUpdate.sectionOne.listCDocumentFront)}>View Document</Button>
                    
                    <ToolTipButton onClick={() => {
                      const conf = window.confirm('This will remove and delete the current document image permanently. Are you sure you would like to do this?')
                      if(conf) {removeFile('listCDocumentFront', dataUpdate.sectionOne.listCDocumentFront);}
                    }} Title={<Typography>Replace Image</Typography>} Icon={<Refresh />} />

                </>
            )}
            
            {/* Condition to show file input when the value is a file or is an empty string */}
            {(dataUpdate.sectionOne.listCDocumentFront === '' || isFile(dataUpdate.sectionOne.listCDocumentFront)) && (
                <InputGroup className="mb-3">
                    <Form.Control
                        type="file"
                        onChange={(e) => {
                            const file = e.target.files[0]; // Get the file from the event
                            if (file) {
                                setDataUpdate((prevData) => ({
                                    ...prevData,
                                    sectionOne: {
                                        ...prevData.sectionOne,
                                        listCDocumentFront: file
                                    }
                                }));
                            }
                        }}
                    />
                    <Button disabled={!dataUpdate.sectionOne.listCDocumentFront} onClick={()=>uploadFile(dataUpdate.sectionOne.listCDocumentFront, `${id}/${i9Id}/listCFront`, 'listCDocumentFront')} variant="contained">Upload</Button>
                </InputGroup>
            )}
             </>
              )}
            </Grid>

            <Grid sx={{marginTop:2, display: !editing ? sectionOne.listType === 'listBC' ? 'inline-block' : 'none': dataUpdate.sectionOne.listType === 'listBC' ? 'inline-block' : 'none'}} item xs={12} sm={3}>
              <Typography ><b>List C Document Back:</b></Typography>
              {!editing && (
              <Button onClick={()=>handleOpenModal(sectionOne.listCDocumentBack)}>View Document</Button>
            )}
              {editing && (
              <>
              {dataUpdate.sectionOne.listCDocumentBack.toString().includes('dl.')  && (
                <>
                    <Button onClick={() => handleOpenModal(dataUpdate.sectionOne.listCDocumentBack)}>View Document</Button>
                    
                    <ToolTipButton onClick={() => {
                      const conf = window.confirm('This will remove and delete the current document image permanently. Are you sure you would like to do this?')
                      if(conf) {removeFile('listCDocumentBack', dataUpdate.sectionOne.listCDocumentBack);}
                    }} Title={<Typography>Replace Image</Typography>} Icon={<Refresh />} />

                </>
            )}
            
            {/* Condition to show file input when the value is a file or is an empty string */}
            {(dataUpdate.sectionOne.listCDocumentBack === '' || isFile(dataUpdate.sectionOne.listCDocumentBack)) && (
                <InputGroup className="mb-3">
                    <Form.Control
                        type="file"
                        onChange={(e) => {
                            const file = e.target.files[0]; // Get the file from the event
                            if (file) {
                                setDataUpdate((prevData) => ({
                                    ...prevData,
                                    sectionOne: {
                                        ...prevData.sectionOne,
                                        listCDocumentBack: file
                                    }
                                }));
                            }
                        }}
                    />
                    <Button disabled={!dataUpdate.sectionOne.listCDocumentBack} onClick={()=>uploadFile(dataUpdate.sectionOne.listCDocumentBack, `${id}/${i9Id}/listCBack`, 'listCDocumentBack')} variant="contained">Upload</Button>
                </InputGroup>
            )}
             </>
              )}
            </Grid>
            </Grid>
            </Grid>   

          <div style={{display: !editing ? sectionOne.listType === 'listA' ? 'block' : 'none' : dataUpdate.sectionOne.listType === 'listA' ? 'block' : 'none'}}>

                <Grid container spacing={2}>


                <Grid item xs={12} sm={6}>
                <Typography ><b>Document Title *</b></Typography>
                {!editing && (
                <Typography>{sectionTwo.listA.documentTitle.replace(/_/g, ' ')}</Typography>
              )}
              {editing && (
                <Select
                fullWidth
                value={dataUpdate.sectionTwo.listA.documentTitle}  // Use `value` instead of `defaultValue`
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => handleInputChangeListItems('sectionTwo','documentTitle', e.target.value, 'listA')}
              >
              
              {listADocuments.filter(doc => doc.citizenShipType.includes(dataUpdate.sectionOne.citizenshipStatus)).map((i,index)=>{
            return <MenuItem value={i.code}>{i.documentName}</MenuItem>
          })}

              </Select>
              )}
           
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography ><b>Issuing Authority *</b></Typography>
                {!editing && (
                <Typography>{sectionTwo.listA.issuingAuthority}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.sectionTwo.listA.issuingAuthority}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','issuingAuthority', e.target.value, 'listA')}
               />
              )}
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography ><b>Document Number *</b></Typography>
                {!editing && (
                <Typography>{sectionTwo.listA.documentNumber}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.sectionTwo.listA.documentNumber}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','documentNumber', e.target.value, 'listA')}
               />
              )}
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography><b>Expiration Date *</b></Typography>

                {!editing && (
                <Typography>{formatDate(sectionTwo.listA.expirationDate)}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                type="date"
                 value={dataUpdate.sectionTwo.listA.expirationDate}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','expirationDate', e.target.value, 'listA')}
               />
              )}
                </Grid>


                </Grid>
</div>   

<div style={{display: !editing ? sectionOne.listType === 'listA' ? 'none' : 'block': editing && dataUpdate.sectionOne.listType === 'listA' ? 'none' : 'block'}}>

<Grid container spacing={2}>
<Grid item xs={12} sm={12}>
<Typography sx={{fontSize:16}} variant="button"><b>List B Document</b></Typography>
</Grid>
<Grid item xs={12} sm={6}>
<Typography ><b>Document Title *</b></Typography>
{!editing && (
                <Typography>{sectionTwo.listB.documentTitle.replace(/_/g, ' ')}</Typography>
              )}
              {editing && (
                <Select
                fullWidth
                value={dataUpdate.sectionTwo.listB.documentTitle}  // Use `value` instead of `defaultValue`
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => handleInputChangeListItems('sectionTwo','documentTitle', e.target.value, 'listB')}
              >
              {listBDocuments.map((i,index)=>{
            return <MenuItem value={i.code}>{i.documentName}</MenuItem>
          })}

              </Select>
              )}
</Grid>
<Grid item xs={12} sm={6}>
<Typography ><b>Issuing Authority *</b></Typography>
{!editing && (
                <Typography>{sectionTwo.listB.issuingAuthority}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.sectionTwo.listB.issuingAuthority}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','issuingAuthority', e.target.value, 'listB')}
               />
              )}
</Grid>
<Grid item xs={12} sm={6}>
<Typography ><b>Document Number *</b></Typography>
      {!editing && (
                <Typography>{sectionTwo.listB.documentNumber}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.sectionTwo.listB.documentNumber}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','documentNumber', e.target.value, 'listB')}
               />
              )}

</Grid>
<Grid item xs={12} sm={6}>
<Typography><b>Expiration Date *</b></Typography>

  {!editing && (
        <Typography>{formatDate(sectionTwo.listB.expirationDate)}</Typography>
      )}
      {editing && (
        <TextField
        sx={{backgroundColor:'white'}}
        required
          fullWidth
          type="date"
          value={dataUpdate.sectionTwo.listB.expirationDate}
          onChange={(e) => handleInputChangeListItems('sectionTwo','expirationDate', e.target.value, 'listB')}
        />
      )}
</Grid>

<Grid item xs={12} sm={12}>
<Typography sx={{fontSize:16}} variant="button"><b>List C Document</b></Typography>
</Grid>
<Grid item xs={12} sm={6}>
<Typography ><b>Document Title *</b></Typography>
{!editing && (
                <Typography>{sectionTwo.listC.documentTitle.replace(/_/g, ' ')}</Typography>
              )}
              {editing && (
                <Select
                fullWidth
                value={dataUpdate.sectionTwo.listC.documentTitle}  // Use `value` instead of `defaultValue`
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => handleInputChangeListItems('sectionTwo','documentTitle', e.target.value, 'listC')}
              >
              {listCDocuments.filter(doc => doc.citizenShipType.includes(dataUpdate.sectionOne.citizenshipStatus)).map((i,index)=>{
            return <MenuItem value={i.code}>{i.documentName}</MenuItem>
          })}

              </Select>
              )}
</Grid>
<Grid item xs={12} sm={6}>
<Typography ><b>Issuing Authority *</b></Typography>
{!editing && (
                <Typography>{sectionTwo.listC.issuingAuthority}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.sectionTwo.listC.issuingAuthority}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','issuingAuthority', e.target.value, 'listC')}
               />
              )}
</Grid>
<Grid item xs={12} sm={6}>
<Typography ><b>Document Number *</b></Typography>
{!editing && (
                <Typography>{sectionTwo.listC.documentNumber}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.sectionTwo.listC.documentNumber}
                 onChange={(e) => handleInputChangeListItems('sectionTwo','documentNumber', e.target.value, 'listC')}
               />
              )}

</Grid>
<Grid item xs={12} sm={6}>
<Typography><b>Expiration Date *</b></Typography>
{!editing && (
        <Typography>{formatDate(sectionTwo.listC.expirationDate)}</Typography>
      )}
      {editing && (
        <TextField
        sx={{backgroundColor:'white'}}
        required
          fullWidth
          type="date"
          value={dataUpdate.sectionTwo.listC.expirationDate}
          onChange={(e) => handleInputChangeListItems('sectionTwo','expirationDate', e.target.value, 'listC')}
        />
      )}
</Grid>


</Grid>

</div>   
<Grid item xs={12}>
            <Typography sx={{marginTop:2, marginBottom:2}}><b>First Day of Employment (mm/dd/yyyy)</b></Typography>
            {!editing && (
            <Typography>{formatDate(sectionTwo.firstDayOfEmployment)}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                type="date"
                 value={dataUpdate.sectionTwo.firstDayOfEmployment}
                 onChange={(e) => handleInputChange('sectionTwo','firstDayOfEmployment', e.target.value)}
               />
              )}
        </Grid> 
<hr/>
<Grid container spacing={2}>
<Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Employer Information</b></Typography>
</Grid>



        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>Last Name of Employer or Authorized Representitive</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerLastName}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerLastName}
                 onChange={(e) => handleInputChange('sectionTwo','employerLastName', e.target.value)}
               />
              )}
        </Grid>
        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>First Name of Employer or Authorized Representitive</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerFirstName}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerFirstName}
                 onChange={(e) => handleInputChange('sectionTwo','employerFirstName', e.target.value)}
               />
              )}
        </Grid>
        <Grid item xs={12}>
        <Typography sx={{ marginBottom:2}}><b>Title of Employer or Authorized Representitive</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerTitle}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerTitle}
                 onChange={(e) => handleInputChange('sectionTwo','employerTitle', e.target.value)}
               />
              )}
        </Grid>
      
        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>Employer's Business or Organization Name</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerBusinessName}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerBusinessName}
                 onChange={(e) => handleInputChange('sectionTwo','employerBusinessName', e.target.value)}
               />
              )}
        </Grid>
        <Grid item xs={6}>
        <Typography sx={{ marginBottom:2}}><b>Employer's Business or Organization Address</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerAddress}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerAddress}
                 onChange={(e) => handleInputChange('sectionTwo','employerAddress', e.target.value)}
               />
              )}
        </Grid>
        <Grid item xs={4}>
        <Typography sx={{ marginBottom:2}}><b>City or Town</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerCity}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerCity}
                 onChange={(e) => handleInputChange('sectionTwo','employerCity', e.target.value)}
               />
              )}
        </Grid>
        <Grid item xs={4}>
        <Typography sx={{ marginBottom:2}}><b>State</b></Typography>
        {!editing && (
            <Typography>{statesArray[sectionTwo.employerState]}</Typography>
          )}
              {editing && (
                 <Select
                 fullWidth
                 value={dataUpdate.sectionTwo.employerState}  // Use `value` instead of `defaultValue`
                 sx={{ backgroundColor: 'white' }}
                 onChange={(e) => handleInputChange('sectionTwo','employerState', e.target.value)}
               >
                 {statesArray.map((state, index) => (
                   <MenuItem key={index} value={index}>{state}</MenuItem>
                 ))}
               </Select>
              )}
        </Grid>
        
        <Grid item xs={4}>
        <Typography sx={{ marginBottom:2}}><b>ZIP Code</b></Typography>
        {!editing && (
            <Typography>{sectionTwo.employerZipCode}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.sectionTwo.employerZipCode}
                 onChange={(e) => handleInputChange('sectionTwo','employerZipCode', e.target.value)}
               />
              )}

        </Grid>
</Grid>
    </Paper>
    </Box>
    <Modal 
            open={imgModal}
            onClose={handleCloseModal}
            >
              <Box sx={style}>
              <img style={{width:'100%', height:'auto'}} src={img}/>
              </Box>
            </Modal>
    </Box>
    )
    
}
}