// src/components/Home.js
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { TextField, Grid, ToggleButton, ToggleButtonGroup, CircularProgress  } from '@mui/material';
import uuid from 'react-uuid';
import axios from 'axios';
import logo from '../../assets/images/i9HelpDesk.png';
import { API } from '../../controllers/API';
import { ArrowBackIosNew } from '@mui/icons-material';
import { NavBar } from '../../components/NavBar';
import { TitleBar } from '../../components/TitleBar';
import { Spinner } from 'react-bootstrap';
import LoaderModal from '../../components/LoaderModal';

const I9Setup = () => {
    const [sent, setSent] = useState(false)
    const [sending, setSending] = useState(false)
    const date = new Date()
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        id: uuid(),
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        contactEmail:'',
        contactName:'',
        contactPhoneNumber:'',
        date: date,
        communicationMethod: '',
        sectionOne:{},
        sectionTwo:{},
        everify:{},
        type:'digital'
      });
      
      useEffect(()=>{
        const libLength = user?.data.i9Library.length;
        const pendingLength = user?.data.i9.length;
        const inactiveLength = user?.data.inactive.length;
       const membership = user?.membership;
  
    
        const total = libLength + pendingLength + inactiveLength;
        console.log(total)
        if(user !== null){
          
       if(membership === 'basic' && total >= 50) {
        window.alert('You have filled all I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
        navigate('/') 
       }
       else{
        if( membership === 'Pro' && total >= 200) {
          window.alert('You have filled all I-9 spots for your membership level. If you need to upgrade please reach out to support@i9helpdesk.com')
          navigate('/') 
         }
         else {
         setLoading(false)
        }
       } 

      }
      
      },[user])
    

  // Function to handle form field changes
  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setSending(true)
      // Do something with the form data, e.g., send it to a server
      console.log('Form Data:', formData);
  let companyInfo = {
    companyName: user.companyInformation.companyName,
    employerFirstName: user.firstName,
    employerLastName: user.lastName

  }
      const resp = await axios.post(API + '/i9/start-i9', { id: user._id, data: formData, companyInfo });
  
      if (resp.data.success) {
        window.alert('I-9 Setup was successful. Please reach out to the candidate and make sure they click the link to complete the I9 form.');
        navigate('/i9/pending')
        setSending(false)

      } else {
        setSending(false)
        window.alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      window.alert('An error occurred. Please try again.');
    }
  };
  let navigate = useNavigate();

  useEffect(() => {
    // Fetch user data from the server when the component mounts or when the user comes back to the page
    const fetchUserData = async () => {
      try {
        // Check if the token cookie exists before making the request
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        const response = await axios.post(API+'/auth/refresh-user', { token });
        const refreshedUser = response.data.user;

        // Update the user data in the state
        setUser(refreshedUser);
        console.log(refreshedUser)
        
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };

    fetchUserData();
  }, [navigate]);
if(loading){
  return (<LoaderModal open={true}/>)
} else {
  
  return (
    <div>
      <NavBar/>
      <TitleBar Title={'Start New I9'}
      To='/'
      NavigationTitle={'Back'}
      />
     
      <div style={{display:sent ? 'none': 'block'}}>
      <div style={{padding:20}}>
     

      <Grid sx={{padding:2}} container spacing={2}>
      <Grid item xs={12} sm={12}>
      <Typography sx={{fontSize:18, marginTop:10}} variant='button'>Candidate Information</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          sx={{backgroundColor:'white'}}
          label="First Name"
          variant="outlined"
          fullWidth
          value={formData.firstName}
          onChange={(e) => handleInputChange('firstName', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        sx={{backgroundColor:'white'}}
          label="Last Name"
          variant="outlined"
          fullWidth
          value={formData.lastName}
          onChange={(e) => handleInputChange('lastName', e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={12}>

      <Typography sx={{fontSize:18, marginTop:10}} variant='button'>Employer Contact Information</Typography>
     <Typography>Please input who the candidate should contact if they have any questions.</Typography>
     </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        sx={{backgroundColor:'white'}}
          label="Contact Name"
          variant="outlined"
          fullWidth
          value={formData.contactName}
          onChange={(e) => handleInputChange('contactName', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        sx={{backgroundColor:'white'}}
          label="Contact Email"
          variant="outlined"
          fullWidth
          value={formData.contactEmail}
          onChange={(e) => handleInputChange('contactEmail', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        sx={{backgroundColor:'white'}}
          label="Contact Phone Number"
          variant="outlined"
          fullWidth
          value={formData.contactPhoneNumber}
          onChange={(e) => handleInputChange('contactPhoneNumber', e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        {/* Toggle Button Section */}
        <div >
      <Typography variant='p'>How would you like to send the I-9 form for the candidate to fill out?</Typography>
      </div>
        <ToggleButtonGroup
          value={formData.communicationMethod}
          exclusive
          onChange={(e) => handleInputChange('communicationMethod', e.target.value)}
          aria-label="communication-method"
          style={{ marginTop: '16px' }}
        >
          <ToggleButton value="email" aria-label="email">
            Email
          </ToggleButton>
          <ToggleButton value="text" aria-label="text">
            Text
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid sx={{display:formData.communicationMethod === 'email' ? 'block':'none'}} item xs={12}>
      <Typography  sx={{fontSize:16, marginBottom:1}} variant='subtitle2'>Enter the Candidate's Email:</Typography>

        <TextField
       sx={{backgroundColor:'white'}}
          label="Email"
          variant="outlined"
          fullWidth
          value={formData.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
        />
      </Grid>
      <Grid sx={{display:formData.communicationMethod === 'text' ? 'block':'none'}} item xs={12}>        
      <Typography  sx={{fontSize:16, marginBottom:1}} variant='subtitle2'>Enter the Candidate's Phone Number:</Typography>

      <TextField
      sx={{backgroundColor:'white'}}
          label="Phone Number"
          variant="outlined"
          fullWidth
          value={formData.phoneNumber}
          onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
        />
      </Grid>
      
      {/* <Grid item xs={12}>
        <TextField
          label="Date"
          variant="outlined"
          fullWidth
          value={formData.date}
          onChange={(e) => handleInputChange('date', e.target.value)}
        />
      </Grid> */}
      <Grid sx={{display:formData.communicationMethod === '' ? 'none':'block'}} item xs={12}>
        <Button endIcon={<Spinner style={{display:sending ? 'block': 'none'}} size='sm' />} variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>

      </div>
      </div>
      <div style={{display:sent ? 'block': 'none', padding:20}}>
        <Typography><b>The I9 form has been sent!</b></Typography>
        <Link  to="/"> <Button sx={{marginRight:2}} variant="contained">Go Home</Button></Link>
     <Button onClick={()=>window.location.reload()} sx={{marginRight:2}} variant="contained">Start Over</Button>
      </div>
     
    </div>
  );
}
};

export default I9Setup;
