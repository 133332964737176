import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import React from "react";
import { NonEditableForm } from "./NonEditableForm";
import { ArrowBack } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";

const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        // top: 0,
        // bottom: 0,
        // left: 0,
        // right: 0,
        position: 'absolute',
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '50%',
        maxHeight: 85, // Adjust the maximum height as needed
        marginBottom: 5,
    }
  }

export const StartEverifyModal = ({open, onSkip, onSubmit, gettingData,initialData, onClose, onSubmitEverify, sendingSubmitting, showSkip})=>{
    return(
        <Modal
        open={open}
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin:10
        }}
    >

       

        <Box sx={styles.container}>
            { gettingData && (
        <Box>
        <Typography variant="button" sx={{fontSize:20}}><b>Getting E-Verify Data</b></Typography>
        <Box sx={{textAlign:'center'}}>
         <CircularProgress />
         </Box>
        </Box>
        )}
        { initialData.data === null && !gettingData &&(
            <Box >
            <Box ><Button onClick={onClose} ><ArrowBack/>BACK</Button></Box>
    
            <Typography variant="button" sx={{fontSize:20}}><b>Start Everify</b></Typography>
            
            <Typography>Would you like to start the E-Verify process for this employee?</Typography>
            <Typography sx={{display:showSkip ? 'none':'block'}} variant="subtitle2">You can start the E-Verify Process in the I9 details section if you choose not to do so right now.</Typography>
            <Box sx={{display:'inline', marginTop:2}}>
            <Button variant="contained" sx={{marginRight:1, marginTop:2, display: showSkip ?'none':'inline'}} onClick={onSkip}>Skip for now</Button>
            <Button sx={{marginTop:2}} variant="contained" onClick={onSubmit}>Start E-Verify</Button>
            </Box>
            </Box>
)}
           { initialData.data !== null&& !gettingData  &&(
            <Box >
            <Box ><Button onClick={onClose} ><ArrowBack/>BACK</Button></Box>
            <Typography variant="button" sx={{fontSize:20}}><b>Confirm E-Verify Details</b></Typography>
            <Typography>Please confirm the E-Verify case details. If something doesn't look right and then click the "<b>BACK</b>" button to fix the issue and then resubmit.</Typography>
           <Box sx={{margin:5}}>
           <NonEditableForm initialData={initialData}/>
           <Box
      
           >
          
            <Button onClick={onSubmitEverify} sx={{float:'right', marginTop:5}} variant="contained" endIcon={<Spinner style={{display: sendingSubmitting ? 'flex':'none'}} size='sm' />}>Create E-Verify Case</Button>
           </Box>
           </Box>
            </Box>
           )}
 



        </Box>
    </Modal>
    )
}