// src/components/Register.js
import React, { useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, Stepper, Step, StepLabel, Select, MenuItem, Divider, Box, CardContent, CardActions, IconButton, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/i9HelpDesk.png';
import '../assets/css/register-form.css';
import { Card, Form } from 'react-bootstrap';
import { HiringSiteForm } from '../components/HiringSiteForm';
import { Delete } from '@mui/icons-material';
import BulkUploadModal from '../components/BulkUploadModal';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { API } from '../controllers/API';
import MembershipSelection from '../components/MembershipSelection';
import LoaderModal from '../components/LoaderModal';
import states from '../utils/states.json'
import counties from '../utils/counties_list.json'

const steps = ['Membership','User Information', 'Employer Category', 'Company Information','Company Address', 'Hiring Sites', 'Review and Submit'];




const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isHiringSiteModalOpen, setHiringSiteModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [requiredFields, setRequiredFields] = useState([]);
const [formErrors, setFormErrors] = useState([])
  const [formData, setFormData] = useState({
    // Add other form data properties as needed
    firstName:'',
    lastName:'',
    email:'',
    password:'',
    confirmPassword:'',
    phoneNumber:'',
    employerCategory: 'None of these categories apply',
    federalContractorType: '',
    additionalCategories:'',
    role:'user',
    governmentType:'',
    companyInformation: {
      companyName: '',
      dba: '',
      eid: '',
      uei: '',
      dunsNo: '',
      totalNumberOfEmployees: '',
      naicsCode: '',
    },
    physicalAddress: {
      streetAddress: '',
      suite: '',
      city: '',
      state: '',
      zipCode: '',
      county: '',
    },
    mailingAddress: {
      streetAddress: '',
      suite: '',
      city: '',
      state: '',
      zipCode: '',
      county: '',
    },
    useMailingAddress: false,
    membership:'',
    hiringSites: [],
  });

  const filterCountiesByState = (counties, selectedState) => {
    let state = states.filter((i)=>{ return selectedState === i.code });
    return counties.filter(county => county.State === state.name);
  };



  let navigate = useNavigate();
  const handleRegister = async () => {
    // Add your registration logic here
    // If registration is successful, navigate to the Home screen
    alert('Registration Successful');
    navigate('login')
  };

  const validateMembershipSelection = () => {
    return formData.membership !== '';
  };

  const validateStep1 = () => {
    const missingFields = [];
  
    if (formData.firstName === '') {
      missingFields.push('First Name');
    }
    if (formData.lastName === '') {
      missingFields.push('Last Name');
    }
    if (formData.phoneNumber === '') {
      missingFields.push('Phone Number');
    }
    if (formData.email === '') {
      missingFields.push('Email Address');
    }
    if (formData.password === '') {
      missingFields.push('Password');
    }
    if (formData.confirmPassword === '') {
      missingFields.push('Confirm Password');
    }
  
    setRequiredFields(missingFields);
    return missingFields.length === 0;
  };

  const validateStep2 = () => {
    if (formData.employerCategory === 'Federal Contractor') {
      return formData.federalContractorType !== '';
    } else if (formData.employerCategory === 'Government') {
      return formData.governmentType !== '';
    }
    return true;
  };

  const validateStep3 = () => {
    const companyInfo = formData.companyInformation;
    return (
      companyInfo.companyName !== '' &&
      companyInfo.eid !== '' &&
      companyInfo.totalNumberOfEmployees !== '' &&
      companyInfo.naicsCode !== ''
    );
    
  };

  const validateStep4 = () => {
    const errors = {};
  
    // Validate physical address fields
    if (!formData.physicalAddress.streetAddress.trim()) {
      errors.physicalStreetAddress = 'Physical street address is required';
    }
    if (!formData.physicalAddress.city.trim()) {
      errors.physicalCity = 'Physical city is required';
    }
    if (!formData.physicalAddress.state.trim()) {
      errors.physicalState = 'Physical state is required';
    }
    if (!formData.physicalAddress.zipCode.trim()) {
      errors.physicalZipCode = 'Physical ZIP code is required';
    }
  
    // Validate mailing address fields if different from physical address
    if (formData.useMailingAddress) {
      if (!formData.mailingAddress.streetAddress.trim()) {
        errors.mailingStreetAddress = 'Mailing street address is required';
      }
      if (!formData.mailingAddress.city.trim()) {
        errors.mailingCity = 'Mailing city is required';
      }
      if (!formData.mailingAddress.state.trim()) {
        errors.mailingState = 'Mailing state is required';
      }
      if (!formData.mailingAddress.zipCode.trim()) {
        errors.mailingZipCode = 'Mailing ZIP code is required';
      }
    }

    console.log(errors)
  
    return errors;
  };
  

  const handleNext = () => {
    if (activeStep === 0) {
      if (validateMembershipSelection()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Open Snackbar to display message about selecting membership
        setSnackbarOpen(true);
      }
    } else if (activeStep === 1) {
      if (validateStep1()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Open Snackbar to display missing fields for Step 1
        setSnackbarOpen(true);
      }
    } else if (activeStep === 2) {
      if (validateStep2()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Open Snackbar to display missing fields for Step 2
        setSnackbarOpen(true);
      }
    } else if (activeStep === 3) {
      if (validateStep3()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Open Snackbar to display missing fields for Step 3
        setSnackbarOpen(true);
      }
    } else if (activeStep === 4) {
      // Step 4 validation
      const errors = validateStep4();
      // Check if there are any errors
      if (Object.keys(errors).length === 0) {
        // No errors, proceed to the next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Errors found, update state to store errors
        setFormErrors(errors);
        // Open Snackbar to display missing fields for Step 4
        setSnackbarOpen(true);
      }
    } else {
      // Proceed to the next step for other steps without validation
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmployerCategoryChange = (event) => {
    const category = event.target.value;

    // Update formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      employerCategory: category,
      
    }));
    console.log(formData)
  };

  const handleFederalContractorTypeChange = (event) => {
    const type = event.target.value;
 

    // Update formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      federalContractorType: type,
    }));
    console.log(formData)
  };

  const handleAdditionalCategoriesChange = (event) => {
    const type = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      additionalCategories: type,
    }));  
    console.log(formData)
  };

    const handleGovernmentTypeChange = (event) => {
      const governmentType = event.target.value;
  
      // Update formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        governmentType: governmentType,
      }));
      console.log(formData)
    };

    const updateCompanyInformation = (fieldName, value) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyInformation: {
          ...prevFormData.companyInformation,
          [fieldName]: value,
        },
      }));
      console.log(formData)
    };

    const updateAddressInformation = (addressType, fieldName, value) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [addressType]: {
          ...prevFormData[addressType],
          [fieldName]: value,
        },
      }));
      console.log(formData.physicalAddress)
    };

    const openHiringSiteModal = () => {
      setHiringSiteModalOpen(true);
    };
  
    const closeHiringSiteModal = () => {
      setHiringSiteModalOpen(false);
    };
  
    const handleAddHiringSite = (hiringSite) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        hiringSites: [...prevFormData.hiringSites, hiringSite],
      }));
    };

    const removeHiringSite = (index) => {
      setFormData((prevData) => ({
        ...prevData,
        hiringSites: prevData.hiringSites.filter((_, i) => i !== index),
      }));
    };

    const updateHiringSites = (newHiringSites) => {
      // Update formData.hiringSites with the new array of hiring sites
      setFormData((prevFormData) => ({
        ...prevFormData,
        hiringSites: [...prevFormData.hiringSites, ...newHiringSites],
      }));
      console.log(formData.hiringSites);
    };
    const submitRegistration = async (paymentMethodId) => {
      const registrationData = {
        ...formData,
        paymentMethodId: paymentMethodId,
      }; 
      console.log(registrationData)   
      try {
        setLoading(true); // Assuming you have a loading state to indicate the process is ongoing
        const response = await axios.post(API+'/auth/register', registrationData);
        
        if (response.status === 201) {
          alert('Registration Successful');
          navigate('/login'); 
        } else {
          // Handle server-side validation errors or other responses
          alert(`Registration failed: ${response.data.error || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Registration failed:', error);
        alert(`Registration failed: ${error.response.data.error || error.message}`);
      } finally {
        setLoading(false); // Reset loading state regardless of outcome
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
    
      setLoading(true);
    
      const cardElement = elements.getElement(CardElement);
    
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
    
      if (error) {
        console.log('[error]', error);
        setLoading(false);
      } else {
        console.log('[PaymentMethod]', paymentMethod);
        // Here you would also handle the rest of the registration logic
        // such as sending the formData and paymentMethod.id to your backend
        submitRegistration(paymentMethod.id); // Assuming this is where you finalize the registration process
      }
    };

    const handleMembershipChange = (event) => {
      setFormData((prevData) => ({
        ...prevData,
        membership: event.target.value,
      }));
    };
    
    
  


  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: 
      return (<> 
       <Typography>Please select your membership:</Typography>
      <MembershipSelection
      handleMembershipChange={handleMembershipChange}
      selectedMembership={formData.membership}
      /></>)
      case 1:
        return (<>
        <div>
      <Typography sx={{fontSize:24}} variant='button'><b>User Information</b></Typography>
      <Divider/>
      <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
              <Typography ><b>First Name</b></Typography>
      <TextField
      
      className='register-input'
      fullWidth
        
        variant="outlined"
        value={formData.firstName}
        onChange={(e) => {
          let value = e.target.value
          setFormData((prevFormData) => ({
          ...prevFormData,
          firstName: value,
        }));
      }}
      />
        <br/>
        </Grid>

        <Grid item xs={12} sm={6}>
              <Typography ><b>Last Name</b></Typography>
      <TextField
      className='register-input'
       fullWidth
    
        variant="outlined"
        value={formData.lastName}
        onChange={(e) => {
          let value = e.target.value
          setFormData((prevFormData) => ({
          ...prevFormData,
          lastName: value,
        }));
      }}
      />
        <br/>
        </Grid>
        <Grid item xs={12} sm={12}>
              <Typography ><b>Phone Number</b></Typography>
      <TextField
      className='register-input'
       fullWidth
        
        variant="outlined"
        value={formData.phoneNumber}
        onChange={(e) => {
          let value = e.target.value
          setFormData((prevFormData) => ({
          ...prevFormData,
          phoneNumber: value,
        }));
      }}
      />
        <br/>
        </Grid>
        <Grid item xs={12} sm={12}>
              <Typography ><b>Email Address</b></Typography>
      <TextField
      className='register-input'
       fullWidth
      
        variant="outlined"
        value={formData.email}
        onChange={(e) => {
          let value = e.target.value
          setFormData((prevFormData) => ({
          ...prevFormData,
          email: value,
        }));
      }}
      />
        <br/>
        </Grid>
        <Grid item xs={12} sm={12}>
              <Typography ><b>Password</b></Typography>
      <TextField
      className='register-input'
       fullWidth
   
        variant="outlined"
        type="password"
        value={formData.password}
        onChange={(e) => {
          let value = e.target.value
          setFormData((prevFormData) => ({
          ...prevFormData,
          password: value,
        }));
      }}
      />
        <br/>
        </Grid>
        <Grid item xs={12} sm={12}>
              <Typography ><b>Confirm Password</b></Typography>
      <TextField
      className='register-input'
       fullWidth
        variant="outlined"
        type="password"
        value={formData.confirmPassword}
        onChange={(e) => {
          let value = e.target.value
          setFormData((prevFormData) => ({
          ...prevFormData,
          confirmPassword: value,
        }));
      }}
      />
        <br/>
       
</Grid>
        </Grid>
      
    </div>
        </>); // Replace this with actual form fields for user information
      case 2:
        return (
          <Box>
            <Typography sx={{ fontSize: 24 }} variant="button">
              <b>Company Information</b>
            </Typography>
            <Divider />

            <Grid container>
              <Grid sx={{ marginTop: 2 }} xs={12} sm={12}>
                <Typography variant="h6">
                  <b>Employer Category</b>
                </Typography>

                <Typography>Which category best describes your company? (required)</Typography>

                <Select
                  sx={{ marginTop: 1 }}
                  fullWidth
                  value={formData.employerCategory}
                  onChange={handleEmployerCategoryChange}
                >
                  <MenuItem value="None of these categories apply">None of these categories apply</MenuItem>
                  <MenuItem value="Federal Contractor">Federal Contractor</MenuItem>
                  <MenuItem value="Government">Government</MenuItem>
                  <MenuItem value="Airport Operators">Airport Operators</MenuItem>
                </Select>

              </Grid>

              {formData.employerCategory === 'Federal Contractor' && (
                <>
                  <Grid sx={{ marginTop: 2 }} xs={12} sm={12}>
                    <Typography variant="h6">
                      <b>Federal Contractor Type</b>
                    </Typography>

                    <Typography>
                      What type of Federal Contractor is your company?(required)
                    </Typography>

                    <Select
                    sx={{ marginTop: 1 }}
                    fullWidth
                    value={formData.federalContractorType}
                    onChange={handleFederalContractorTypeChange}
                  >
                    <MenuItem value="Federal Contractor with FAR E-Verify Clause">
                      Federal Contractor with FAR E-Verify Clause
                    </MenuItem>
                    <MenuItem value="Federal Contractor without FAR E-Verify Clause">
                      Federal Contractor without FAR E-Verify Clause
                    </MenuItem>
                  </Select>

                  </Grid>

                  {formData.federalContractorType ===
                    'Federal Contractor with FAR E-Verify Clause' && (
                    <>
                      <Grid sx={{ marginTop: 2 }} xs={12} sm={12}>
                        <Typography variant="h6">
                          <b>Additional Categories</b>
                        </Typography>

                        <Typography>
                          Select additional categories that apply to your
                          company:(required)
                        </Typography>

                        <Select
                          sx={{ marginTop: 1 }}
                          fullWidth
                          value={formData.additionalCategories}
                          onChange={handleAdditionalCategoriesChange}
                        >
                          <MenuItem value="None of these categories apply">None of these categories apply</MenuItem>
                          <MenuItem value="Institution of Higher Education">Institution of Higher Education</MenuItem>
                          <MenuItem value="State or Local Government">State or Local Government</MenuItem>
                          <MenuItem value="Federally Recognized Indian Tribe">Federally Recognized Indian Tribe</MenuItem>
                        </Select>
                      </Grid>
                    </>
                  )}
                </>
              )}

            {formData.employerCategory === 'Government' && (
                <>
                  <Grid sx={{ marginTop: 2 }} xs={12} sm={12}>
                    <Typography variant="h6">
                      <b>Government Type</b>
                    </Typography>
                    <Typography>
                      What type of Government is your company? (required)
                    </Typography>
                    <Select
                      sx={{ marginTop: 1 }}
                      fullWidth
                      value={formData.governmentType || ''}
                      onChange={handleGovernmentTypeChange}
                    >
                      <MenuItem value="Federal Government">Federal Government</MenuItem>
                      <MenuItem value="State Government">State Government</MenuItem>
                      <MenuItem value="Local Government">Local Government</MenuItem>
                    </Select>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        );
        case 3:
          return (
            <>
              <Box>
                <Typography sx={{ fontSize: 24 }} variant="button">
                  <b>Company Information</b>
                </Typography>
                <Divider />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography>
                    <b>Company Name</b>
                  </Typography>
                  <TextField
                    className="register-input"
                    fullWidth
                    variant="outlined"
                    value={formData.companyInformation.companyName}
                    onChange={(e) => updateCompanyInformation('companyName', e.target.value)}
                  />
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={12}>
                  <Typography>
                    <b>Doing Business As (DBA) Name (if applicable)</b>
                  </Typography>
                  <TextField
                    className="register-input"
                    fullWidth
                    variant="outlined"
                    value={formData.companyInformation.dba}
                    onChange={(e) => updateCompanyInformation('dba', e.target.value)}
                  />
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Employer Identification Number (EIN)*</b>
                  </Typography>
                  <TextField
                    className="register-input"
                    fullWidth
                    variant="outlined"
                    value={formData.companyInformation.eid}
                    onChange={(e) => updateCompanyInformation('eid', e.target.value)}
                  />
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Unique Entity Identifier (UEI) (if applicable)</b>
                  </Typography>
                  <TextField
                    className="register-input"
                    fullWidth
                    variant="outlined"
                    value={formData.companyInformation.uei}
                    onChange={(e) => updateCompanyInformation('uei', e.target.value)}
                  />
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>DUNS Number (if applicable)</b>
                  </Typography>
                  <TextField
                    className="register-input"
                    fullWidth
                    variant="outlined"
                    value={formData.companyInformation.dunsNo}
                    onChange={(e) => updateCompanyInformation('dunsNo', e.target.value)}
                  />
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography><b>Total Number of Employees</b></Typography>
                  <Select
                    className="register-input"
                    fullWidth
                    value={formData.companyInformation.totalNumberOfEmployees}
                    onChange={(e) => updateCompanyInformation('totalNumberOfEmployees', e.target.value)}
                  >
                    <MenuItem>---</MenuItem>
                    <MenuItem value='1-4'>1-4</MenuItem>
                    <MenuItem value='5-9'>5-9</MenuItem>
                    <MenuItem value='10-19'>10-19</MenuItem>
                    <MenuItem value='20-99'>20-99</MenuItem>
                    <MenuItem value='100-499'>100-499</MenuItem>
                    <MenuItem value='500-999'>500-999</MenuItem>
                    <MenuItem value='1,000-2,499'>1,000-2,499</MenuItem>
                    <MenuItem value='5,000-9,999'>5,000-9,999</MenuItem>
                    <MenuItem value='5,000-9,999'>5,000-9,999</MenuItem>
                    <MenuItem value='10,000 and over'>10,000 and over</MenuItem>
                  </Select>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>3-Digit NAICS Code</b>
                  </Typography>
                  <TextField
                    className="register-input"
                    fullWidth
                    variant="outlined"
                    value={formData.companyInformation.naicsCode}
                    onChange={(e) => updateCompanyInformation('naicsCode', e.target.value)}
                  />
                  <Typography><a href='https://www.naics.com/search/' target='_blank'>Look up NAICS code</a></Typography>
                  <br />
                </Grid>
              </Grid>
            </>
          );

          case 4:
        return (
          <>
            <Box>
              <Typography sx={{ fontSize: 24 }} variant="button">
                <b>Company Addresses</b>
              </Typography>
              <Divider />
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={10}>
                <Typography variant="h6">
                  <b>Physical Address</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography>
                  <b>Street Address (Street Number and Name)</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.streetAddress}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'streetAddress',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>
                  <b>Suite/Other</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.suite}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'suite',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>City</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.city}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'city',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>State</b>
                </Typography>
                <Select
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.state}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'state',
                      e.target.value
                    )
                   
                  }
                >
                  <MenuItem value="---">---</MenuItem>
                  {states.map(o=>{
                    return <MenuItem value={o.code}>{o.code}</MenuItem>

                  })}
                </Select>
                {/* <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.state}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'state',
                      e.target.value
                    )
                  }
                /> */}
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ZIP Code</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.zipCode}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'zipCode',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>County</b>
                </Typography>
                <Select
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.county}
                  onChange={(e) =>{
                    updateAddressInformation(
                      'physicalAddress',
                      'county',
                      e.target.value
                    )
                  
                  }
                  }
                >
                  <MenuItem value="---">---</MenuItem>
                  {counties.filter(county => county.Code === formData.physicalAddress.state).map(o=>{
                    return <MenuItem value={o.County}>{o.County}</MenuItem>

                  })}
                </Select>

                {/* <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.physicalAddress.county}
                  onChange={(e) =>
                    updateAddressInformation(
                      'physicalAddress',
                      'county',
                      e.target.value
                    )
                  }
                /> */}
                <br />
              </Grid>


              {/* ... (similarly update other fields in physical address) */}

              <Grid item xs={12} sm={12}>
                <Form.Check
                  label="Mailing Address is different from the Physical Address"
                  onChange={() =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      useMailingAddress: !prevFormData.useMailingAddress,
                    }))
                  }
                />
                <br />
              </Grid>

              {formData.useMailingAddress && (
                <>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6">
                      <b>Mailing Address</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography>
                      <b>Street Address (Street Number and Name)</b>
                    </Typography>
                    <TextField
                      className="register-input"
                      fullWidth
                      variant="outlined"
                      value={formData.mailingAddress.streetAddress}
                      onChange={(e) =>
                        updateAddressInformation(
                          'mailingAddress',
                          'streetAddress',
                          e.target.value
                        )
                      }
                    />
                    <br />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                <Typography>
                  <b>Suite/Other</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.mailingAddress.suite}
                  onChange={(e) =>
                    updateAddressInformation(
                      'mailingAddress',
                      'suite',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>City</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.mailingAddress.city}
                  onChange={(e) =>
                    updateAddressInformation(
                      'mailingAddress',
                      'city',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>State</b>
                </Typography>
                <Select
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.mailingAddress.state}
                  onChange={(e) =>
                    updateAddressInformation(
                      'mailingAddress',
                      'state',
                      e.target.value
                    )
                   
                  }
                >
                  <MenuItem value="---">---</MenuItem>
                  {states.map(o=>{
                    return <MenuItem value={o.code}>{o.code}</MenuItem>

                  })}
                </Select>
                
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ZIP Code</b>
                </Typography>
                <TextField
                  className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.mailingAddress.zipCode}
                  onChange={(e) =>
                    updateAddressInformation(
                      'mailingAddress',
                      'zipCode',
                      e.target.value
                    )
                  }
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>County</b>
                </Typography>
                <Select
                  // className="register-input"
                  fullWidth
                  variant="outlined"
                  value={formData.mailingAddress.county}
                  onChange={(e) =>{
                    updateAddressInformation(
                      'mailingAddress',
                      'county',
                      e.target.value
                    )
                  
                  }
                  }
                >
                  <MenuItem value="---">---</MenuItem>
                  {counties.filter(county => county.Code === formData.physicalAddress.state).map(o=>{
                    return <MenuItem value={o.County}>{o.County}</MenuItem>

                  })}
                </Select>
                
                <br />
              </Grid>
                  {/* ... (similarly update other fields in mailing address) */}
                </>
              )}
            </Grid>
          </>
        );


        case 5 : 
        return (
          <>
            <Box>
            
              <Box sx={{display:'inline' }}>
              <Typography sx={{ fontSize: 24 }} variant="button">
                <b>Hiring Sites</b>
              </Typography>
                <Button style={{float:'right' , marginLeft:5}} onClick={()=>setBulkUploadModalOpen(true)} variant="contained" sx={{ marginRight: 1 }}>
                  Upload CSV
                </Button>
                <Button style={{float:'right'}}  variant="contained" onClick={openHiringSiteModal}>
                  Add Single Hiring Site
                </Button>
              </Box>
              <Typography><a href='https://www.e-verify.gov/quick-reference-guide-for-e-verify-enrollment-10-introduction/14-verification-locations-and-hiring' target='_blank'>What is a hiring site?</a></Typography>

              <Divider />
      
              
      
             <Box sx={{marginTop:4}}>
             <Grid container spacing={2}>
             <Grid item key={'index'} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography variant="button">
                        {formData.physicalAddress.streetAddress}, {formData.physicalAddress.suite}
                      </Typography>
                      <br/>
                      <Typography variant="button">{formData.physicalAddress.city}, {formData.physicalAddress.state} {formData.physicalAddress.zipCode}</Typography>
                    </CardContent>
                    <CardActions>
                 
                    </CardActions>
                  </Card>
                </Grid>
             {formData.hiringSites.map((site, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography variant="button">
                        {site.streetAddress}, {site.suite}
                      </Typography>
                      <br/>
                      <Typography variant="button">{site.city}, {site.state} {site.zipCode}</Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton
                      
                        aria-label="delete"
                        onClick={() => removeHiringSite(index)}
                      >
                        <Delete htmlColor='red' />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              </Grid>
             </Box>
            </Box>
      
            {/* Hiring Site Form Modal */}
            <HiringSiteForm
              isOpen={isHiringSiteModalOpen}
              handleClose={closeHiringSiteModal}
              addHiringSite={handleAddHiringSite}
            />
             <BulkUploadModal
          open={bulkUploadModalOpen}
          onClose={() => setBulkUploadModalOpen(false)}
          updateHiringSites={updateHiringSites}
        />
          </>
        );

        case 6:
  return (
    <Box>
      <Typography sx={{fontSize:22}} variant='button'><b>User Information</b></Typography>
     <hr/>
      <Grid container>
       <Grid sx={{marginBottom: 5}} item sm={6} xs={12}>
        <Typography><b>First Name</b></Typography>
        <Typography>{formData.firstName}</Typography>
       </Grid>

       <Grid sx={{marginBottom: 5}} item sm={6} xs={12}>
        <Typography><b>Last Name</b></Typography>
        <Typography>{formData.lastName}</Typography>
       </Grid>

       <Grid sx={{marginBottom: 5}} item sm={6} xs={12}>
        <Typography><b>Email</b></Typography>
        <Typography>{formData.email}</Typography>
       </Grid>
    

      </Grid>
      <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Company Information</b></Typography>
     <hr/>
     <Grid container>
       <Grid  item sm={12} xs={12}>
        <Typography><b>Employer Category</b></Typography>
        <Typography>{formData.employerCategory}</Typography>
        <br/>
       </Grid>

       <Grid  item sm={12} xs={12}>
        <Typography><b>Company Name</b></Typography>
        <Typography>{formData.companyInformation.companyName}</Typography>
        <br/>
       </Grid>

       <Grid item xs={12} sm={12}>
                  <Typography>
                    <b>Doing Business As(DBA) Name</b>
                  </Typography>
                  <Typography>{formData.companyInformation.dba}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Employer Identification Number (EIN)</b>
                  </Typography>
                  <Typography>{formData.companyInformation.eid}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Unique Entity Identifier (UEI)</b>
                  </Typography>
                  <Typography>{formData.companyInformation.uei}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>DUNS Number</b>
                  </Typography>
                  <Typography>{formData.companyInformation.dunsNo}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography><b>Total Number of Employees</b></Typography>
                  <Typography>{formData.companyInformation.totalNumberOfEmployees}</Typography>
                  <br />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>3-Digit NAICS Code</b>
                  </Typography>
                 <Typography>{formData.companyInformation.naicsCode}</Typography>
                  <br />
                </Grid>
    

      </Grid>
      <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Company Addresses</b></Typography>
     <hr/>
      <Grid container>
      <Grid item xs={12} sm={10}>
                <Typography variant="h6">
                  <b>Physical Address</b>
                </Typography>
                
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography>
                  <b>Street Address (Street Number and Name)</b>
                </Typography>
                <Typography>{formData.physicalAddress.streetAddress}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography>
                <b>Suite/Other</b>
                </Typography>
                <Typography>{formData.physicalAddress.suite}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>City</b>
                </Typography>
                <Typography>{formData.physicalAddress.city}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>State</b>
                </Typography>
                <Typography>{formData.physicalAddress.state}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ZIP Code</b>
                </Typography>
                <Typography>{formData.physicalAddress.zipCode}</Typography>
                <br />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>County</b>
                </Typography>
                <Typography>{formData.physicalAddress.county}</Typography>
                <br />
              </Grid>
      </Grid>

        {formData.useMailingAddress && (
       <Grid container>
          <Grid item xs={12} sm={10}>
          <Typography variant="h6">
            <b>Mailing Address</b>
          </Typography>
          <Divider/>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography>
            <b>Street Address (Street Number and Name)</b>
          </Typography>
          <Typography>{formData.mailingAddress.streetAddress}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography>
          <b>Suite/Other</b>
          </Typography>
          <Typography>{formData.mailingAddress.suite}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            <b>City</b>
          </Typography>
          <Typography>{formData.mailingAddress.city}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            <b>State</b>
          </Typography>
          <Typography>{formData.mailingAddress.state}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            <b>ZIP Code</b>
          </Typography>
          <Typography>{formData.mailingAddress.zipCode}</Typography>
          <br />
        </Grid>

        <Grid item xs={12} sm={6}>
                <Typography>
                  <b>County</b>
                </Typography>
                <Typography>{formData.mailingAddress.county}</Typography>
                <br />
              </Grid>
</Grid>
        )}
      <hr/>
      <Typography sx={{fontSize:22}} variant='button'><b>Hiring Sites</b></Typography>
     <hr/>
     <Grid container>
     <Grid item xs={12} sm={6}>
                <Typography>
                  <b>Total Number of Hiring Sites</b>
                </Typography>
                <Typography>{formData.hiringSites.length + 1}</Typography>
                <br />
              </Grid>
    <Grid xs={12} sm={12}>

    
        <Divider sx={{ my: 2 }} />
    <Typography variant="h6" component="h3">
      Payment Information
    </Typography>
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        p: 2,
        borderRadius: 1,
        mt: 2,
        mb: 3,
      }}
    >
      <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' }, }, invalid: { color: '#9e2146' }, } }} />
    </Box>
    <Typography><b>Total Due:</b> {formData.membership === 'Basic'? '$299' : formData.membership === 'Pro'? '$499': formData.membership === 'Enterprise'? '$999': '$0.00' }</Typography>

        </Grid>
     </Grid>
  </Box>
  
  );

      
      
    }
  };

  return (
    <div style={{  backgroundColor:'#102372', height:'100vh'}}>

    <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center', backgroundColor:'#102372', }}>
       <Box sx={{paddingBottom:10}}>
        <div style={{padding:10, marginRight:10, paddingTop:'5%', textAlign:'center', }}>
          <img width="300" style={{padding:5}} src={logo} alt="Logo" />
          
        </div>
        <Box sx={{textAlign:'center'}}> <Typography sx={{color:'white', fontSize:30}} variant='button'><b>REGISTER</b></Typography></Box>
        <Stepper sx={{padding:5, color:'white'}} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step sx={{color:'white'}} key={label}>
                <StepLabel classes={{
                  iconContainer: 'pink'
                }} sx={{color:"white"}}><Typography variant='subtitle2' sx={{color:'white'}}>{label}</Typography></StepLabel>
              </Step>
            ))}
          </Stepper>
      <Paper sx={{  padding: 2, maxWidth: 750, margin: 'auto', width: '100%', marginTop:5, overflow:'scroll' }}>
        <Grid sx={{display:'block'}}  container>
         <Typography>{getStepContent(activeStep)}</Typography>
           

           <br></br>
      
          <div style={{marginTop:'10px !important'}}>
         
              <div >
                <div >
                <Button  disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button variant="contained" onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext }>
                  {activeStep === steps.length - 1 ? 'Register' : 'Next'}
                </Button>
                </div>
              </div>
      
          </div>
        
      </Grid>
      
      </Paper>
      </Box>
      <Snackbar
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  open={snackbarOpen}
  autoHideDuration={6000}
  onClose={() => setSnackbarOpen(false)}
>
  <Alert onClose={() => setSnackbarOpen(false)} severity="error">
    {activeStep === 0 && 'Please select a membership'}
    {activeStep === 1 && `Please fill in the following fields: ${requiredFields.join(', ')}`}
    {activeStep === 2 && 'Please fill in all required fields for Step 3'}
    {activeStep === 3 && 'Please fill in all required fields for Step 4'}
    {activeStep === 4 && 'Please fill in all required fields for Step 5'}
  </Alert>
</Snackbar>
      <LoaderModal title={<Typography variant='button' sx={{fontSize:24, marginBottom: 2, color: 'white'}}><b>Setting Up Account...</b></Typography>} open={loading}/>
    </div>
    </div>
  );
};

export default Register;
