import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  Modal,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';

export const HiringSiteForm = ({ isOpen, handleClose, addHiringSite }) => {
  const [hiringSiteData, setHiringSiteData] = useState({
    // Define the fields for hiring site here
    // For example:
    streetAddress: '',
    suite:'',
    city: '',
    state: '',
    zipCode: '',
  });

  const handleInputChange = (field, value) => {
    setHiringSiteData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddHiringSite = () => {
    // Validate the form fields if needed
    // Add the hiring site to the formData array
    addHiringSite(hiringSiteData);

    // Clear the form fields and close the modal
    setHiringSiteData({
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
    });
    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          minWidth: 300,
        }}
      >
        {/* Add hiring site form fields */}
        <Typography variant="h6">Add Hiring Site</Typography>
        <Grid container spacing={2}>
          <Grid item xs={9}>
          <Typography>
                  <b>Street Address</b>
                </Typography>
            <TextField
              
              fullWidth
              value={hiringSiteData.streetAddress}
              onChange={(e) =>
                handleInputChange('streetAddress', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
          <Typography>
                  <b>Suite</b>
                </Typography>
            <TextField
              fullWidth
              value={hiringSiteData.suite}
              onChange={(e) =>
                handleInputChange('suite', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography>
                  <b>City</b>
                </Typography>
            <TextField
              fullWidth
              value={hiringSiteData.city}
              onChange={(e) => handleInputChange('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography>
                  <b>State</b>
                </Typography>
                <TextField
              fullWidth
              value={hiringSiteData.state}
              onChange={(e) => handleInputChange('state', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
          <Typography>
                  <b>Zip Code</b>
                </Typography>
            <TextField
              fullWidth
              value={hiringSiteData.zipCode}
              onChange={(e) => handleInputChange('zipCode', e.target.value)}
            />
          </Grid>
        </Grid>
        {/* Add hiring site button */}
        <div style={{marginTop:10, textAlign:'end'}}>
        <Button sx={{marginRight:2}}  onClick={handleAddHiringSite}>
          Add Hiring Site
        </Button>
        <Button  onClick={handleClose}>
          Close
        </Button>
        </div>
      </Box>
    </Modal>
  );
};