import { AppBar, Box, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
import logo from '../assets/images/i9HelpDesk.png';



export const SentSectionOne = ()=>{
    return (
        <Box>
             <AppBar position="static">
        <Toolbar sx={{ background: '#102372', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ marginRight: '16px', width: '200px' }} />
          </div>
        </Toolbar>
      </AppBar>

      <Box sx={{padding:5}}>
        <Paper sx={{padding:5, textAlign:'center'}}>
          <Typography variant="h5"><b>Thank you for successfully completing Section One of your Form I-9!</b></Typography>
          <Box sx={{marginTop:2}}></Box>
        <Typography sx={{fontSize:18}} variant="p">Your employer will contact you to arrange for the verification process required in Section Two.</Typography>
        <Box sx={{marginTop:2}}></Box>
        <Typography sx={{fontSize:18}} variant="p">Should you have any questions or require further assistance, please do not hesitate to contact your employer directly.</Typography>
        <Box sx={{marginTop:2}}></Box>
        <Typography sx={{fontSize:18}} variant="p">You may now safely close this page.</Typography>

        </Paper>
      </Box>
        </Box>
    )
}