const {PDFDocument} = require('pdf-lib')

export function formatSubDate(inputDate) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
      // Add one year to the date
      date.setFullYear(date.getFullYear() + 1);

      // Return the formatted date
      return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }
}

export function formatWrittenDate(inputDate) {
  // Create a Date object from the input date string
  const date = new Date(inputDate);

  // Array of month names
  const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

  // Extract month, day, and year from the Date object
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Concatenate the formatted date string
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

export function formatDate(inputDate) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }
}

export function formatSSN(ssn) {
  // Remove any non-numeric characters from the input
  const numericSSN = ssn.replace(/\D/g, '');

  // Check if the numericSSN has a valid length
  if (numericSSN.length !== 9) {
    // Handle invalid SSN length
    console.error('Invalid SSN length');
    return numericSSN;
  }

  // Format the SSN with dashes
  const formattedSSN = `${numericSSN.substr(0, 3)}-${numericSSN.substr(3, 2)}-${numericSSN.substr(5)}`;

  return formattedSSN;
}

export const statesArray =  ['---', 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'CAN', 'MEX']
 
export const citizenshipStatusArray = [
  {
    name: 'A citizen of the United States',
    code:'US_CITIZEN'
  },
  {
    name: 'Noncitizen National of the United States',
    code:'NONCITIZEN'
  },
  {
    name: 'Lawful Permanent Resident (Enter USCIS or A-Number.)',
    code:'LAWFUL_PERMANENT_RESIDENT'
  },
  {
    name: 'Alien Authorized to Work (A-Number)',
    code:'ALIEN_AUTHORIZED_TO_WORK'
  },
  {
    name: 'Noncitizen Authorized to Work (A-Number)',
    code:'NONCITIZEN_AUTHORIZED_TO_WORK'
  }
]
export const listADocuments =
 [
  {
    documentName:'---',
    sectionTwoName:'',
    code:'',
    citizenShipType:['US_CITIZEN','NONCITIZEN','LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK']
  },
  {
   documentName: 'U.S. Passport or U.S. Passport Card',
   sectionTwoName:'U.S. Passport or U.S. Passport Card',
   code:'US_PASSPORT',
   citizenShipType:['US_CITIZEN','NONCITIZEN']
  },
  {
    documentName:'Permanent Resident Card or Alien Registration Receipt Card (Form I-551)',
    sectionTwoName:'Form I-551',
    code:'FORM_I551',
    citizenShipType:['LAWFUL_PERMANENT_RESIDENT']
  },
  {
    documentName:'Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation on a machine-readable immigrant visa',
    sectionTwoName:'Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation',
    code:'FOREIGN_PASSPORT_WITH_I551_STAMP',
    citizenShipType:['LAWFUL_PERMANENT_RESIDENT']
  },
  {
    documentName:'Arrival/Departure Record (Form I-94) with temporary I-551 stamp or refugee admission stamp (receipt)',
    sectionTwoName:'Arrival/Departure Record (Form I-94) with temporary I-551 stamp or refugee admission stamp (receipt)',
    code:'FORM_I94_RECEIPT',
    citizenShipType:['LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK', 'ALIEN_AUTHORIZED_TO_WORK']
  },
  {
    documentName:'Employment Authorization Document that contains a photograph (Form I-766)',
    sectionTwoName:'Form I-766',
    code:'FORM_I766',
    citizenShipType:['NONCITIZEN_AUTHORIZED_TO_WORK', 'ALIEN_AUTHORIZED_TO_WORK']
  },
  {
    documentName:"Foreign passport with Arrival/Departure Record (Form I-94)",
    sectionTwoName:'Form I-94',
    code:'FOREIGN_PASSPORT_WITH_FORM_I94',
    citizenShipType:['NONCITIZEN_AUTHORIZED_TO_WORK', 'ALIEN_AUTHORIZED_TO_WORK']
  }
]

export const listBDocuments = 
[
  {
    documentName:'---',
    sectionTwoName:'',
    code:'',
    citizenShipType:['US_CITIZEN','NONCITIZEN','LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK']
  },
  {
    documentName:"Driver's License",
    sectionTwoName:"Driver's license or ID card issued by a U.S. state or outlying possession",
    code:'DRIVERS_LICENSE',
  },
  {
    documentName:'ID card issued by a U.S. federal, state or local government agency',
    sectionTwoName:'Government ID Card',
    code:'GOVERNMENT_ID_CARD',
  },
  {
    documentName:'School ID card',
    sectionTwoName:'School ID Card',
    code:'SCHOOL_ID_CARD',
  },
  {
    documentName:"Voter's Registration Card",
    sectionTwoName:"Voter's Registration Card",
    code:'VOTER_REGISTRATION_CARD',
  },
  {
    documentName:'U.S. Military card or Draft Record',
    sectionTwoName:'U.S. Military card or Draft Record',
    code:'US_MILITARY_CARD',
  },
  {
    documentName:"Military dependent's ID card",
    sectionTwoName:"Military dependent's ID card",
    code:'MILITARY_DEPENDENT_ID_CARD',
  },
  {
    documentName:'U.S. Coast Guard Merchant Mariner Card',
    sectionTwoName:'U.S. Coast Guard Merchant Mariner Card',
    code:'US_COAST_GUARD_CARD',
  },
  {
    documentName:'Native American Tribal Document',
    sectionTwoName:'Native American Tribal Document',
    code:'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
  },
  {
    documentName:"Driver's license issued by a Canadian government authority",
    sectionTwoName:'Canadian Drivers License',
    code:'CANADIAN_DRIVERS_LICENSE',
  },
  {
    documentName:'School record or report card (Under 18)',
    sectionTwoName:'School Record',
    code:'SCHOOL_RECORD',
  },
  {
    documentName:'Clinic, doctor, or hospital record (Under 18)',
    sectionTwoName:'Hospital Record',
    code:'HOSPITAL_RECORD',
  },
  {
    documentName:'Day-care or nursery school record (under age 18)',
    sectionTwoName:'Day Care Record',
    code:'DAY_CARE_RECORD',
  }
]
export const listCDocuments = 
[
  {
    documentName:'---',
    sectionTwoName:'',
    code:'',
    citizenShipType:['US_CITIZEN','NONCITIZEN','LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK','ALIEN_AUTHORIZED_TO_WORK']
  },
  {
    documentName:'A Social Security Account Number card (Read List C Instructions)',
    sectionTwoName:'Social Security Card',
    code:'SOCIAL_SECURITY_CARD',
    citizenShipType:['US_CITIZEN','NONCITIZEN','LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK', 'ALIEN_AUTHORIZED_TO_WORK']
  },
  {
    documentName:'Form DS-1350',
    sectionTwoName:'Form DS-1350',
    code:'DS_1350',
    citizenShipType:['US_CITIZEN','NONCITIZEN']
  },
  {
    documentName:'Certification of Birth Abroad (Form FS-545)',
    sectionTwoName:'Certification of Birth Abroad (Form FS-545)',
    code:'FS_545',
    citizenShipType:['US_CITIZEN','NONCITIZEN']
  },
  {
    documentName:'Form FS-240',
    sectionTwoName:'Form FS-240',
    code:'FS_240',
    citizenShipType:['US_CITIZEN','NONCITIZEN']
  },
  {
    documentName:'Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal',
    sectionTwoName:'US Birth Certificate',
    code:'US_BIRTH_CERTIFICATE',
    citizenShipType:['US_CITIZEN','NONCITIZEN']
  },
  {
    documentName:'Native American tribal document',
    sectionTwoName:'Native American Tribal Document',
    code:'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
    citizenShipType:['US_CITIZEN','NONCITIZEN','LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK', 'ALIEN_AUTHORIZED_TO_WORK']
  },
  {
    documentName:'U.S. Citizen ID Card (Form I-197)',
    sectionTwoName:'U.S. Citizen ID Card (Form I-197)',
    code:'FORM_I197',
    citizenShipType:['US_CITIZEN']
  },
  {
    documentName:'Identification Card for Use of Resident Citizen in the United States (Form I-179)',
    sectionTwoName:'Form I-179',
    code:'FORM_I179',
    citizenShipType:['US_CITIZEN']
  },
  {
    documentName:'Employment authorization document issued by the Department of Homeland Security (Read List C Instructions)',
    sectionTwoName:'Employment Authorization Document',
    code:'EMPLOYMENT_AUTHORIZATION_DOCUMENT,',
    citizenShipType:['US_CITIZEN','NONCITIZEN','LAWFUL_PERMANENT_RESIDENT','NONCITIZEN_AUTHORIZED_TO_WORK', 'ALIEN_AUTHORIZED_TO_WORK']
  }
]

export const fillPDF = async (a,formData, fieldsArray) => {
    try {
    
      // Load existing PDF template (replace 'existingPDFBytes' with your PDF data)
      const existingPDFBytes = await fetch(a).then(response => response.arrayBuffer());
console.log(existingPDFBytes)
      if (!existingPDFBytes || existingPDFBytes.length === 0) {
        throw new Error('Invalid or empty PDF content');
      }
  
      const pdfDoc = await PDFDocument.load(a);

      // Access form fields
      const form = pdfDoc.getForm();

      // Identify and set values for specific fields
      fieldsArray.forEach((fieldName) => {
        const textField = form.getTextField(fieldName);
        textField.setText(formData[fieldName]);
      });
      

      // Save the modified PDF
      const filledPDFBytes = await pdfDoc.save();

      // Example: Trigger download
      const blob = new Blob([filledPDFBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'filled_pdf.pdf';
      link.click();
    } catch (error) {
      console.error('Error filling PDF form:', error);
    }
  };


  export const countries = [
    {
      "code": "AFG",
      "name": "Afghanistan"
    },
    {
      "code": "AGO",
      "name": "Angola"
    },
    {
      "code": "ALB",
      "name": "Albania"
    },
    {
      "code": "AND",
      "name": "Andorra"
    },
    {
      "code": "ARE",
      "name": "United Arab Emirates"
    },
    {
      "code": "ARG",
      "name": "Argentina"
    },
    {
      "code": "ARM",
      "name": "Armenia"
    },
    {
      "code": "ATG",
      "name": "Antigua and Barbuda"
    },
    {
      "code": "AUS",
      "name": "Australia"
    },
    {
      "code": "AUT",
      "name": "Austria"
    },
    {
      "code": "AZE",
      "name": "Azerbaijan"
    },
    {
      "code": "BDI",
      "name": "Burundi"
    },
    {
      "code": "BEL",
      "name": "Belgium"
    },
    {
      "code": "BEN",
      "name": "Benin"
    },
    {
      "code": "BFA",
      "name": "Burkina Faso"
    },
    {
      "code": "BGD",
      "name": "Bangladesh"
    },
    {
      "code": "BGR",
      "name": "Bulgaria"
    },
    {
      "code": "BHR",
      "name": "Bahrain"
    },
    {
      "code": "BHS",
      "name": "Bahamas"
    },
    {
      "code": "BIH",
      "name": "Bosnia and Herzegovina"
    },
    {
      "code": "BLR",
      "name": "Belarus"
    },
    {
      "code": "BLZ",
      "name": "Belize"
    },
    {
      "code": "BOL",
      "name": "Bolivia, Plurinational State of"
    },
    {
      "code": "BRA",
      "name": "Brazil"
    },
    {
      "code": "BRB",
      "name": "Barbados"
    },
    {
      "code": "BRN",
      "name": "Brunei"
    },
    {
      "code": "BTN",
      "name": "Bhutan"
    },
    {
      "code": "BWA",
      "name": "Botswana"
    },
    {
      "code": "CAF",
      "name": "Central African Republic"
    },
    {
      "code": "CAN",
      "name": "Canada"
    },
    {
      "code": "CHE",
      "name": "Switzerland"
    },
    {
      "code": "CHL",
      "name": "Chile"
    },
    {
      "code": "CHN",
      "name": "China"
    },
    {
      "code": "CIV",
      "name": "Cote d'Ivoire (Ivory Coast)"
    },
    {
      "code": "CMR",
      "name": "Cameroon"
    },
    {
      "code": "COD",
      "name": "Congo, Democratic Republic of the"
    },
    {
      "code": "COG",
      "name": "Congo, Republic of the"
    },
    {
      "code": "COL",
      "name": "Colombia"
    },
    {
      "code": "COM",
      "name": "Comoros"
    },
    {
      "code": "CPV",
      "name": "Cape Verde"
    },
    {
      "code": "CRI",
      "name": "Costa Rica"
    },
    {
      "code": "CUB",
      "name": "Cuba"
    },
    {
      "code": "CYP",
      "name": "Cyprus"
    },
    {
      "code": "CZE",
      "name": "Czech Republic"
    },
    {
      "code": "DEU",
      "name": "Germany"
    },
    {
      "code": "DJI",
      "name": "Djibouti"
    },
    {
      "code": "DMA",
      "name": "Dominica"
    },
    {
      "code": "DNK",
      "name": "Denmark"
    },
    {
      "code": "DOM",
      "name": "Dominican Republic"
    },
    {
      "code": "DZA",
      "name": "Algeria"
    },
    {
      "code": "ECU",
      "name": "Ecuador"
    },
    {
      "code": "EGY",
      "name": "Egypt"
    },
    {
      "code": "ERI",
      "name": "Eritrea"
    },
    {
      "code": "ESP",
      "name": "Spain"
    },
    {
      "code": "EST",
      "name": "Estonia"
    },
    {
      "code": "ETH",
      "name": "Ethiopia"
    },
    {
      "code": "FIN",
      "name": "Finland"
    },
    {
      "code": "FJI",
      "name": "Fiji"
    },
    {
      "code": "FRA",
      "name": "France"
    },
    {
      "code": "FSM",
      "name": "Micronesia, Federated States of"
    },
    {
      "code": "GAB",
      "name": "Gabon"
    },
    {
      "code": "GBR",
      "name": "United Kingdom"
    },
    {
      "code": "GEO",
      "name": "Georgia"
    },
    {
      "code": "GHA",
      "name": "Ghana"
    },
    {
      "code": "GIN",
      "name": "Guinea"
    },
    {
      "code": "GMB",
      "name": "Gambia"
    },
    {
      "code": "GNB",
      "name": "Guinea-Bissau"
    },
    {
      "code": "GNQ",
      "name": "Equatorial Guinea"
    },
    {
      "code": "GRC",
      "name": "Greece"
    },
    {
      "code": "GRD",
      "name": "Grenada"
    },
    {
      "code": "GTM",
      "name": "Guatemala"
    },
    {
      "code": "GUY",
      "name": "Guyana"
    },
    {
      "code": "HND",
      "name": "Honduras"
    },
    {
      "code": "HRV",
      "name": "Croatia"
    },
    {
      "code": "HTI",
      "name": "Haiti"
    },
    {
      "code": "HUN",
      "name": "Hungary"
    },
    {
      "code": "IDN",
      "name": "Indonesia"
    },
    {
      "code": "IND",
      "name": "India"
    },
    {
      "code": "IRL",
      "name": "Ireland"
    },
    {
      "code": "IRN",
      "name": "Iran, Islamic Republic of"
    },
    {
      "code": "IRQ",
      "name": "Iraq"
    },
    {
      "code": "ISL",
      "name": "Iceland"
    },
    {
      "code": "ISR",
      "name": "Israel"
    },
    {
      "code": "ITA",
      "name": "Italy"
    },
    {
      "code": "JAM",
      "name": "Jamaica"
    },
    {
      "code": "JOR",
      "name": "Jordan"
    },
    {
      "code": "JPN",
      "name": "Japan"
    },
    {
      "code": "KAZ",
      "name": "Kazakhstan"
    },
    {
      "code": "KEN",
      "name": "Kenya"
    },
    {
      "code": "KGZ",
      "name": "Kyrgyzstan"
    },
    {
      "code": "KHM",
      "name": "Cambodia"
    },
    {
      "code": "KIR",
      "name": "Kiribati"
    },
    {
      "code": "KNA",
      "name": "Saint Kitts and Nevis"
    },
    {
      "code": "KOR",
      "name": "Korea, Republic of (South)"
    },
    {
      "code": "KVO",
      "name": "Kosovo"
    },
    {
      "code": "KWT",
      "name": "Kuwait"
    },
    {
      "code": "LAO",
      "name": "Laos"
    },
    {
      "code": "LBN",
      "name": "Lebanon"
    },
    {
      "code": "LBR",
      "name": "Liberia"
    },
    {
      "code": "LBY",
      "name": "Libya"
    },
    {
      "code": "LCA",
      "name": "Saint Lucia"
    },
    {
      "code": "LIE",
      "name": "Liechtenstein"
    },
    {
      "code": "LKA",
      "name": "Sri Lanka"
    },
    {
      "code": "LSO",
      "name": "Lesotho"
    },
    {
      "code": "LTU",
      "name": "Lithuania"
    },
    {
      "code": "LUX",
      "name": "Luxembourg"
    },
    {
      "code": "LVA",
      "name": "Latvia"
    },
    {
      "code": "MAR",
      "name": "Morocco"
    },
    {
      "code": "MCO",
      "name": "Monaco"
    },
    {
      "code": "MDA",
      "name": "Moldova, Republic of"
    },
    {
      "code": "MDG",
      "name": "Madagascar"
    },
    {
      "code": "MDV",
      "name": "Maldives"
    },
    {
      "code": "MEX",
      "name": "Mexico"
    },
    {
      "code": "MHL",
      "name": "Marshall Islands, Republic of the"
    },
    {
      "code": "MKD",
      "name": "Macedonia, the Former Yugoslav Republic of"
    },
    {
      "code": "MLI",
      "name": "Mali"
    },
    {
      "code": "MLT",
      "name": "Malta"
    },
    {
      "code": "MMR",
      "name": "Burma (Myanmar)"
    },
    {
      "code": "MNE",
      "name": "Montenegro"
    },
    {
      "code": "MNG",
      "name": "Mongolia"
    },
    {
      "code": "MOZ",
      "name": "Mozambique"
    },
    {
      "code": "MRT",
      "name": "Mauritania"
    },
    {
      "code": "MUS",
      "name": "Mauritius"
    },
    {
      "code": "MWI",
      "name": "Malawi"
    },
    {
      "code": "MYS",
      "name": "Malaysia"
    },
    {
      "code": "NAM",
      "name": "Namibia"
    },
    {
      "code": "NER",
      "name": "Niger"
    },
    {
      "code": "NGA",
      "name": "Nigeria"
    },
    {
      "code": "NIC",
      "name": "Nicaragua"
    },
    {
      "code": "NLD",
      "name": "Netherlands"
    },
    {
      "code": "NOR",
      "name": "Norway"
    },
    {
      "code": "NPL",
      "name": "Nepal"
    },
    {
      "code": "NRU",
      "name": "Nauru"
    },
    {
      "code": "NZL",
      "name": "New Zealand"
    },
    {
      "code": "OMN",
      "name": "Oman"
    },
    {
      "code": "PAK",
      "name": "Pakistan"
    },
    {
      "code": "PAN",
      "name": "Panama"
    },
    {
      "code": "PER",
      "name": "Peru"
    },
    {
      "code": "PHL",
      "name": "Philippines"
    },
    {
      "code": "PLW",
      "name": "Palau"
    },
    {
      "code": "PNG",
      "name": "Papua New Guinea"
    },
    {
      "code": "POL",
      "name": "Poland"
    },
    {
      "code": "PRK",
      "name": "Korea, Democratic Republic of (North)"
    },
    {
      "code": "PRT",
      "name": "Portugal"
    },
    {
      "code": "PRY",
      "name": "Paraguay"
    },
    {
      "code": "PSE",
      "name": "Palestinian Authority"
    },
    {
      "code": "QAT",
      "name": "Qatar"
    },
    {
      "code": "ROU",
      "name": "Romania"
    },
    {
      "code": "RUS",
      "name": "Russia"
    },
    {
      "code": "RWA",
      "name": "Rwanda"
    },
    {
      "code": "SAU",
      "name": "Saudi Arabia"
    },
    {
      "code": "SDN",
      "name": "Sudan"
    },
    {
      "code": "SEN",
      "name": "Senegal"
    },
    {
      "code": "SGP",
      "name": "Singapore"
    },
    {
      "code": "SLB",
      "name": "Solomon Islands"
    },
    {
      "code": "SLE",
      "name": "Sierra Leone"
    },
    {
      "code": "SLV",
      "name": "El Salvador"
    },
    {
      "code": "SMR",
      "name": "San Marino"
    },
    {
      "code": "SOM",
      "name": "Somalia"
    },
    {
      "code": "SRB",
      "name": "Serbia"
    },
    {
      "code": "SSD",
      "name": "South Sudan"
    },
    {
      "code": "STP",
      "name": "Sao Tome and Principe"
    },
    {
      "code": "SUR",
      "name": "Suriname"
    },
    {
      "code": "SVK",
      "name": "Slovakia"
    },
    {
      "code": "SVN",
      "name": "Slovenia"
    },
    {
      "code": "SWE",
      "name": "Sweden"
    },
    {
      "code": "SWZ",
      "name": "Eswatini"
    },
    {
      "code": "SYC",
      "name": "Seychelles"
    },
    {
      "code": "SYR",
      "name": "Syria"
    },
    {
      "code": "TCD",
      "name": "Chad"
    },
    {
      "code": "TGO",
      "name": "Togo"
    },
    {
      "code": "THA",
      "name": "Thailand"
    },
    {
      "code": "TJK",
      "name": "Tajikistan"
    },
    {
      "code": "TKM",
      "name": "Turkmenistan"
    },
    {
      "code": "TLS",
      "name": "Timor-leste"
    },
    {
      "code": "TON",
      "name": "Tonga"
    },
    {
      "code": "TTO",
      "name": "Trinidad and Tobago"
    },
    {
      "code": "TUN",
      "name": "Tunisia"
    },
    {
      "code": "TUR",
      "name": "Turkey"
    },
    {
      "code": "TUV",
      "name": "Tuvalu"
    },
    {
      "code": "TWN",
      "name": "Taiwan"
    },
    {
      "code": "TZA",
      "name": "Tanzania, United Republic of"
    },
    {
      "code": "UGA",
      "name": "Uganda"
    },
    {
      "code": "UKR",
      "name": "Ukraine"
    },
    {
      "code": "URY",
      "name": "Uruguay"
    },
    {
      "code": "UZB",
      "name": "Uzbekistan"
    },
    {
      "code": "VAT",
      "name": "Holy See (Vatican City State)"
    },
    {
      "code": "VCT",
      "name": "Saint Vincent and the Grenadines"
    },
    {
      "code": "VEN",
      "name": "Venezuela, Bolivarian Republic of"
    },
    {
      "code": "VNM",
      "name": "Viet Nam"
    },
    {
      "code": "VUT",
      "name": "Vanuatu"
    },
    {
      "code": "WSM",
      "name": "Samoa"
    },
    {
      "code": "YEM",
      "name": "Yemen"
    },
    {
      "code": "ZAF",
      "name": "South Africa"
    },
    {
      "code": "ZMB",
      "name": "Zambia"
    },
    {
      "code": "ZWE",
      "name": "Zimbabwe"
    }
  ];
  

