import { Alert, Box, Button, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { API } from "../../controllers/API";
import { Form, Spinner } from "react-bootstrap";
import { formatWrittenDate } from "../../utils/Global-Functions";
import axios from "axios";





export const ConfirmFAN = ({caseNumber, name, employee_decision_date})=>{
   const [ language, setLanguage] = useState('english');
   const [spinner, setSpinner] = useState(false)
   const [loading, setLoading] = useState(false)


   const [selectedValue, setSelectedValue] = useState('');
   const [employeeNotified, setEmployeeNotified] = useState(false);
   const handleRadioChange = (event) => {
    if(event.target.value !== 'refer'){
        setEmployeeNotified(false)
    }
    setSelectedValue(event.target.value);
  };


   const getFAN = async (caseNumber, language) => {
    try {
        setSpinner(true)
        // Make a POST request to the backend route to retrieve the FAN PDF
        const response = await fetch(API + `/everify/authentication/further_action_notice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ caseNumber, language })
        });

        // Handle response
        if (!response.ok) {
            // If response is not ok, throw an error
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Failed to retrieve FAN PDF');
            setSpinner(false)

        }

        // Convert the response to a Blob object
        const blob = await response.blob();

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = `${caseNumber}_FAN_${language}.pdf`;
        downloadLink.click();

        // Clean up by revoking the Blob URL
        URL.revokeObjectURL(blobUrl);

        setSpinner(false)

    } catch (error) {
        // Handle errors
        console.error('Error retrieving PDF:', error.message);
        setSpinner(false)

        window.alert(error.message)
    }
};


const referCase = async (case_number, employeeNotified) => {
    setLoading(true)
    try {

        // Make a POST request to refer the case
        const response = await fetch(API + `/everify/authentication/refer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({case_number, employee_notified: employeeNotified })
        });

        // Handle response
        if (!response.ok) {
            // If response is not ok, throw an error
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Failed to refer case');
        }

        // Parse the response JSON data
        const responseData = await response.json();
        window.location.reload()
        setLoading(false)

        // Return the response data
        return responseData;
    } catch (error) {
        // Handle errors
        setLoading(false)

        console.error('Error referring case:', error.message);
        throw error;
    }
};

const closeCase = async (case_number) => {
    try {
     setLoading(true);

        // Construct the request body
        

        // Make the request to close the case
        const response = await axios.post(API+`/everify/authentication/close`, {case_number, case_closure_reason_code:'INCORRECT_DATA'}, {
            headers: {
           
                'Content-Type': 'application/json'
            }
        });

        // Return the response data
        window.location.reload();
        return response.data;
    } catch (error) {
        // Handle errors
        console.error('Error closing case:', error.message);
        throw error;
    }
};

const indicateNoAction = async (caseNumber) => {
    try {
        setLoading(true);
        // Make a POST request to the backend route to indicate no action
        const response = await axios.post(API + '/everify/authentication/no_action',{ case_number: caseNumber }, {
         
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(response)

        // Handle response
        if (response.data.case_eligibility_statement !== 'NO_ACTION_FNC') {
            setLoading(false);
            // If response is not ok, throw an error
            window.alert('Something went wrong. Please try again.')
        }

        // Parse the response JSON data
        const responseData = await response.data;
        window.location.reload();
        // Return the response data
        return responseData;
    } catch (error) {
        // Handle errors
        setLoading(false);
        console.error('Error indicating no action:', error.message);
        throw error;
    }
};



    return (
        <Alert severity="warning">
            <Typography variant="button"><b>FURTHER ACTION NOTICE NEXT STEPS </b></Typography>
        <br/>
            <Typography variant="button">Step 1</Typography>
            <br/>
        
            <Typography className="mb-3">You must download, print, and review the Further Action Notice with {name} privately. Make sure that {name} fully understands the Further Action Notice. This document will explain why {name} received this result, and what to do next.</Typography>
        
            <Typography> If you encounter any issues downloading your document, please ensure that you are using the latest version of your browser and that your pop-up blocker is disabled.</Typography>
             
            <Typography variant="button"><b>Select Language</b></Typography>
            
            <Select
            fullWidth
            className="mb-3"
            sx={{backgroundColor:'white'}}
            value={language}
            onChange={(e)=>{
                setLanguage(e.target.value)
            }}
            >
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="spanish">Spanish</MenuItem>
            </Select>
            <br/>
            
            <Button onClick={()=>getFAN(caseNumber, language)} endIcon={<Spinner style={{display:spinner ? 'flex':'none'}} size='sm'/>} variant="contained">{spinner ? 'Downloading' :'Download Futher Action Notice'}</Button>
            <Box className="mt-2 mb-2">
            <Typography variant="button">Step 2</Typography>
            <br/>
            <ul>
                <li className="mt-2 mb-2"><Typography><b>If {name} chooses to take action to resolve this case, indicate that in the option below.</b> Make sure {name} understands that after you select this option, there will be a deadline of <span style={{color:'red'}}><b>eight federal government working days</b></span> starting today to take action. If an email was provided, {name} will receive a confirmation email that indicates when this countdown begins.</Typography></li>
                <li className="mt-2 mb-2"><Typography><b>If {name} chooses not to take action to resolve this case, indicate that in the option below.</b> You will receive a final result that indicates that we were unable to confirm employment eligibility. {name} needs to understand that refusing to take action could result in losing this job.</Typography></li>
            </ul>

            <Typography style={{fontSize:18}}><b>After {name} has reviewed the Further Action Notice, indicate the decision below:</b></Typography>
            <Box sx={{padding:2}}>
            <RadioGroup
        name="fanDecision"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
        sx={{marginTop:'5px', marginBottom:'5px'}}
          value="refer"
          control={<Radio />}
          label={<Typography><b>{name} will take action to resolve this E-Verify case.</b> {name} understands that action must be taken by <b>{formatWrittenDate(employee_decision_date)}</b>.</Typography>}
        />
        <FormControlLabel
        sx={{marginTop:'5px', marginBottom:'5px'}}
          value="no_action"
          control={<Radio />}
          label={<Typography><b>{name} will not take action to resolve this case. </b>{name} understands that this cannot be undone and choosing not to take action could result in termination of employment.</Typography>}
        />

        <FormControlLabel
        sx={{marginTop:'5px', marginBottom:'5px'}}
          value="close"
          control={<Radio />}
          label={<Typography><b>The information entered was not correct. </b>I am choosing to close this case.</Typography>}
        />
      </RadioGroup>

      {selectedValue === 'refer' && (
           <Form.Check
           style={{marginLeft:2}}
           label={<Typography><b>I have downloaded and given the employee the Further Action Notice.</b></Typography>}
           value={employeeNotified}
           onChange={()=>setEmployeeNotified(!employeeNotified)}
           />
           )}

{selectedValue === 'no_action' && (
        <Button endIcon={<Spinner size="sm" style={{display:loading ? 'flex':'none'}}/>} onClick={()=>indicateNoAction(caseNumber)} variant="contained">Confirm No Action</Button>

           )}

{selectedValue === 'close' && (
         <Button endIcon={<Spinner size="sm" style={{display:loading ? 'flex':'none'}}/>} onClick={()=>closeCase(caseNumber)} variant="contained">Close Case</Button>

           )}

           {employeeNotified && selectedValue === 'refer' && (
            <Box className="p-2">
            <Button endIcon={<Spinner size="sm" style={{display:loading ? 'flex':'none'}}/>} onClick={()=> referCase(caseNumber, employeeNotified)} variant="contained">Refer Case</Button>
            </Box>
           )}
      
      </Box>
            </Box>
        </Alert>
    )
}