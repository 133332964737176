import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  Input,
  InputLabel,
  Collapse,
} from '@mui/material';
import { Form } from 'react-bootstrap';
import CancelIcon from '@mui/icons-material/Cancel';
import { countries, listADocuments, listBDocuments, listCDocuments, statesArray } from '../utils/Global-Functions';


const DocumentUploadForm = (props) => {
 
    const [selectedOption, setSelectedOption] = useState(props.selectedOption || 'listA');
    const [showListA, setShowListA] = useState(false);
    const [showListB, setShowListB] = useState(false);
    const [showListC, setShowListC] = useState(false);
  
    
  
    useEffect(()=>{
     console.log(props.valueB)
    },[])
  const [listAFile, setListAFile] = useState(null);
  const [listBFile, setListBFile] = useState(null);
  const [listCFile, setListCFile] = useState(null);


  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      props.setSelectedOption(newOption);
    }
  };
  const toggleListVisibility = (list) => {
    switch (list) {
      case 'A':
        setShowListA(!showListA);
        break;
      case 'B':
        setShowListB(!showListB);
        break;
      case 'C':
        setShowListC(!showListC);
        break;
      default:
        break;
    }
  };


  const handleFileUpload = (selectedOption, file) => {
    // Update the state based on the selected option
    switch (selectedOption) {
      case 'listAFront':
        props.setFormData((prevData) => ({ ...prevData, listADocumentFront: file }));
        break;
      case 'listABack':
        props.setFormData((prevData) => ({ ...prevData, listADocumentBack: file }));
        break;  
      case 'listBFront':
        props.setFormData((prevData) => ({ ...prevData, listBDocumentFront: file }));
        break;
      case 'listBBack':
        props.setFormData((prevData) => ({ ...prevData, listBDocumentBack: file }));
        break;  
      case 'listCFront':
        props.setFormData((prevData) => ({ ...prevData, listCDocumentFront: file }));
        break;
      case 'listCBack':
        props.setFormData((prevData) => ({ ...prevData, listCDocumentBack: file }));
        break;
      default:
        break;
    }

    // Your existing code for handling file uploads
    props.handleDocumentUpload(selectedOption, file);
  };


  const listAInfo = (
    <div>
      <Typography variant="subtitle2">
        List A includes documents that establish both identity and employment authorization:
      </Typography>
      <Collapse sx={{ textAlign: 'left' }} in={showListA}>
        <ol>
          <li>U.S. Passport or U.S. Passport Card</li>
          <li>Permanent Resident Card or Alien Registration Receipt Card (Form I-551)</li>
          <li>
            Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation on a machine-readable immigrant visa
          </li>
          <li>Employment Authorization Document that contains a photograph (Form I-766)</li>
          <li>
            For an individual temporarily authorized to work for a specific employer because of his or her status or parole:
            <ul>
              <li>Foreign passport; and</li>
              <li>
                Form I-94 or Form I-94A that has the following:
                <ol>
                  <li>The same name as the passport; and</li>
                  <li>
                    An endorsement of the individual's status or parole as long as that period of endorsement has not yet expired and the proposed employment is not in conflict with any restrictions or limitations identified on the form.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A indicating nonimmigrant admission under the Compact of Free Association Between the United States and the FSM or RMI
          </li>
        </ol>

        <Typography variant="body1">Acceptable Receipts:</Typography>
        <ul>
          <li>Receipt for a replacement of a lost, stolen, or damaged List A document.</li>
          <li>Form I-94 issued to a lawful permanent resident that contains an I-551 stamp and a photograph of the individual.</li>
          <li>Form I-94 with “RE” notation or refugee stamp issued to a refugee.</li>
        </ul>
      </Collapse>

      <Button sx={{marginTop:2}} color="primary" onClick={() => toggleListVisibility('A')}>
        {showListA ? 'Hide' : 'View Acceptable Documents'}
      </Button>
    </div>
  );

  const listBInfo = (
    <div style={{textAlign: 'left'}}>
      <Typography sx={{ textAlign: 'left' }} variant="subtitle2">
        List B includes documents that establish identity:
      </Typography>
      <Collapse sx={{ textAlign: 'left' }} in={showListB}>
        <ol>
          <li>Driver's license or ID card issued by a State or outlying possession of the United States provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address</li>
          <li>ID card issued by federal, state or local government agencies or entities, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address</li>
          <li>School ID card with a photograph</li>
          <li>Voter's registration card</li>
          <li>U.S. Military card or draft record</li>
          <li>Military dependent's ID card</li>
          <li>U.S. Coast Guard Merchant Mariner Card</li>
          <li>Native American tribal document</li>
          <li>Driver's license issued by a Canadian government authority</li>
          <li>
            For persons under age 18 who are unable to present a document listed above:
            <ul>
              <li>School record or report card</li>
              <li>Clinic, doctor, or hospital record</li>
              <li>Day-care or nursery school record</li>
            </ul>
          </li>
        </ol>
  
        <Typography variant="body1">Acceptable Receipts:</Typography>
        <ul>
          <li>May be presented in lieu of a document listed above for a temporary period.</li>
          <li>Receipt for a replacement of a lost, stolen, or damaged List B document.</li>
        </ul>
      </Collapse>
  
      <Button sx={{marginTop:0}} color="primary" onClick={() => toggleListVisibility('B')}>
        {showListB ? 'Hide' : 'View Acceptable Documents'}
      </Button>
    </div>
  );
  
  const listCInfo = (
    <div style={{textAlign: 'left', marginTop:10}}>
      <Typography  sx={{ textAlign: 'left' }} variant="subtitle2">
        List C includes documents that establish employment authorization:
      </Typography>
      <Collapse sx={{ textAlign: 'left' }} in={showListC}>
        <ol>
          <li>A Social Security Account Number card, unless the card includes one of the following restrictions:
            <ul>
              <li>NOT VALID FOR EMPLOYMENT</li>
              <li>VALID FOR WORK ONLY WITH INS AUTHORIZATION</li>
              <li>VALID FOR WORK ONLY WITH DHS AUTHORIZATION</li>
            </ul>
          </li>
          <li>Certification of report of birth issued by the Department of State (Forms DS-1350, FS-545, FS-240)</li>
          <li>Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal</li>
          <li>Native American tribal document</li>
          <li>U.S. Citizen ID Card (Form I-197)</li>
          <li>Identification Card for Use of Resident Citizen in the United States (Form I-179)</li>
          <li>Employment authorization document issued by the Department of Homeland Security</li>
        </ol>
  
        <Typography variant="body1">Acceptable Receipts:</Typography>
        <ul>
          <li>May be presented in lieu of a document listed above for a temporary period.</li>
          <li>Receipt for a replacement of a lost, stolen, or damaged List C document.</li>
        </ul>
      </Collapse>
  
      <Button sx={{marginTop:0}} color="primary" onClick={() => toggleListVisibility('C')}>
        {showListC ? 'Hide' : 'View Acceptable Documents'}
      </Button>
    </div>
  );
  

  return (
    <div>
     <ToggleButtonGroup
        value={props.selectedOption}
        exclusive
        onChange={(event, newOption) => {
          props.setSelectedOption(newOption);
          if(newOption === 'listA'){
            
          props.setFormData((prevData)=>({
            ...prevData,
            listBDocumentCode: '',
            listBDocument:'',
            listCDocumentCode: '',
            listCDocument:''
          }))

        } else {
          props.setFormData((prevData)=>({
            ...prevData,
            listADocumentCode: '',
            listADocument:''
            
          }))
        }
        }}
        aria-label="documentOptions"
        style={{ marginBottom: '16px', flexDirection: 'column' }}
      >
        <ToggleButton
          style={{
            width: '100%',
            backgroundColor: props.selectedOption === 'listA' ? '#102372' : '',
            color: props.selectedOption === 'listBC' ? 'white' : '#102372',
          }}
          value="listA"
        >
          <Card sx={{ textAlign: 'left' }} variant={props.selectedOption === 'listA' ? 'outlined' : 'elevation'}>
            <CardContent sx={{ textAlign: 'left' }}>
              <Typography variant="h6">List A</Typography>
              {listAInfo}
            </CardContent>
          </Card>
        </ToggleButton>

        <ToggleButton
          value="listBC"
          style={{
            width: '100%',
            backgroundColor: props.selectedOption === 'listBC' ? '#102372' : '',
            color: props.selectedOption === 'listBC' ? 'white' : '#102372',
          }}
        >
          <Card variant={props.selectedOption === 'listBC' ? 'outlined' : 'elevation'}>
            <CardContent sx={{ textAlign: 'left' }}>
              <Typography variant="h6">List B/C</Typography>
              {listBInfo}
              {listCInfo}
            </CardContent>
          </Card>
        </ToggleButton>
      </ToggleButtonGroup>

      {props.selectedOption === 'listBC' ? (
        <>
         <Form.Label htmlFor="fileUpload"><b>LIST B Document Type:</b></Form.Label>
        <Form.Select
        value={props.valueBCode}
        onChange={(e)=>{
          let value = e.target.value;
          props.setFormData((prevData)=>({
            ...prevData,
            listBDocumentCode: value,
          })
          

      )
      console.log(value)
    }}

    
        >
          {listBDocuments.map((i,index)=>{
            return <option value={i.code}>{i.documentName}</option>
          })}
        </Form.Select>
        {props.valueBCode === 'DRIVERS_LICENSE' && (
  <>
          <Typography sx={{marginTop:2}}><b>Specify Subdocument Type:</b></Typography>

  <Form.Select
      onChange={(e)=>{
        let value = e.target.value;
        console.log(value)
        props.setFormData((prevData)=>({
          ...prevData,
          listBSubDocumentCode: value,
        })
        

    )
    
  }}
      >
        <option value="">---</option>
        <option value="DRIVERS_LICENSE">Driver's License</option>
      <option value="STATE_ID_CARD">State Issued ID Card</option>
      </Form.Select>

      <Typography sx={{marginTop:2}}><b>State Card Was Issued:</b></Typography>

      <Form.Select
      onChange={(e)=>{
        let value = e.target.value;
        console.log(value)
        props.setFormData((prevData)=>({
          ...prevData,
          listBSubDocumentStateCode: value,
        }))
     
    
  }}
      >
       {statesArray.map(i=>{
        return <option value={i}>{i}</option>
       })}
      </Form.Select>
      
      </>
)}
        
          <Form.Label style={{marginTop:10}} htmlFor="fileUpload"><b>Upload LIST B Document Front:</b></Form.Label>
          <Form.Control
           
            type="file"
            id="fileUpload1"
            accept=" .jpg, .png"
            style={{ marginBottom: '16px', display: props.valueB !== '' ? 'none': 'block' }}
            onChange={(e) => handleFileUpload('listBFront', e.target.files[0])}
          />
          <p style={{display: props.valueB !== '' ? 'block': 'none'}}>{props.valueB ? props.valueB.name : ''} <span onClick={()=>{props.RemoveValueB()}}><CancelIcon htmlColor='tomato'/></span></p>

        <Form.Label style={{marginTop:10}} htmlFor="fileUpload"><b>Upload LIST B Document Back:</b></Form.Label>
          <Form.Control
           
            type="file"
            id="fileUpload2"
            accept=" .jpg, .png"
            style={{ marginBottom: '16px', display: props.valueBBack !== '' ? 'none': 'block' }}
            onChange={(e) => handleFileUpload('listBBack', e.target.files[0])}
          />
          <p style={{display: props.valueBBack !== '' ? 'block': 'none'}}>{props.valueBBack ? props.valueB.name : ''} <span onClick={()=>{props.RemoveValueBBack()}}><CancelIcon htmlColor='tomato'/></span></p>

          <Form.Label htmlFor="fileUpload"><b>LIST C Document Type:</b></Form.Label>
        <Form.Select
        value={props.valueCCode}
        onChange={(e)=>{
          let value = e.target.value;
          props.setFormData((prevData)=>({
            ...prevData,
            listCDocumentCode: value,
          })
          

      )
      console.log(props.formData)
    }}
        >
          {listCDocuments.filter(doc => doc.citizenShipType.includes(props.formData.citizenshipStatus)).map((i,index)=>{
            return <option value={i.code}>{i.documentName}</option>
          })}
        </Form.Select>
          <Form.Label style={{marginTop:10}} htmlFor="fileUpload"><b>Upload LIST C Document Front:</b></Form.Label>
          <Form.Control
            
            type="file"
            id="fileUpload3"
            accept=".jpg, .png"
            style={{ marginBottom: '16px', display: props.valueC !== '' ? 'none': 'block' }}
            onChange={(e) => handleFileUpload('listCFront', e.target.files[0])}
          />
         
          <p style={{display: props.valueC !== '' ? 'block': 'none'}}>{props.valueC ? props.valueC.name : ''} <span onClick={()=>{props.RemoveValueC()}}><CancelIcon htmlColor='tomato'/></span></p>

          <Form.Label style={{marginTop:10}} htmlFor="fileUpload"><b>Upload LIST C Document Back:</b></Form.Label>
          <Form.Control
        
          type="file"
          id="fileUpload4"
          accept=".jpg, .png"
          style={{ marginBottom: '16px', display: props.valueCBack !== '' ? 'none': 'block' }}
          onChange={(e) => handleFileUpload('listCBack', e.target.files[0])}
        />
          <p style={{display: props.valueCBack !== '' ? 'block': 'none'}}>{props.valueCBack ? props.valueC.name : ''} <span onClick={()=>{props.RemoveValueCBack()}}><CancelIcon htmlColor='tomato'/></span></p>

        </>
      ) : (
        <>
        <Form.Label htmlFor="fileUpload"><b>LIST A Document Type:</b></Form.Label>
        <Form.Select
        value={props.valueACode}
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          props.setFormData((prevData)=>({
            ...prevData,
            listADocumentCode: value,
          }))

    }}
        >
          {listADocuments.filter(doc => doc.citizenShipType.includes(props.citizenshipStatus)).map((i,index)=>{
            return <option value={i.code}>{i.documentName}</option>
          })}
        </Form.Select>
        { props.valueACode === 'FOREIGN_PASSPORT_WITH_FORM_I94'  && ( 
           <> 
       <Typography ><b>Please select the country code:</b></Typography>
     
        <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          props.setFormData((prevData)=>({
            ...prevData,
            countryCode: value,
          }))
        
      
    }}
        >
          <option value="">---</option>
          {countries.map((i,index)=>{
            return <option value={i.code}>{i.name} - {i.code}</option>
          })}
        </Form.Select>
        </>
       )}

{ props.valueACode === 'FOREIGN_PASSPORT_WITH_I551_STAMP'  && ( 
           <> 
       <Typography ><b>Please select the country code:</b></Typography>
     
        <Form.Select
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          props.setFormData((prevData)=>({
            ...prevData,
            countryCode: value,
          }))
    }}
        >
          <option value="">---</option>
          {countries.map((i,index)=>{
            return <option value={i.code}>{i.name} - {i.code}</option>
          })}
        </Form.Select>
        </>
       )}
          <Form.Label style={{marginTop:10}} htmlFor="fileUpload"><b>Upload LIST A Document Front:</b></Form.Label>
          <Form.Control
        
            type="file"
            id="fileUpload5"
            accept=".jpg, .png"
            style={{ marginBottom: '16px', display: props.valueA !== '' ? 'none': 'block' }}
            onChange={(e) => handleFileUpload('listAFront', e.target.files[0])}
          />
            <p style={{display: props.valueA !== '' ? 'block': 'none'}}>{props.valueA ? props.valueA.name : ''} <span onClick={()=>{props.RemoveValueA()}}><CancelIcon htmlColor='tomato'/></span></p>


<Form.Label style={{marginTop:10}} htmlFor="fileUpload"><b>Upload LIST A Document Back:</b></Form.Label>
          <Form.Control
        
            type="file"
            id="fileUpload6"
            accept=".jpg, .png"
            style={{ marginBottom: '16px', display: props.valueABack !== '' ? 'none': 'block' }}
            onChange={(e) => handleFileUpload('listABack', e.target.files[0])}
          />
           <p style={{display: props.valueABack !== '' ? 'block': 'none'}}>{props.valueABack ? props.valueA.name : ''} <span onClick={()=>{props.RemoveValueABack()}}><CancelIcon htmlColor='tomato'/></span></p>

        </>
      )}
      <br/>
  
  </div>
  );
};

export default DocumentUploadForm;
