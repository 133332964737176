import React, { useState } from "react";
import { AppBar, Box, Grid, Paper, Typography, Button, Modal, TextField, Checkbox, FormControlLabel, IconButton, Select, MenuItem } from "@mui/material";
import { formatDate, listADocuments, listCDocuments } from "../utils/Global-Functions";
import SignatureCanvas from 'react-signature-canvas';
import { Form } from "react-bootstrap";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height:'95%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Set overflowY to 'auto' for vertical scrolling
  };

export const SupplementBModal = ({formModal, handleCloseModal, handleChange, newSupplementB, setNewSupplementB, handleSubmit, handleFileUploadFront, handleFileUploadBack, saveSignature, clearSignature , preparerSigCanvas, handleCheckboxChange, clearDocuments, selectedData}) =>{ 
  const [reverification, setReverification] = useState(false)
  const [expDate, setExpDate] = useState(false)

    return (
        <Modal 
            open={formModal}
            onClose={handleCloseModal}
            >
              <Box sx={style}>
              <hr/>
    <Typography sx={{fontSize:16}} variant="button"><b>Employee Reverification Information</b></Typography>
    <hr/>
              <Grid item xs={12} sm={12}>
                <Typography><b>Instructions:</b> This supplement replaces Section 3 on the previous version of Form I-9. Only use this page if your employee requires reverification, is rehired within three years of the date the original Form I-9 was completed, or provides proof of a legal name change. Enter the employee's name in the fields above. Use a new section for each reverification or rehire. Review the Form I-9 instructions before completing this page. Keep this page as part of the employee's Form I-9 record. Additional guidance can be found in the <a href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274" target="_blank">Handbook for Employers: Guidance for Completing Form I-9 (M-274)</a></Typography>
             </Grid>
              <Grid item xs={12} sm={12}>
              <Typography sx={{marginTop:2}}><b>Date of Rehire *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  type="date"
                  name="dateOfRehire"
                  onChange={handleChange}
                  value={newSupplementB.dateOfRehire}
                />
              </Grid>
              <Grid container spacing={2}>
            
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Last Name *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  name="lastName"
                  onChange={handleChange}
                  value={newSupplementB.lastName}
                />
              </Grid>
              
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>First Name *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementB.firstName}
                  name="firstName"
                  onChange={handleChange}
                  />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Middle Initial </b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementB.middleInitial}
                  name="middleInitial"
                  onChange={handleChange}
                />

                
              </Grid>
              <Grid sx={{}} item xs={12} sm={12}>
              <FormControlLabel
                        control={
                          <Checkbox
                            checked={reverification}
                            onChange={()=>{setReverification(!reverification); 
                           console.log(newSupplementB.listType)
                            if(reverification === false){
                              clearDocuments()
                            }
                            if(reverification) {
                              setNewSupplementB((prevData)=>({
                                ...prevData,
                                listType: 'listA',
                              }))
                            }
                            }}
                          />
                        }
                        label={`Check here if the employee requires reverification.`}
                      />
              </Grid>
              { reverification && (
                <>
             <Grid item xs={12} sm={12}>
             <hr/>

    <Typography sx={{fontSize:16}} variant="button"><b>Document Information</b></Typography>
    <hr/>
                <Typography><b>Reverification:</b> If the employee requires reverification, your employee can choose to present any acceptable List A or List C documentation to show continued employment authorization. Enter the document information in the spaces below</Typography>
             </Grid>
             <Grid item xs={12} sm={12}>
              <Typography sx={{marginTop:2}}><b>Document List Type *</b></Typography>
              <Form.Select
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementB.listType}
                  name="listType"
                  onChange={handleChange}
                >
                <option value="listA">List A</option>
                <option value="listC">List C</option>
                </Form.Select>
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Document Type *</b></Typography>
       { newSupplementB.listType !== 'listC' && ( 
       <Select
       sx={{marginTop:1}}
       fullWidth
        value={newSupplementB.documentTitle}
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setNewSupplementB((prevData)=>({
            ...prevData,
            documentTitle: value,
          }))

    }}
        >
          {listADocuments.filter(doc => doc.citizenShipType.includes(selectedData.sectionOne.citizenshipStatus)).map((i,index)=>{
            return <MenuItem value={i.code}>{i.documentName}</MenuItem>
          })}
        </Select>
       )}

{ newSupplementB.listType === 'listC' && ( 
       <Select
       sx={{marginTop:1}}
       fullWidth
        value={newSupplementB.documentTitle}
        onChange={(e)=>{
          let value = e.target.value;
          console.log(value)
          setNewSupplementB((prevData)=>({
            ...prevData,
            documentTitle: value,
          }))

    }}
        >
          {listCDocuments.filter(doc => doc.citizenShipType.includes(selectedData.sectionOne.citizenshipStatus)).map((i,index)=>{
            return <MenuItem value={i.code}>{i.documentName}</MenuItem>
          })}
        </Select>
       )}
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Document Number  *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementB.documentNumber}
                  name="documentNumber"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography sx={{marginTop:2}}><b>Expiration Date  *</b></Typography>
              <TextField
              sx={{marginTop:1}}
              disabled={expDate}
                  fullWidth
                  type="date"
                  value={newSupplementB.expirationDate}
                  name="expirationDate"
                  onChange={handleChange}
                />

                <FormControlLabel
                        control={
                          <Checkbox
                            checked={expDate}
                            onChange={()=>{setExpDate(!expDate); 
                            
                            if(expDate === true){
                              setNewSupplementB((prevData)=>({
                                ...prevData,
                                expirationDate: '',
                              }))
                            }
                            }}
                          />
                        }
                        label={`Check here if the document does not have an expiration date.`}
                      />      
              </Grid>
              <Grid item xs={12} sm={12}>
              <hr/>
         <Typography sx={{fontSize:16}} variant="button"><b>Upload Document</b></Typography>
               <hr/>
               <Form.Label><b>Document Front</b></Form.Label>
        <Form.Control
              
                onChange={(e)=> handleFileUploadFront(e.target.files[0],'documentFront')}
                type="file"
                accept=".jpg, .png"
                style={{ marginBottom: '16px'}}
                // onChange={(e) => handleFileUpload('listB', e.target.files[0])}
              />

<Form.Label><b>Document Back</b></Form.Label>
        <Form.Control
         
                onChange={(e)=> handleFileUploadBack(e.target.files[0], 'documentBack')}
                type="file"
                accept=".jpg, .png"
                style={{ marginBottom: '16px'}}
                // onChange={(e) => handleFileUpload('listB', e.target.files[0])}
              />
              </Grid>
              </>
              )}
              {/* Signature, Date */}
              <Grid item xs={12} sm={12}>
     

<Grid item xs={12}>

    <hr/>
    <Typography sx={{fontSize:16}} variant="button"><b>Employer or Representitive Information and Signature</b></Typography>
    <hr/>
    <Grid sx={{marginBottom:5}} spacing={2} container>
    <Grid item xs={12} sm={6}>
              <Typography sx={{marginTop:2}}><b>Employer or Authorized Representitive Last Name *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  name="employerLastName"
                  onChange={handleChange}
                  value={newSupplementB.employerLastName}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
              <Typography sx={{marginTop:2}}><b>Employer or Authorized Representitive First Name *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                  value={newSupplementB.employerFirstName}
                  name="employerFirstName"
                  onChange={handleChange}
                  />
              </Grid>

              <Grid item xs={12} sm={12}>
              <Typography><b>Additional Information</b></Typography>

                  <TextField

                  multiline
                  rows={4} // You can adjust the number of rows as needed
                  value={newSupplementB.additionalInformation}
                  onChange={(e) => {
                    setNewSupplementB((prevData) => ({
                      ...prevData,
                      additionalInformation: e.target.value,
                      }));
                      console.log(newSupplementB)
                  }}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  />



                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={newSupplementB.alternativeProcedure}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={`Check here if you used an alternative procedure authorized by DHS to examine documents.`}
                      />
              </Grid>
              <Grid item xs={12} sm={12}>
              <Typography sx={{marginBottom:2}}><b>Signature *</b></Typography>

              <div style={{ border: '2px solid black', marginBottom: 5, display:newSupplementB.signature === '' ? 'block': 'none' }}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    style: {
                      width: '100%',
                      height: 100,
                    },
                  }}
                  ref={preparerSigCanvas}
                />
              </div>
              <img style={{width:'100%', height:'auto'}} src={newSupplementB.signature}/>
              <div>
               
              </div>
              </Grid>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button sx={{display:newSupplementB.signature === '' ? 'none': 'block', marginTop:2}} onClick={clearSignature} variant="outlined" color="secondary">
                    Clear Signature
                  </Button>
                </Grid>
                <Grid item>
                  <Button sx={{display:newSupplementB.signature === '' ? 'block': 'none'}} onClick={saveSignature} variant="contained" color="primary">
                    Save Signature
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography sx={{marginTop:2}}><b>Today's Date *</b></Typography>
              <TextField
              sx={{marginTop:1}}
                  fullWidth
                 disabled
                  value={formatDate(newSupplementB.date)}
                  name="employerFirstName"
                  onChange={handleChange}
                  />
              </Grid>
              </Grid>
              </Grid>
              
        </Grid>

     
            </Grid>
            <Grid sx={{textAlign:'end'}} item xs={12} sm={12}>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </Grid>
              </Box>
            </Modal>
       
           
    )
}