import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, ListItemText, Menu, MenuItem, Modal, Paper, Typography } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { TitleBar } from "../components/TitleBar";
import { ExpandMore } from "@mui/icons-material";



export const ResourceLibrary = ()=>{
    return (
        <Box>
            <NavBar/>

            <TitleBar
            Title="Resource Library"
            NavigationTitle="Back"
            To={-1}
            />
    <Box sx={{padding:5}}>

        
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="button"><b>I-9 Resources</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
       <a href="https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf" target="_blank" download>I-9 Instructions</a>
       <br/>
       <a href="https://www.uscis.gov/sites/default/files/document/forms/i-9.pdf" target="_blank" download>Form I-9</a>
        <br/>
        <a href="https://helpdeskforhr.com/wp-content/uploads/2024/05/I9-Questions-and-Answers-_-USCIS.pdf" target="_blank" download>I-9 Questions and Answers</a>
        <br/>
        <a href="https://helpdeskforhr.com/wp-content/uploads/2024/05/Handbook-for-Employers-M-274-_-USCIS.pdf" target="_blank" download>Handbook for Employers M-274 USCIS</a>
       
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="button"><b>Everify Resources</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <a href="https://www.e-verify.gov/about-e-verify/e-verify-webinars" target="_blank" download>Everify Webinars</a>
        <br/>
        <a href="https://www.e-verify.gov/key-links/questions-and-answers" target="_blank" download>Everify FAQs</a>
       
        </AccordionDetails>
      </Accordion>
      </Box>
        </Box>
    )
}