// src/screens/AccountSettings.js

// Import necessary libraries and components
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { API } from '../controllers/API';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NavBar } from '../components/NavBar';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import LoaderModal from '../components/LoaderModal';
import { TitleBar } from '../components/TitleBar';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { formatDate } from '../utils/Global-Functions';

const AccountSettings = () => {
  // State variables
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [update, setUpdate] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  // Function to download user log as CSV
  const downloadCSV = (data) => {
    if (!data || data.length === 0) return;

    const headers = Object.keys(data[0]).filter((header) => header !== 'id'); // Exclude 'id' from headers
    const csvRows = [headers.join(',')];

    data.forEach((row) => {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${user.companyInformation.companyName} log.csv`;
    link.click();
    URL.revokeObjectURL(url);
  };

  // Fetch user data, subscription status, and payment method on component mount or when 'update' changes
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          console.error('Token not found. Redirecting to login.');
          navigate('/login');
          return;
        }

        const response = await axios.post(API + '/auth/refresh-user', { token });
        const refreshedUser = response.data.user;
        setUser(refreshedUser);
        setCustomerId(refreshedUser.stripeCustomerId);

        // Fetch payment method and subscription status concurrently
        await Promise.all([
          fetchCustomerPaymentMethod(refreshedUser.stripeCustomerId),
          fetchSubscriptionStatus(refreshedUser.stripeSubscriptionId),
        ]);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login'); // Redirect to the login page in case of an error
      }
    };

    const fetchSubscriptionStatus = async (stripeSubscriptionId) => {
      try {
        const response = await axios.get(API + `/admin/subscription-status/${stripeSubscriptionId}`);
        const data = response.data;
        setStatus(data); // Update the subscription status
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        setLoading(false);
      }
    };

    const fetchCustomerPaymentMethod = async (stripeCustomerId) => {
      try {
        const response = await axios.post(API + `/auth/customer-payment-method/${stripeCustomerId}`);
        setPaymentMethod(response.data);
      } catch (error) {
        console.error('Error fetching customer payment method:', error);
      }
    };

    fetchUserData();
  }, [update, navigate]);

  // Function to handle subscription cancellation
  const handleCancelSubscription = async (subscriptionId) => {
    try {
      setLoading(true);
      const response = await axios.post(API + `/admin/cancel-subscription/${subscriptionId}`);
      alert(response.data.message);
      setUpdate(!update);
    } catch (error) {
      alert('An error occurred while canceling the subscription.');
      console.error('Error canceling subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle subscription renewal
  const handleRenewSubscription = async (subscriptionId) => {
    try {
      setLoading(true);
      const response = await axios.post(`${API}/admin/renew-subscription/${subscriptionId}`);
      alert(response.data.message);
      setUpdate(!update);
    } catch (error) {
      alert(
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'An error occurred while renewing the subscription.'
      );
      console.error('Error renewing subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle updating the payment method
  const handleUpdatePaymentMethod = async () => {
    if (!stripe || !elements) {
      console.log('Stripe has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
    } else {
      try {
        await axios.post(API + `/auth/update-payment-method`, {
          paymentMethodId: newPaymentMethod.id,
          stripeCustomerId: customerId,
        });
        setShowUpdateForm(false);
        setUpdate(!update);
        alert('Payment method updated successfully.');
      } catch (backendError) {
        console.error('Error updating payment method:', backendError);
        alert('Failed to update payment method.');
      }
    }
  };

  // Function to handle removing the payment method
  const handleRemovePaymentMethod = async () => {
    const conf = window.confirm('Are you sure you would like to do this?');
    if (conf) {
      try {
        await axios.post(`${API}/auth/payment-method/${paymentMethod.id}`);
        setPaymentMethod(null);
        alert('Payment method removed successfully.');
      } catch (error) {
        console.error('Error removing payment method:', error.response.data);
        alert(`Error removing payment method: ${error.response.data.error}`);
      }
    }
  };

  // Function to handle adding a new payment method
  const handleAddPaymentMethod = async () => {
    if (!stripe || !elements) {
      console.log("Stripe hasn't loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
    } else {
      try {
        await axios.post(`${API}/auth/add-payment-method`, {
          stripeCustomerId: customerId,
          paymentMethodId: newPaymentMethod.id,
        });
        alert('Payment method added successfully');
        setUpdate(!update);
        setShowAddPaymentForm(false);
      } catch (error) {
        console.error('Error adding payment method:', error.response.data);
        alert('Failed to add payment method.');
      }
    }
  };

  if (loading) {
    return <LoaderModal open={true} />;
  } else {
    return (
      <Box>
        <NavBar />
        <TitleBar
          Title={'Account Settings'}
          To={-1}
          DisplayButton={true}
          ButtonTitle={'Download Account Log'}
          onClick={() => downloadCSV(user.log)}
          NavigationTitle={'Back'}
        />
        <Box sx={{ margin: 3, padding: 2 }} component={Paper}>
          {/* User Information */}
          <Typography sx={{ fontSize: 22 }} variant="button">
            <b>User Information</b>
          </Typography>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>First Name:</b> {user.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Last Name:</b> {user.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Email:</b> {user.email}
              </Typography>
            </Grid>
            {/* Add other user information as needed */}
          </Grid>

          {/* Company Information */}
          <hr />
          <Typography sx={{ fontSize: 22 }} variant="button">
            <b>Company Information</b>
          </Typography>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                <b>Employer Category:</b> {user.employerCategory}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Company Name:</b> {user.companyInformation.companyName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Doing Business As (DBA) Name:</b> {user.companyInformation.dba}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Employer Identification Number (EIN):</b> {user.companyInformation.eid}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Unique Entity Identifier (UEI):</b> {user.companyInformation.uei}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>DUNS Number:</b> {user.companyInformation.dunsNo}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Total Number of Employees:</b> {user.companyInformation.totalNumberOfEmployees}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>3-Digit NAICS Code:</b> {user.companyInformation.naicsCode}
              </Typography>
            </Grid>
          </Grid>

          {/* Company Addresses */}
          <hr />
          <Typography sx={{ fontSize: 22 }} variant="button">
            <b>Company Addresses</b>
          </Typography>
          <hr />
          {/* Physical Address */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                <b>Physical Address</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography>
                <b>Street Address:</b> {user.physicalAddress.streetAddress}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>
                <b>Suite/Other:</b> {user.physicalAddress.suite}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>City:</b> {user.physicalAddress.city}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>State:</b> {user.physicalAddress.state}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>ZIP Code:</b> {user.physicalAddress.zipCode}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>County:</b> {user.physicalAddress.county}
              </Typography>
            </Grid>
          </Grid>

          {/* Mailing Address */}
          {user.useMailingAddress && (
            <>
              <hr />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <b>Mailing Address</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography>
                    <b>Street Address:</b> {user.mailingAddress.streetAddress}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>
                    <b>Suite/Other:</b> {user.mailingAddress.suite}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>City:</b> {user.mailingAddress.city}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>State:</b> {user.mailingAddress.state}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ZIP Code:</b> {user.mailingAddress.zipCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>County:</b> {user.mailingAddress.county}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {/* Hiring Sites */}
          <hr />
          <Typography sx={{ fontSize: 22 }} variant="button">
            <b>Hiring Sites</b>
          </Typography>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Total Number of Hiring Sites:</b> {user.hiringSites.length + 1}
              </Typography>
            </Grid>
          </Grid>

          {/* Membership Information */}
          <hr />
          <Typography sx={{ fontSize: 22 }} variant="button">
            <b>Membership Information</b>
          </Typography>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 18 }} variant="button">
                <b>Membership:</b> {user.membership} Membership
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 18 }} variant="button">
                <b>Status:</b>{' '}
                <span
                  style={{
                    color:
                      status.status === 'active'
                        ? 'green'
                        : status.status === 'past_due'
                        ? 'orange'
                        : 'red',
                  }}
                >
                  {status.status.toUpperCase()}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* Subscription Action Buttons */}
              <Box>
                {status.status === 'active' && !status.cancelationDate && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCancelSubscription(user.stripeSubscriptionId)}
                  >
                    Cancel Subscription
                  </Button>
                )}

              {(status.status === 'canceled' ||
                status.status === 'past_due' ||
                status.status === 'unpaid' ||
                status.status === 'incomplete' ||
                status.status === 'incomplete_expired' ||
                status.status === 'expired' ||
                status.status === 'trial_ended' ||
                status.status === 'ended') && (
                <Button onClick={() => handleRenewSubscription(user.stripeSubscriptionId)}>
                  Renew Subscription
                </Button>
              )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {/* Subscription Dates */}
              {status.status === 'active' && !status.cancelationDate && (
                <Typography sx={{ fontSize: 18 }} variant="button">
                  <b>Renewal Date:</b> {formatDate(status.renewalDate)}
                </Typography>
              )}
              {status.status === 'active' && status.cancelationDate && (
                <Typography sx={{ fontSize: 18 }} variant="button">
                  <b>Subscription Ends On:</b> {formatDate(status.cancelationDate)}
                </Typography>
              )}
              {['canceled', 'incomplete_expired', 'expired', 'trial_ended'].includes(status.status) && (
                <Typography sx={{ fontSize: 18 }} variant="button">
                  <b>Subscription Ended On:</b> {formatDate(status.endDate || status.cancelationDate)}
                </Typography>
              )}
              {['past_due', 'unpaid'].includes(status.status) && (
                <Typography sx={{ fontSize: 18 }} variant="button">
                  <b>Subscription Ended On:</b> {formatDate(status.endDate)}
                </Typography>
              )}
            </Grid>
          </Grid>

          {/* Payment Information */}
          <hr />
          <Typography sx={{ fontSize: 18 }} variant="button">
            <b>Payment Information</b>
          </Typography>
          <Box maxWidth={300}>
            {/* Display Payment Method */}
            {paymentMethod ? (
              <>
                <Cards
                  preview
                  number={`000000000000${paymentMethod.last4}`}
                  expiry={`${paymentMethod.exp_month}${paymentMethod.exp_year}`}
                  name={'______________'}
                  issuer={paymentMethod.brand}
                />
              </>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No payment method added.
              </Typography>
            )}

            {/* Add Payment Method Form */}
            {showAddPaymentForm && (
              <div>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    p: 2,
                    borderRadius: 1,
                    mt: 2,
                    mb: 3,
                  }}
                >
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': { color: '#aab7c4' },
                        },
                        invalid: { color: '#9e2146' },
                      },
                    }}
                  />
                </Box>
                <Button onClick={handleAddPaymentMethod}>Save Payment Method</Button>
              </div>
            )}

            {/* Payment Method Actions */}
            <Box sx={{ textAlign: 'right' }}>
              {paymentMethod && (
                <>
                  <Button onClick={() => setShowUpdateForm(true)}>
                    <CachedIcon htmlColor="dodgerblue" /> {'\u00A0' + 'Update'}
                  </Button>

                  <Dialog open={showUpdateForm} onClose={() => setShowUpdateForm(false)}>
                    <DialogTitle>Update Payment Method</DialogTitle>
                    <DialogContent sx={{ width: 500 }}>
                      <Box
                        sx={{
                          border: '1px solid',
                          borderColor: 'divider',
                          p: 2,
                          borderRadius: 1,
                          mt: 2,
                          mb: 3,
                        }}
                      >
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': { color: '#aab7c4' },
                              },
                              invalid: { color: '#9e2146' },
                            },
                          }}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowUpdateForm(false)}>Cancel</Button>
                      <Button onClick={handleUpdatePaymentMethod}>Update</Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
              {paymentMethod && (
                <Button onClick={handleRemovePaymentMethod} sx={{ color: 'tomato' }}>
                  <DeleteIcon htmlColor="tomato" /> {'\u00A0' + 'Delete'}
                </Button>
              )}
              {!paymentMethod && (
                <Button
                  variant="outlined"
                  sx={{ display: showAddPaymentForm ? 'none' : 'block' }}
                  onClick={() => setShowAddPaymentForm(true)}
                >
                  Add Payment Method
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default AccountSettings;
